<template>
  <section>
    <div
      class="third-party-bulk-update-template"
      data-test-id="third-party-bulk-update-template"
    >
      <BaseInfoBox
        v-if="fileUploadError"
        :text="fileUploadError"
        class="third-party-bulk-update-template__error"
        :theme="themes.ERROR"
        :icon-options="defaultIcons.HIGH"
      />
      <VueDropzone
        class="third-party-bulk-update-template__dropzone"
        data-test-id="third-party-bulk-update-template__dropzone"
        :options="dropzoneOptions"
        id="bulkUpdateDropzone"
        ref="bulkUpdateDropzone"
        useCustomSlot
        @vdropzone-files-added="onFilesAdded"
        @vdropzone-error="onError"
        @vdropzone-success="onSuccess"
        @vdropzone-sending="onSending"
        @vdropzone-removed-file="onFileRemoved"
      >
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title">
            Drag and drop to upload content!
          </h3>
          <div class="subtitle">
            ...or click to select a file from your computer
          </div>
        </div>
      </VueDropzone>
      <router-link :to="urls.LIST_OF_SAMPLE_EXCEL_FILES" target="_blank">
        List of available Sample Excel Files
      </router-link>
      <div class="third-party-bulk-update-template__footer">
        <CallToAction
          value="Upload"
          data-test-id="third-party-bulk-update-template__upload"
          :is-disabled="isUploadButtonDisabled"
          :is-loading="isUploading"
          @click="uploadFile"
        />
      </div>
    </div>
      <div
        v-if="validationErrorList.length"
        class="third-party-bulk-update-template__validation-errors"
      >
        <div class="third-party-bulk-update-template__validation-errors-header">
          <BaseText
            class="third-party-bulk-update-template__validation-errors-header-message"
            text="Validation errors"
            tag="h4"
            :size="typographySize.HEADING_FOUR"
            hasDefaultSpacingRemoved
          />
          <CallToAction
            class="third-party-bulk-update-template__copy-validation-errors"
            data-test-id="third-party-bulk-update-template__copy-validation-errors"
            ref="copyValidationErrorsButton"
            @click="onCopyValidationErrors"
            value="Copy errors"
            icon="copy"
            has-icon-with-text
            :icon-with-text-direction="direction.HORIZONTAL"
          />
        </div>

      <ul
        class="third-party-bulk-update-template__validation-error-list"
        data-test-id="third-party-bulk-update-template__validation-error-list"
      >
        <li
          v-for="{ id, error } in validationErrorList"
          :key="id"
          class="third-party-bulk-update-template__validation-error-item"
        >
          {{ error }}
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import {
  customErrorMessages,
  direction,
  endpoints,
  fileTypes,
  themes,
  timers,
  typographySize,
  urls,
  validationKeys,
  defaultIcons
} from "@/constants";
import VueDropzone from "@/utils/VueDropzone";
import { makeAuthorizationHeader, makeUUID } from "@/utils";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import { mapState } from "vuex";
import BaseInfoBox from "@/atoms/BaseInfoBox/BaseInfoBox";
import BaseText from "@/atoms/BaseText/BaseText";

export default {
  name: "ThirdPartyBulkUpdateTemplate",
  components: { BaseText, BaseInfoBox, CallToAction, VueDropzone },
  data() {
    return {
      urls,
      typographySize,
      themes,
      defaultIcons,
      direction,
      isUploading: false,
      hasValidFilesQueued: false,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL}${endpoints.THIRD_PARTY_BULK_UPDATE}`,
        addRemoveLinks: true,
        autoProcessQueue: false,
        headers: makeAuthorizationHeader(),
        maxFiles: 1
      },
      fileUploadError: "",
      validationErrorList: []
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId,
      platformRequestSupportLink: (state) =>
        state.platformSettings.platformRequestSupportLink
    }),
    isUploadButtonDisabled() {
      return !(this.isUploading || this.hasValidFilesQueued);
    }
  },
  methods: {
    makeUUID,
    uploadFile() {
      if (this.$refs.bulkUpdateDropzone.getQueuedFiles()?.length) {
        this.setValidationErrorList([]);
        this.isUploading = true;
        this.$refs.bulkUpdateDropzone.processQueue();
      }
    },
    removeFile(file) {
      this.$refs.bulkUpdateDropzone.removeFile(file);
    },
    onFilesAdded([uploadedFile]) {
      this.setValidationErrorList([]);
      this.fileUploadError = "";
      if (!this.isValidFileType(uploadedFile)) {
        this.removeFile(uploadedFile);
        this.fileUploadError =
          customErrorMessages.uploadingFiles[
            validationKeys.UNSUPPORTED_FILE_FORMAT
          ];
        return;
      }

      if (this.$refs.bulkUpdateDropzone.getQueuedFiles()?.length > 1) {
        this.removeFile(uploadedFile);
        this.setError({
          title:
            customErrorMessages.generic.titles[validationKeys.titles.ERROR],
          message:
            customErrorMessages.uploadingFiles[
              validationKeys.ONLY_UPLOAD_ONE_FILE
            ]
        });
        return;
      }

      this.hasValidFilesQueued = true;
    },
    isValidFileType(fileToValidate) {
      return fileTypes.XLSX.some(
        (XLSXValue) =>
          XLSXValue.toLowerCase() === fileToValidate?.type?.toLowerCase?.()
      );
    },
    onSuccess() {
      this.isUploading = false;
      this.setSuccessMessage({
        message: "Your updates are being processed"
      });
      setTimeout(() => {
        this.$refs.bulkUpdateDropzone.removeAllFiles();
      }, timers.MODERATE_READABLE);
    },
    onSending(_, __, formData) {
      formData.append("company_id", `${this.companyId}`);
    },
    onFileRemoved() {
      this.setValidationErrorList([]);
    },
    onError(file, _, xhr) {
      this.isUploading = false;
      if (xhr.status === 422) {
        const errorList = JSON.parse(xhr.response)?.errors;

        if (Array.isArray(errorList)) {
          const mappedErrorList = errorList.map((error) => ({
            id: makeUUID(),
            error
          }));

          this.setValidationErrorList(mappedErrorList);
          this.setError({
            title:
              customErrorMessages.generic.titles[
                validationKeys.titles.VALIDATION_ERROR
              ],
            message:
              customErrorMessages.uploadingFiles[
                validationKeys.EXCEL_VALIDATION_ERROR
              ],
            additionalOptions: {
              href: this.platformRequestSupportLink
            }
          });
          return;
        }
      }

      this.$refs.bulkUpdateDropzone.removeFile(file);
      this.setError({
        title:
          customErrorMessages.generic.titles[
            validationKeys.titles.SYSTEM_ERROR
          ],
        message: customErrorMessages.generic[validationKeys.UNEXPECTED_ERROR]
      });
    },
    setValidationErrorList(value) {
      this.validationErrorList = value;
    },
    onCopyValidationErrors() {
      const validationListText = this.validationErrorList
        .map(({ error }) => error)
        .join("\r\n");

      navigator.clipboard.writeText(validationListText);
      this.$refs.copyValidationErrorsButton.$el.blur();
      this.setSuccessMessage({
        title: "Success",
        message: "Errors copied to clipboard"
      });
    },
    setError({ title = "", message = "", additionalOptions = {} }) {
      this.$bvToast.toast(message, {
        title,
        variant: "danger",
        appendToast: false,
        noAutoHide: true,
        solid: true,
        ...additionalOptions
      });
    },
    setSuccessMessage({ title = "", message = "" }) {
      this.$bvToast.toast(message, {
        title,
        variant: "success",
        autoHideDelay: 5000,
        appendToast: false,
        solid: true
      });
    }
  }
};
</script>

<style scoped lang="scss">
.third-party-bulk-update-template {
  @include page-container;
  padding-bottom: 0;

  &__dropzone {
    width: 100%;
    max-width: 750px;
    height: 275px;
    border: 1px dashed;
    margin: 0 auto $spacing16;
    display: flex;
    justify-content: center;
    align-items: center;

    &.dz-max-files-reached {
      pointer-events: none;
      background: #fff !important;

      &:deep(.dz-file-preview) {
        pointer-events: all;
      }

      &:deep(.dz-error-message) {
        display: none;
      }
    }
  }

  &__error {
    max-width: 750px;
    margin: 0 auto $spacing16;
  }
  &__footer {
    max-width: 750px;
    display: flex;
    justify-content: flex-end;
    margin: $spacing16 auto 0;
  }
  &__validation-errors {
    max-width: 750px;
    margin: $spacing24 auto 0;
    text-align: left;
  }

  &__validation-errors-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &-message {
      padding-top: $spacing24;
    }
  }

  &__validation-error-list {
    max-height: 260px;
    overflow-y: scroll;
    margin: $spacing16 auto 0;
    border: 2px solid $cardinal;
    border-radius: $border-radius6;
    padding: $spacing6;
    list-style-type: none;
  }

  &__validation-error-item {
    padding: $spacing4 0;

    &:nth-of-type(even) {
      background-color: $linen;
    }
  }
}
</style>

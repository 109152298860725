<template>
  <div class="custom-attribute-chart-widget">
    <BaseChartWidget
      v-if="chartVisible"
      :chart-title="chartTitle"
      :chart-options="chartOptions"
      :highcharts-component-options="highchartsComponentOptions"
      :chart-type-options="currentTypeOptions"
      @change="onChangeChartType"
    />
  </div>
</template>

<script>
import BaseChartWidget from "@/atoms/BaseChartWidget/BaseChartWidget";
import {
  getSelectedOption,
  makeBarChartObj,
  makeChartCountryObj,
  makeGaugeChartObject,
  makeMapChartOptions,
  makePieChartObj,
  makeScatterChartObject
} from "@/utils";
import {
  attributeType,
  chartTypeOptions,
  chartTypes,
  colors,
  customAttributeRiskScales,
  drilldownEvents,
  tenPointScaleChartTypeOptions
} from "@/constants";
import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";
import { isEmpty, isEqual } from "lodash";

export default {
  name: "CustomAttributeChartWidget",
  components: { BaseChartWidget },
  props: {
    widget: {
      type: Object,
      default: () => ({})
    },
    data: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: ""
    },
    subType: {
      type: String,
      default: ""
    },
    attributeIsInactive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chartTypeOptions,
      tenPointScaleChartTypeOptions,
      currentTypeOptions: [],
      chartOptions: {}
    };
  },
  computed: {
    chartVisible() {
      return !isEmpty(this.chartOptions);
    },
    chartTitle() {
      return `${this.widget?.label}${
        this.attributeIsInactive ? " (inactive)" : ""
      }`;
    },
    chartDataPoints() {
      return this.data?.map((value) => ({
        y: parseFloat(value?.count),
        barValue: value?.value,
        name: value?.label
      }));
    },
    chartLabels() {
      return this.data?.map((value) => value?.label);
    },
    chartHasAllRequiredData() {
      return !!(this.data?.length && this.type && this.widget?.label);
    },
    scatterChartData() {
      return this.data?.map((value) => [
        parseFloat(value?.value),
        parseFloat(value?.count)
      ]);
    },
    scatterDateData() {
      return this.data?.map((value) => {
        const date = new Date(value?.value);
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth();
        const day = date.getUTCDate();
        const UTCDate = Date.UTC(year, month, day);

        return {
          x: UTCDate,
          y: parseFloat(value?.count),
          dateValue: value?.value
        };
      });
    },
    worldMapChartData() {
      return this.data?.map((value) => ({
        country: value?.label,
        z: value?.count,
        code: value?.value?.toUpperCase()
      }));
    },
    highchartsComponentOptions() {
      return this.type === attributeType.COUNTRY_LIST ||
        this.type === attributeType.MULTISELECT_COUNTRIES
        ? { constructorType: "mapChart" }
        : {};
    }
  },
  watch: {
    chartHasAllRequiredData(newValue) {
      this.checkForChartInitialisation(newValue);
    },
    currentTypeOptions(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.setChartOptionsBasedOnCustomAttributeType();
      }
    },
    subType(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setChartTypeOptions();
      }
    }
  },
  mounted() {
    this.checkForChartInitialisation(this.chartHasAllRequiredData);
    this.setChartTypeOptions();
  },
  methods: {
    makeOptionsForSelect,
    setChartTypeOptions() {
      this.currentTypeOptions =
        this.type === attributeType.RATING &&
        this.subType === customAttributeRiskScales.TEN_POINT_SCALE
          ? this.tenPointScaleChartTypeOptions
          : this.chartTypeOptions;
    },
    onChangeChartType(event) {
      this.currentTypeOptions = this.makeOptionsForSelect(
        event,
        this.currentTypeOptions
      );
    },
    async setChartOptionsBasedOnCustomAttributeType() {
      this.chartOptions = {};
      await this.$nextTick();
      switch (this.type) {
        case attributeType.RATING:
        case attributeType.BOOLEAN:
        case attributeType.USER:
        case attributeType.DROPDOWN:
        case attributeType.MULTISELECT:
          if (
            getSelectedOption(this.currentTypeOptions)?.value === chartTypes.PIE
          ) {
            this.chartOptions = this.makePieChart({});
          } else if (
            getSelectedOption(this.currentTypeOptions)?.value ===
            chartTypes.GAUGE
          ) {
            this.chartOptions = this.makeGaugeChart();
          } else {
            this.chartOptions = this.makeBarChart({});
          }
          break;
        case attributeType.DOUBLE:
        case attributeType.DATE:
          this.chartOptions = this.makeScatterChart({});
          break;
        case attributeType.COUNTRY_LIST:
        case attributeType.MULTISELECT_COUNTRIES:
          this.chartOptions = this.makeWorldMapCountyChart({});
          break;
        default:
          this.chartOptions = {};
      }
    },
    makePieChart({ _handleChartClickEvent = this.handleChartClickEvent }) {
      return {
        ...makePieChartObj(),
        colors: [colors.carolinaBlue],
        series: [
          {
            data: this.chartDataPoints
          }
        ],
        plotOptions: {
          pie: {
            cursor: "pointer",
            events: {
              click: _handleChartClickEvent
            }
          }
        }
      };
    },
    makeBarChart({ _handleChartClickEvent = this.handleChartClickEvent }) {
      const defaultChart = makeBarChartObj();

      return {
        ...defaultChart,
        chart: {
          ...defaultChart.chart,
          height: 400
        },
        colors: [colors.carolinaBlue],
        xAxis: {
          categories: this.chartLabels,
          labels: {
            style: {
              color: colors.black
            }
          }
        },
        yAxis: {
          gridLineWidth: 0,
          lineWidth: 1,
          endOnTick: true,
          labels: {
            style: {
              color: colors.black
            }
          },
          title: {
            text: "Third Parties"
          }
        },
        tooltip: {
          pointFormat: "<b>{point.category}:{point.y}</b>"
        },
        series: [
          {
            data: this.chartDataPoints
          }
        ],
        turboThreshold: 0,
        plotOptions: {
          bar: {
            cursor: "pointer",
            events: {
              click: _handleChartClickEvent
            }
          }
        }
      };
    },
    handleChartClickEvent({ point }) {
      this.$emit(drilldownEvents.DRILLDOWN_CLICK, {
        title: this.chartTitle,
        value: point?.barValue,
        label: point?.category,
        attributeId: this.widget?.id
      });
    },
    makeScatterChart({
      _handleScatterChartClickEvent = this.handleScatterChartClickEvent
    }) {
      const oneDayInTermsOfMilliseconds = 24 * 3600 * 1000;

      return {
        ...makeScatterChartObject(),
        xAxis: {
          type:
            attributeType.DATE === this.type ? "datetime" : chartTypes.SCATTER,
          dateTimeLabelFormats: {
            day: "%Y/%m/%d"
          },
          startOnTick: true,
          endOnTick: true,
          showLastLabel: true,
          ordinal: false,
          minTickInterval:
            attributeType.DATE === this.type ? oneDayInTermsOfMilliseconds : 1
        },
        tooltip: {
          pointFormat:
            attributeType.DATE === this.type
              ? "<b>Date: {point.x:%Y/%m/%d}</b> <br/> <b>Value: {point.y}</b>"
              : "<b>Value: {point.x} <br/>Value: {point.y}</b>"
        },
        series: [
          {
            name: "",
            marker: {
              symbol: "circle"
            },
            data:
              attributeType.DATE === this.type
                ? this.scatterDateData
                : this.scatterChartData
          }
        ],
        plotOptions: {
          scatter: {
            cursor: "pointer",
            events: {
              click: _handleScatterChartClickEvent
            }
          }
        },
        turboThreshold: 0
      };
    },
    handleScatterChartClickEvent({ point }) {
      this.$emit(drilldownEvents.DRILLDOWN_CLICK, {
        title: this.chartTitle,
        value:
          attributeType.DATE === this.type ? point?.dateValue : point.category,
        label:
          attributeType.DATE === this.type ? point?.dateValue : point.category,
        attributeId: this.widget?.id
      });
    },
    makeWorldMapCountyChart({
      _handleMapChartClickEvent = this.handleMapChartClickEvent
    }) {
      return {
        ...makeMapChartOptions(),
        mapNavigation: {
          enabled: false
        },
        title: {
          text: ""
        },
        series: [
          makeChartCountryObj(),
          {
            type: chartTypes.MAP_BUBBLE,
            name: "Third Parties",
            color: "#6BDC99",
            borderColor: "black",
            borderWidth: 0.2,
            joinBy: ["iso-a2", "code"],
            data: this.worldMapChartData,
            minSize: 4,
            maxSize: "12%",
            tooltip: {
              pointFormat: "{point.country}: {point.z}"
            }
          }
        ],
        plotOptions: {
          [chartTypes.MAP_BUBBLE]: {
            cursor: "pointer",
            events: {
              click: _handleMapChartClickEvent
            }
          }
        }
      };
    },
    handleMapChartClickEvent({ point }) {
      this.$emit(drilldownEvents.DRILLDOWN_CLICK, {
        title: this.chartTitle,
        value: point?.code,
        label: point.country,
        attributeId: this.widget?.id
      });
    },
    getAverageScore() {
      const mappedValues = this.data.reduce(
        (accumulation, dataPoint) => {
          if (dataPoint.count > 0) {
            accumulation.totalCount += dataPoint.count;
            accumulation.value += dataPoint.count * parseInt(dataPoint.label);
          }

          return accumulation;
        },
        { totalCount: 0, value: 0 }
      );

      return Math.floor(mappedValues.value / mappedValues.totalCount);
    },
    makeGaugeChart() {
      const baseChartOptions = makeGaugeChartObject();
      const averageScore = this.getAverageScore();

      return {
        ...baseChartOptions,
        series: [
          {
            data: [
              {
                y: averageScore
              }
            ],
            dataLabels: {
              borderWidth: 0,
              style: {
                border: 0,
                fontFamily: "Roboto",
                fontSize: "1.8rem",
                color: "#000",
                textOutline: 0
              },
              shape: "none",
              y: -50
            }
          }
        ],
        plotOptions: {
          [chartTypes.GAUGE]: {
            cursor: undefined,
            events: {
              click: undefined
            }
          }
        },
        yAxis: {
          ...baseChartOptions.yAxis,
          stops: [
            [0.0, "#56B384"],
            [0.49, "#56B384"],
            [0.5, "#D76317"],
            [0.79, "#D76317"],
            [0.8, "#C21F3B"],
            [1, "#C21F3B"]
          ],
          title: {
            enabled: false
          }
        }
      };
    },
    checkForChartInitialisation(value) {
      if (value) {
        this.setChartOptionsBasedOnCustomAttributeType();
      }
    }
  }
};
</script>

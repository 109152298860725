<template>
  <div class="manage-third-party">
    <!-- Configure Table Widget Modal start -->
    <b-modal
      v-model="showSelectColumnModal"
      :title="$t('eb360.manageThirdParties.selectColumns')"
      size="xl"
      modal-class="modal-open-configure-table"
      content-class="modal-content-configure-table"
      dialog-class="modal-dialog-configure-table"
      footer-class="modal-footer-configure-table"
      header-class="modal-header-configure-table"
      style="height: 100% !important"
      class="add-modal-new pl-0"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
      <div>
        <b-row>
          <b-row style="padding: 0px 7px" class="w-100">
            <!-- Add table setting dropdown start -->
            <b-col cols="6">
              <b-dropdown text="Add Table Setting">
                <div style="height: 368px; overflow: auto">
                  <div
                    class="dropdown-item bg-white text-body"
                    v-for="(form, index) in dynamicTableCol"
                    :key="`dynamicTableCol-${index}`"
                  >
                    <div
                      v-if="form.isActive && form.key == 'column_width'"
                      class="dropdownFieldsContainer"
                    >
                      <Checkbox
                        v-model="form.visible"
                        class="float-left"
                        @update:modelValue="addColumn($event, form)"
                        :label="form.label"
                      />
                      <vue-number-input
                        center
                        inline
                        controls
                        :model-value="parseInt(form.value)"
                        :min="50"
                        :max="1000"
                        :step="10"
                        size="small"
                        :inputtable="false"
                        @update:model-value="setAllFields($event, form.key)"
                      />
                    </div>
                    <div
                      v-if="
                        (form.isActive && form.key == 'font_size') ||
                        form.key == 'header_font_size'
                      "
                      class="dropdownFieldsContainer"
                    >
                      <Checkbox
                        v-model="form.visible"
                        class="float-left"
                        @update:modelValue="addColumn($event, form)"
                        :label="form.label"
                      />
                      <vue-number-input
                        inline
                        controls
                        center
                        :model-value="parseInt(form.value)"
                        :min="10"
                        :max="30"
                        size="small"
                        :inputtable="false"
                        @update:model-value="setAllFields($event, form.key)"
                      />
                    </div>
                    <div
                      v-else-if="
                        (form.isActive && form.key == 'font_color') ||
                        form.key == 'background_color' ||
                        form.key == 'header_font_color' ||
                        form.key == 'header_background_color'
                      "
                      class="dropdownFieldsContainer"
                    >
                      <Checkbox
                        v-model="form.visible"
                        class="float-left"
                        @update:modelValue="addColumn($event, form)"
                        :label="form.label"
                      />
                      <InputColorPicker
                        style="border: 1px solid"
                        :value="form.value"
                        @change="changeAllFieldsColor($event, form.key)"
                      />
                    </div>
                    <div
                      v-else-if="
                        form.isActive && form.key == 'column_description'
                      "
                    >
                      <Checkbox
                        v-model="form.visible"
                        class="float-left"
                        @update:modelValue="addColumn($event, form)"
                        :label="form.label"
                      />
                    </div>
                    <div
                      class="dropdownFieldsContainer"
                      v-else-if="
                        form.isActive &&
                        (form.key == 'header_text_align' ||
                          form.key == 'text_align')
                      "
                    >
                      <Checkbox
                        v-model="form.visible"
                        class="float-left"
                        @update:modelValue="addColumn($event, form)"
                        :label="form.label"
                      />
                      <b-input-group style="width: 43%" size="sm">
                        <b-input-group-prepend>
                          <b-btn
                            variant="outline-secondary"
                            @click="decrementAlignmentAll(form.value, form.key)"
                          >
                            <font-awesome-icon icon="arrow-left" />
                          </b-btn>
                        </b-input-group-prepend>
                        <b-form-input
                          class="text-center"
                          disabled
                          v-model="form.name"
                        />
                        <b-input-group-append>
                          <b-btn
                            variant="outline-secondary"
                            @click="incrementAlignmentAll(form.value, form.key)"
                          >
                            <font-awesome-icon icon="arrow-right" />
                          </b-btn>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                    <div
                      class="dropdownFieldsContainer"
                      v-else-if="
                        form.isActive &&
                        form.key == 'fixed_column_background_color'
                      "
                    >
                      <Checkbox
                        v-model="form.visible"
                        class="float-left"
                        @update:modelValue="addColumn($event, form)"
                        :label="form.label"
                      />
                      <b-input-group style="width: 43%" size="sm">
                        <b-input-group-prepend>
                          <b-btn
                            variant="outline-secondary"
                            @click="decrementAll(form.value)"
                          >
                            <font-awesome-icon icon="arrow-left" />
                          </b-btn>
                        </b-input-group-prepend>
                        <b-form-input
                          :style="{ backgroundColor: form.color }"
                          class="text-center"
                          disabled
                        />
                        <b-input-group-append>
                          <b-btn
                            variant="outline-secondary"
                            @click="incrementAll(form.value)"
                          >
                            <font-awesome-icon icon="arrow-right" />
                          </b-btn>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </div>
              </b-dropdown>
            </b-col>
            <!-- Add table setting dropdown end -->
          </b-row>
          <div class="p-2">
            <b-row>
              <!-- List of available columns start -->
              <b-col cols="3">
                <b-list-group-item class="font-weight-bold">
                  Available Columns
                </b-list-group-item>
                <draggable
                  class="list-group"
                  :list="configureTableAvailableCols"
                  group="fields"
                  style="max-height: calc(100vh - 348px); overflow-y: auto"
                >
                  <template #item="{ element }">
                    <b-list-group>
                      <b-list-group-item v-if="element.isActive">
                        {{ element.label }}</b-list-group-item
                      >
                    </b-list-group>
                  </template>
                </draggable>
              </b-col>
              <!-- List of available columns end -->

              <!-- List of selected columns start -->
              <b-col cols="9">
                <div style="display: grid">
                  <b-table-simple
                    responsive="sm"
                    sticky-header="calc(100vh - 295px)"
                    fixed
                    small
                    bordered
                    outlined
                  >
                    <colgroup>
                      <col style="width: 200px" />
                      <col style="width: 200px" />
                      <col style="width: 200px" />
                      <col
                        v-if="dynamicTableCol[3].visible"
                        style="width: 200px"
                      />
                      <col
                        v-if="dynamicTableCol[4].visible"
                        style="width: 200px"
                      />
                      <col
                        v-if="dynamicTableCol[5].visible"
                        style="width: 200px"
                      />
                      <col
                        v-if="dynamicTableCol[6].visible"
                        style="width: 200px"
                      />
                      <col
                        v-if="dynamicTableCol[7].visible"
                        style="width: 200px"
                      />
                      <col
                        v-if="dynamicTableCol[8].visible"
                        style="width: 200px"
                      />
                      <col
                        v-if="dynamicTableCol[9].visible"
                        style="width: 200px"
                      />
                      <col
                        v-if="dynamicTableCol[10].visible"
                        style="width: 200px"
                      />
                      <col
                        v-if="dynamicTableCol[11].visible"
                        style="width: 200px"
                      />
                      <col
                        v-if="dynamicTableCol[12].visible"
                        style="width: 200px"
                      />
                      <col
                        v-if="dynamicTableCol[13].visible"
                        style="width: 200px"
                      />
                    </colgroup>
                    <b-thead>
                      <b-tr style="text-align: center">
                        <b-th
                          v-b-tooltip.hover
                          :title="dynamicTableCol[0].description"
                          sticky-column
                          variant="primary"
                        >
                          {{ dynamicTableCol[0].label }}
                        </b-th>
                        <b-th
                          v-for="(field, index) in dynamicTableCol.slice(1)"
                          :key="index"
                          v-show="field.visible"
                          v-b-tooltip.hover
                          :title="field.description"
                        >
                          {{ field.label }}
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <draggable
                      v-model="modalConfiguredColumns"
                      tag="b-tbody"
                      group="fields"
                    >
                      <template #item="{ element }">
                        <b-tr style="text-align: center">
                          <b-td sticky-column variant="primary">
                            {{ element.label }}
                          </b-td>
                          <b-td>
                            <Checkbox
                              :modelValue="element.stickyColumn"
                              class="align-items-center"
                              @update:modelValue="
                                checkStickyField($event, element)
                              "
                              label="configure columns"
                              labelHidden
                            />
                          </b-td>
                          <b-td>
                            <b-form-input
                              size="sm"
                              @change="setFieldsDisplayLabel($event, element)"
                              :value="element.displayLabel"
                              :placeholder="element.displayLabel"
                              class="noBorder"
                            />
                          </b-td>
                          <b-td v-if="dynamicTableCol[3].visible">
                            <vue-number-input
                              inline
                              controls
                              center
                              size="200px"
                              :model-value="parseInt(element.width)"
                              :min="50"
                              :max="1000"
                              :step="10"
                              :inputtable="false"
                              @update:model-value="
                                setFieldsWidth($event, element)
                              "
                            />
                          </b-td>
                          <b-td v-if="dynamicTableCol[4].visible">
                            <vue-number-input
                              inline
                              controls
                              center
                              :model-value="parseInt(element.fontSize)"
                              :min="10"
                              :max="30"
                              size="200px"
                              :inputtable="false"
                              @update:model-value="
                                setFieldsFontSize($event, element)
                              "
                            />
                          </b-td>
                          <b-td v-if="dynamicTableCol[5].visible">
                            <InputColorPicker
                              style="border: 1px solid"
                              @change="
                                changeColor($event, element, 'fontColor')
                              "
                              :value="element.fontColor"
                            />
                          </b-td>
                          <b-td v-if="dynamicTableCol[6].visible">
                            <InputColorPicker
                              style="border: 1px solid"
                              @change="changeBacgroundColor($event, element)"
                              :value="
                                element?.tdStyle?.backgroundColor || '#ffffff'
                              "
                            />
                          </b-td>
                          <b-td v-if="dynamicTableCol[7].visible">
                            <b-form-input
                              size="sm"
                              v-model="element.description"
                              placeholder="Write description"
                              class="noBorder"
                            />
                          </b-td>
                          <b-td v-if="dynamicTableCol[8].visible">
                            <vue-number-input
                              inline
                              controls
                              center
                              size="200px"
                              :model-value="parseInt(element.headerFontSize)"
                              :min="10"
                              :max="30"
                              :inputtable="false"
                              @update:model-value="
                                setFieldsHeaderFontSize($event, element)
                              "
                            />
                          </b-td>
                          <b-td v-if="dynamicTableCol[9].visible">
                            <InputColorPicker
                              style="border: 1px solid"
                              @change="
                                changeColor($event, element, 'headerFontColor')
                              "
                              :value="element.headerFontColor"
                            />
                          </b-td>
                          <b-td v-if="dynamicTableCol[10].visible">
                            <InputColorPicker
                              style="border: 1px solid"
                              @change="changeColor($event, element, 'thStyle')"
                              :value="
                                element?.thStyle?.backgroundColor || '#ffffff'
                              "
                            />
                          </b-td>
                          <b-td v-if="dynamicTableCol[11].visible">
                            <b-input-group>
                              <b-input-group-prepend>
                                <b-btn
                                  variant="outline-secondary"
                                  @click="
                                    decrement(
                                      element.tdStyle.textAlignment,
                                      element,
                                      dynamicTableCol[11].key
                                    )
                                  "
                                >
                                  <font-awesome-icon icon="arrow-left" />
                                </b-btn>
                              </b-input-group-prepend>
                              <b-form-input
                                class="text-center"
                                disabled
                                v-model="element.tdStyle.alignment"
                              />
                              <b-input-group-append>
                                <b-btn
                                  variant="outline-secondary"
                                  @click="
                                    increment(
                                      element.tdStyle.textAlignment,
                                      element,
                                      dynamicTableCol[11].key
                                    )
                                  "
                                >
                                  <font-awesome-icon icon="arrow-right" />
                                </b-btn>
                              </b-input-group-append>
                            </b-input-group>
                          </b-td>
                          <b-td v-if="dynamicTableCol[12].visible">
                            <b-input-group>
                              <b-input-group-prepend>
                                <b-btn
                                  variant="outline-secondary"
                                  @click="
                                    decrement(
                                      element.thStyle.textAlignment,
                                      element,
                                      dynamicTableCol[12].key
                                    )
                                  "
                                >
                                  <font-awesome-icon icon="arrow-left" />
                                </b-btn>
                              </b-input-group-prepend>
                              <b-form-input
                                class="text-center"
                                disabled
                                v-model="element.thStyle.alignment"
                              />
                              <b-input-group-append>
                                <b-btn
                                  variant="outline-secondary"
                                  @click="
                                    increment(
                                      element.thStyle.textAlignment,
                                      element,
                                      dynamicTableCol[12].key
                                    )
                                  "
                                >
                                  <font-awesome-icon icon="arrow-right" />
                                </b-btn>
                              </b-input-group-append>
                            </b-input-group>
                          </b-td>
                          <b-td v-if="dynamicTableCol[13].visible">
                            <b-input-group v-if="element.stickyColumn">
                              <b-input-group-prepend>
                                <b-btn
                                  variant="outline-secondary"
                                  @click="
                                    decrement(
                                      element.variant,
                                      element,
                                      dynamicTableCol[13].key
                                    )
                                  "
                                >
                                  <font-awesome-icon icon="arrow-left" />
                                </b-btn>
                              </b-input-group-prepend>

                              <b-form-input
                                :style="{
                                  backgroundColor: element.variantColor
                                }"
                                class="text-center"
                                disabled
                              />
                              <b-input-group-append>
                                <b-btn
                                  variant="outline-secondary"
                                  @click="
                                    increment(
                                      element.variant,
                                      element,
                                      dynamicTableCol[13].key
                                    )
                                  "
                                >
                                  <font-awesome-icon icon="arrow-right" />
                                </b-btn>
                              </b-input-group-append>
                            </b-input-group>
                          </b-td>
                        </b-tr>
                      </template>
                    </draggable>
                  </b-table-simple>
                </div>
              </b-col>
              <!-- List of selected columns end -->
            </b-row>
          </div>
        </b-row>
      </div>
      <template v-slot:modal-footer>
        <b-button
          size="md"
          @click="closeConfigureColumnsModal"
          variant="outline-danger"
          >{{ $t("closeBtnText") }}</b-button
        >
        <b-button
          size="md"
          @click="saveConfiguredColumns"
          variant="outline-success"
        >
          {{ $t("okBtnText") }}
        </b-button>
      </template>
    </b-modal>
    <!-- Configure Table Widget Modal end -->

    <!-- Export Third Party Modal start -->
    <b-modal
      v-model="showExportThirdPartyModal"
      title="Export Third Party"
      size="md"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
      <!-- export third party message -->
      <p>{{ exportMessage }}</p>
      <template v-slot:modal-footer>
        <b-button
          size="md"
          @click="showExportThirdPartyModal = false"
          variant="outline-danger"
        >
          Close
        </b-button>
      </template>
    </b-modal>
    <!-- Export Third Party Modal end -->

    <!-- Export ESG Modal start -->
    <b-modal
      v-model="showExportESGModal"
      title="Export Third Party"
      size="md"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
      <!-- export ESG message -->
      <template v-slot:modal-footer>
        <b-button
          size="md"
          @click="showExportESGModal = false"
          variant="outline-danger"
        >
          Close
        </b-button>
      </template>
    </b-modal>
    <!-- Export ESG Modal end -->

    <!-- card fill -->
    <div>
      <div style="display: flex; justify-content: space-between">
        <div class="ml-auto mr-2" style="display: flex; align-items: center">
          <a :href="platformKnowledgeCentreLink" target="_blank">
            <BaseIcon
              :icon="icons.QUESTION"
              :shape="shapes.CIRCLE"
              :size="12"
              :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
            />
          </a>
        </div>
      </div>
      <b-overlay :show="isLoadingTable" rounded="sm" :opacity="1">
        <div>
          <div style="overflow: hidden auto; height: calc(100vh - 230px)">
            <!-- Advance filter options start -->
            <b-collapse v-model="advanceFilterForms">
              <b-card class="mb-2 bg-light">
                <b-row class="text-center">
                  <b-col cols="12">
                    <div class="d-flex">
                      <h6>Filters</h6>
                    </div>
                    <div class="text-right">
                      <b-btn
                        @click="resetAdvanceFilter"
                        class="mr-2"
                        variant="outline-danger"
                        size="sm"
                        >Clear Filters</b-btn
                      >
                      <b-button
                        @click="applyThirdPartyFilter"
                        :disabled="callFilterApi"
                        variant="outline-success"
                        size="sm"
                      >
                        {{ $t("applyBtn") }}
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-row
                  class="text-left"
                  v-for="(filters, filterItemsIndex) in filterItems"
                  :key="filterItemsIndex"
                >
                  <b-col cols="4">
                    <b-form-group>
                      <multiselect
                        label="text"
                        placeholder="Select Filter Type"
                        open-direction="bottom"
                        v-model="filters.filterType"
                        :options="filterOptions"
                        @update:modelValue="
                          setFilterType($event, filterItemsIndex)
                        "
                        @open="
                          changeFilterType(filters.filterType, filterItemsIndex)
                        "
                        @close="
                          removeFilterType(filters.filterType, filterItemsIndex)
                        "
                        :clearable="false"
                        :show-labels="false"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <DatePicker
                      v-if="isValidDateFilterType(filters)"
                      :datePickerOptions="{
                        value: filters.filterTypeValue,
                        enableTimePicker: false,
                        'onUpdate:modelValue': (value) => {
                          filters.filterTypeValue = makeDateToMatchFormat({
                            stringifiedDate: new Date(value).toISOString(),
                            dateFormat:
                              eb360DateFormat.DEFAULT_DATE_FORMAT_HYPHEN
                          });
                          handleDatepickerEvent(
                            filters.filterTypeValue,
                            filters
                          );
                        }
                      }"
                    />
                    <b-form-group
                      v-else-if="
                        filters?.filterType?.text?.includes('From') &&
                        filters?.filterType?.value?.includes('latest_ddq_sent')
                      "
                    >
                      <div
                        v-for="(col, index) in questionnairesFilterColumns"
                        :key="`questionnairesFilterColumns-${index}`"
                      >
                        <div
                          v-if="
                            checkKeyLatestDdqSentFrom(col.label) &&
                            filters?.filterType?.text === col.label
                          "
                        >
                          <MsqFilterComponent
                            v-if="isMsqDataAvailable"
                            :columnKey="[col.key]"
                            :col="col"
                            :filterItemsIndex="filterItemsIndex"
                            :advanceFilterFromDate="filters.filterTypeValue"
                          />
                        </div>
                      </div>
                    </b-form-group>
                    <b-form-group
                      v-else-if="
                        filters?.filterType?.text?.includes('To') &&
                        filters?.filterType?.value?.includes('latest_ddq_sent')
                      "
                    >
                      <div
                        v-for="(col, index) in questionnairesFilterColumns"
                        :key="`questionnairesFilterColumns-${index}`"
                      >
                        <div
                          v-if="
                            checkKeyLatestDdqSentTo(col.label) &&
                            filters?.filterType?.text === col.label
                          "
                        >
                          <MsqFilterComponent
                            v-if="isMsqDataAvailable"
                            :columnKey="[col.key]"
                            :col="col"
                            :filterItemsIndex="filterItemsIndex"
                            :advanceFilterToDate="filters.filterTypeValue"
                          />
                        </div>
                      </div>
                    </b-form-group>
                    <b-form-group
                      v-else-if="
                        filters?.filterType?.value.includes(
                          'latest_completed_ddq_status'
                        )
                      "
                    >
                      <div
                        v-for="(col, index) in questionnairesFilterColumns"
                        :key="`questionnairesFilterColumns-${index}`"
                      >
                        <div
                          v-if="
                            checkKeyLatestCompletedMsqResult(col.key) &&
                            filters?.filterType?.text === col.label
                          "
                        >
                          <MsqFilterComponent
                            v-if="isMsqDataAvailable"
                            :columnKey="[col.key]"
                            :col="col"
                            :filterItemsIndex="filterItemsIndex"
                            :advanceFilterLatestCompletedMSQResult="
                              filters.filterTypeValue
                            "
                            :filterAdvanceLatestCompletedMSQResult="
                              filterAdvance.latestCompletedMSQResult[col.index]
                            "
                          />
                        </div>
                      </div>
                    </b-form-group>
                    <b-form-group
                      v-else-if="
                        filters?.filterType?.value.includes('latest_ddq_status')
                      "
                    >
                      <div
                        v-for="(col, index) in questionnairesFilterColumns"
                        :key="`questionnairesFilterColumns-${index}`"
                      >
                        <div
                          v-if="
                            checkKeyLatestDdqStatus(col.key) &&
                            filters?.filterType?.text === col.label
                          "
                        >
                          <MsqFilterComponent
                            v-if="isMsqDataAvailable"
                            :columnKey="[col.key]"
                            :col="col"
                            :filterItemsIndex="filterItemsIndex"
                            :advanceFilterLatestMSQTaskStatus="
                              filters.filterTypeValue
                            "
                            :filterAdvanceLatestMSQTaskStatus="
                              filterAdvance.latestMSQTaskStatus[col.index]
                            "
                          />
                        </div>
                      </div>
                    </b-form-group>
                    <b-form-group
                      v-else-if="filters?.filterType?.value === 'countries'"
                    >
                      <v-select
                        label="country"
                        v-model="filters.filterTypeValue"
                        :options="filters.selectedFilterOptions"
                        :clearable="true"
                        :multiple="true"
                        @update:modelValue="
                          setFilterTypeValue($event, filterItemsIndex)
                        "
                      >
                        <template slot="option" slot-scope="option">
                          <country-flag
                            v-if="option.code"
                            :country="option.code"
                            size="small"
                          />
                          <span>{{ "&nbsp;" + option.country }}</span>
                        </template>
                      </v-select>
                    </b-form-group>
                    <b-form-group
                      v-else-if="filters?.filterType?.value == 'Date'"
                    >
                      <div
                        v-for="attribute in customAttributes"
                        :key="`customAttributes-${attribute.id}`"
                      >
                        <div
                          v-if="
                            filters?.filterType?.value === attribute.tp_type &&
                            filters?.filterType?.text === attribute.tp_customatt
                          "
                        >
                          <CustomAttributesFilterComponent
                            v-if="!isLoadingUsers"
                            :attributeData="attribute"
                            :optionsUsers="optionsUsers"
                            :filterType="filters.filterType"
                            :filterItemsIndex="filterItemsIndex"
                            :dateTypeValue="filters.filterTypeValue"
                          />
                        </div>
                      </div>
                    </b-form-group>
                    <b-form-group
                      v-else-if="
                        filters?.filterType?.value &&
                        primitiveCustomAttributesList.includes(
                          filters.filterType.value
                        )
                      "
                    >
                      <div
                        v-for="attribute in customAttributes"
                        :key="`customAttributes-${attribute.id}`"
                      >
                        <div
                          v-if="
                            filters?.filterType?.value === attribute.tp_type &&
                            filters?.filterType?.text ===
                              attribute.tp_customatt &&
                            filters?.filterType?.id === attribute.id
                          "
                        >
                          <CustomAttributesFilterComponent
                            v-if="!isLoadingUsers"
                            :attributeData="attribute"
                            :optionsUsers="optionsUsers"
                            :filterType="filters.filterType"
                            :filterItemsIndex="filterItemsIndex"
                            :stringTypeValue="filters.filterTypeValue"
                          />
                        </div>
                      </div>
                    </b-form-group>
                    <b-form-group
                      v-else-if="
                        filters?.filterType?.value &&
                        multiSelectionCustomAttributesList.includes(
                          filters.filterType.value
                        )
                      "
                    >
                      <div
                        v-for="attribute in customAttributes"
                        :key="`customAttributes-${attribute.id}`"
                      >
                        <div
                          v-if="
                            filters?.filterType?.value === attribute.tp_type &&
                            filters?.filterType?.text ===
                              attribute.tp_customatt &&
                            filters.filterType.id === attribute.id
                          "
                        >
                          <CustomAttributesFilterComponent
                            v-if="!isLoadingUsers"
                            :attributeData="attribute"
                            :optionsUsers="optionsUsers"
                            :filterType="filters.filterType"
                            :filterItemsIndex="filterItemsIndex"
                            :filterTypeValue="filters.filterTypeValue"
                          />
                        </div>
                      </div>
                    </b-form-group>
                    <b-form-group
                      v-else-if="filters?.filterType?.value === 'isArchive'"
                    >
                      <v-select
                        label="text"
                        v-model="filters.filterTypeValue"
                        :options="filters.selectedFilterOptions"
                        :clearable="false"
                        @update:modelValue="
                          setFilterTypeValue($event, filterItemsIndex)
                        "
                      />
                    </b-form-group>
                    <b-form-group
                      v-else-if="filters?.filterType?.value == 'createdBy'"
                    >
                      <v-select
                        label="name"
                        v-model="filters.filterTypeValue"
                        :options="filters.selectedFilterOptions"
                        :clearable="true"
                        :multiple="true"
                        @update:modelValue="
                          setFilterTypeValue($event, filterItemsIndex)
                        "
                      />
                    </b-form-group>
                    <b-form-group v-else>
                      <v-select
                        label="text"
                        v-model="filters.filterTypeValue"
                        :options="filters.selectedFilterOptions"
                        :clearable="true"
                        :multiple="true"
                        @update:modelValue="
                          setFilterTypeValue($event, filterItemsIndex)
                        "
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="3">
                    <b-button
                      v-if="filterItemsIndex > 0"
                      variant="outline-danger"
                      size="sm"
                      @click="removeFilterRow(filterItemsIndex, filters)"
                    >
                      <font-awesome-icon icon="trash" />
                    </b-button>
                    <b-button
                      @click="
                        removeFirstFilterValueRow(filterItemsIndex, filters)
                      "
                      variant="outline-danger"
                      size="sm"
                      v-show="showTrashIcon(filterItemsIndex, filters)"
                    >
                      <font-awesome-icon icon="trash" />
                    </b-button>
                    <b-button
                      class="ml-2"
                      size="sm"
                      variant="outline-success"
                      @click="addFilterRow(filterItemsIndex)"
                      v-show="filterItemsIndex === filterItems.length - 1"
                    >
                      <font-awesome-icon icon="plus" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-collapse>
            <!-- Advance filter options end-->

            <!-- alert when list is filtered start -->
            <b-alert
              style="margin: 0px 0px 1px 0px; background: #4d9de0"
              v-model="resetFilter"
              variant="warning"
            >
              <strong style="color: white">
                You are viewing a filtered list
              </strong>
            </b-alert>
            <!-- alert when list is filtered end -->

            <b-navbar toggleable="lg" type="dark" variant="light">
              <b-navbar-toggle target="nav-collapse" />
              <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto">
                  <!-- Add TP button -->
                  <b-button
                    v-if="hasAddThirdPartiesPermission"
                    class="mr-2"
                    variant="outline-primary"
                    size="sm"
                    @click="showAddModalNew"
                    data-test-id="manage-third-parties__button--add-third-party"
                  >
                    {{ $t("addBtn") }}
                  </b-button>

                  <!-- Actions button -->
                  <b-button
                    :disabled="isCtaDisabled"
                    size="sm"
                    class="mr-2"
                    @click="openBulkOrder()"
                    :variant="ctaVarian"
                    data-test-id="manage-third-parties__button--actions"
                  >
                    Actions
                  </b-button>
                  <!-- Act to all button -->
                  <b-button
                    variant="outline-info"
                    class="mr-2"
                    size="sm"
                    @click="openActToAll"
                    data-test-id="manage-third-parties__button--act-on-all"
                  >
                    Act On All
                  </b-button>
                  <!-- Archive TP button -->
                  <b-button
                    v-if="hasArchiveThirdPartiesPermission"
                    :disabled="isCtaDisabled"
                    class="mr-2"
                    size="sm"
                    :variant="ctaVarian"
                    @click="onOpenArchiveThirdPartyModal"
                    data-test-id="manage-third-parties__button--archive"
                  >
                    {{ $t("eb360.manageThirdParties.archiveNav") }}
                  </b-button>

                  <!-- Export TP button -->
                  <b-overlay :show="isLoadingExportThirdParty" rounded="sm">
                    <b-button
                      v-if="hasExportPermission"
                      class="mr-2"
                      size="sm"
                      variant="outline-info"
                      :disabled="isArchivedTP"
                      @click="exportThirdParty"
                      data-test-id="manage-third-parties__button--export-third-parties"
                    >
                      Export
                    </b-button>
                  </b-overlay>
                  <!-- here -->
                  <b-overlay
                    :show="isLoadingSendEmailForThirdPartyFilteredExport"
                    rounded="sm"
                  >
                    <b-button
                      v-if="hasExportPermission"
                      class="mr-2"
                      size="sm"
                      variant="outline-info"
                      @click="exportFilteredThirdParty"
                      data-test-id="manage-third-parties__button--filtered-export-third-parties"
                    >
                      Filtered export
                    </b-button>
                  </b-overlay>
                  <b-nav-form>
                    <!-- Search bar -->
                    <b-form @submit.prevent>
                      <b-input-group size="sm" class="mr-2">
                        <b-form-input
                          v-model="filter"
                          type="search"
                          :placeholder="
                            $t('eb360.manageThirdParties.searchPlaceholder')
                          "
                          @keyup.enter="submitSearch"
                          @input="onEmpltyFilter($event)"
                          data-test-id="manage-third-parties__filter-input"
                        />
                        <b-input-group-append>
                          <b-button
                            :disabled="!filter"
                            variant="outline-success"
                            @click="submitSearch"
                            data-test-id="manage-third-parties__button--submit-search"
                          >
                            {{ $t("searchBtn") }}
                          </b-button>
                          <b-button
                            :disabled="!filter"
                            @click="setFilterClear"
                            data-test-id="manage-third-parties__button--clear-search"
                          >
                            {{ $t("clearBtnText") }}
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form>

                    <!-- Advance filter button -->
                    <b-btn
                      class="mr-2"
                      size="sm"
                      variant="outline-dark"
                      :style="filterIconStyle"
                      @click="advanceFilterForms = !advanceFilterForms"
                      data-test-id="manage-third-parties__button--filters"
                    >
                      <font-awesome-icon icon="filter" />
                    </b-btn>

                    <!-- relaod table button -->
                    <b-button
                      variant="outline-info"
                      size="sm"
                      class="mr-2"
                      @click="reloadTable(true)"
                      data-test-id="manage-third-parties__button--reload"
                    >
                      <font-awesome-icon icon="sync" />
                    </b-button>

                    <!-- Configure Table Widget Button -->
                    <b-button
                      variant="outline-info"
                      size="sm"
                      class="mr-2"
                      @click="openConfigureColumnsModal"
                      data-test-id="manage-third-parties__button--configure"
                    >
                      <font-awesome-icon icon="cogs" />
                    </b-button>
                  </b-nav-form>
                </b-navbar-nav>
              </b-collapse>
            </b-navbar>

            <!-- MMTP Table data start -->
            <div v-if="showTableData">
              <b-table
                class="manage-thirdparty-table text-center"
                responsive="sm"
                select-mode="single"
                sticky-header="calc(100vh - 293px)"
                ref="table"
                id="all-third-party-table"
                :fields="visibleFields"
                :items="thirdPartiesTableData"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filterTable ? filterInputValue : null"
                fixed
                no-sort-reset
                small
                outlined
                bordered
                selectable
                @row-selected="onRowSelected"
                @sort-changed="thirdPartySortData"
                @row-dblclicked="editThirdPartyModal"
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{
                      width: field.width ? field.width + 'px' : colWidth,
                      backgroundColor:
                        field?.tdStyle?.backgroundColor || colors.white
                    }"
                  />
                </template>
                <template #head()="data">
                  <span
                    v-b-tooltip.hover
                    :title="data.field.description"
                    :style="{
                      color: data.field.headerFontColor,
                      fontSize: data.field.headerFontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <div
                      :class="
                        data?.field?.thStyle?.textAlignment || 'text-center'
                      "
                    >
                      {{ data?.field?.displayLabel || data.field.label }}
                    </div>
                  </span>
                </template>
                <template v-slot:head(actions)="data">
                  <div
                    :class="
                      data?.field?.thStyle?.textAlignment || 'text-center'
                    "
                  >
                    <span
                      v-b-tooltip.hover
                      :title="data.field.description"
                      :style="{
                        color: data.field.headerFontColor,
                        fontSize: data.field.headerFontSize + 'px',
                        width: getCellWidth(data)
                      }"
                    >
                      {{ data?.field?.displayLabel || data.field.label }}
                    </span>
                    <Checkbox
                      v-model="selectedMultipleThirdParties"
                      @update:modelValue="selectAllRows($event)"
                      label="Manage Third Party Table Action"
                      labelHidden
                    />
                  </div>
                </template>
                <template v-slot:cell(actions)="data">
                  <Checkbox
                    :modelValue="multipleThirdParties[data.index]"
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    label="Manage Third Party Table Action"
                    labelHidden
                    @update:modelValue="selectedRow($event, data)"
                    @click="handleActionCheckboxClick"
                  />
                </template>
                <template v-slot:head(ubo_idd_risk)="data">
                  <div
                    :class="
                      data?.field?.thStyle?.textAlignment || 'text-center'
                    "
                  >
                    <span
                      :title="data.field.description"
                      :style="{
                        color: data.field.headerFontColor,
                        fontSize: data.field.headerFontSize + 'px',
                        width: getCellWidth(data)
                      }"
                    >
                      {{ data?.field?.displayLabel || data.field.label }}
                    </span>
                    <Tooltip
                      text="Highest remediated risk for all screened Business Owners"
                    />
                  </div>
                </template>
                <template v-slot:head(ubo_idd_plus_risk)="data">
                  <div
                    :class="
                      data?.field?.thStyle?.textAlignment || 'text-center'
                    "
                  >
                    <span
                      :title="data.field.description"
                      :style="{
                        color: data.field.headerFontColor,
                        fontSize: data.field.headerFontSize + 'px',
                        width: getCellWidth(data)
                      }"
                    >
                      {{ data?.field?.displayLabel || data.field.label }}
                    </span>
                    <Tooltip
                      text="Highest remediated risk for all screened Business Owners"
                    />
                  </div>
                </template>
                <template v-slot:cell(tpi_type)="data">
                  <div
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <span
                      v-if="data.item.tpi_type === thirdPartyType.ORGANIZATION"
                      >Company</span
                    >
                    <span v-else>Individual</span>
                  </div>
                </template>
                <template v-slot:cell(comp_name_eng)="data">
                  <div
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <div class="manage-third-party__third-party-details--name">
                      <font-awesome-icon
                        :icon="
                          data.item.tpi_type === thirdPartyType.ORGANIZATION
                            ? 'building'
                            : 'male'
                        "
                      />
                      <router-link :to="makeCompanyProfileUrlLink(data?.item)">
                        {{ data.item.comp_name_eng }}
                      </router-link>
                      <BaseBadge
                        v-if="data.item.verified"
                        class="manage-third-party__entity-verified-icon"
                        v-bind="pillIcons.VERIFIED_PILL"
                      />
                    </div>
                    <div
                      v-if="data?.item?.alias"
                      class="manage-thirdparty-table__column-spacing"
                    >
                      <BaseText
                        text="Alias: "
                        :size="typographySize.BODY_TEXT_BOLD"
                        hasDefaultSpacingRemoved
                      />
                      <BaseText
                        :text="data?.item?.alias"
                        hasDefaultSpacingRemoved
                      />
                    </div>
                    {{
                      data.item.add_by_user &&
                      `${data.item.add_by_user}&nbsp; &nbsp;`
                    }}
                    {{ updateDateFormat(data) }}
                    <br />
                    {{ data.item.country }}
                  </div>
                </template>
                <template v-slot:cell(third_party_name)="data">
                  <div
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <div class="manage-third-party__third-party-details--name">
                      <router-link :to="makeCompanyProfileUrlLink(data?.item)">
                        {{ data.item.third_party_name }}
                      </router-link>
                      <BaseBadge
                        v-if="data.item.verified"
                        class="manage-third-party__entity-verified-icon"
                        v-bind="pillIcons.VERIFIED_PILL"
                      />
                    </div>
                    <div
                      v-if="data?.item?.alias"
                      class="manage-thirdparty-table__column-spacing"
                    >
                      <BaseText
                        text="Alias: "
                        :size="typographySize.BODY_TEXT_BOLD"
                        hasDefaultSpacingRemoved
                      />
                      <BaseText
                        hasDefaultSpacingRemoved
                        :text="data?.item?.alias"
                      />
                    </div>
                  </div>
                </template>
                <template v-slot:cell(name)="data">
                  <div
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    {{ data.item.add_by_user }}
                  </div>
                </template>
                <template v-slot:cell(country)="data">
                  <div
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    {{ data.item.country }}
                  </div>
                </template>
                <template v-slot:cell(created_date)="data">
                  <div
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    {{ updateDateFormat(data) }}
                  </div>
                </template>
                <template v-slot:cell(risk_level)="data">
                  <div
                    :class="getColumnClass(data?.field?.tdStyle?.textAlignment)"
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <IconWithText
                      v-if="
                        data.item.risk_level == 1 ||
                        data.item.risk_level == 3 ||
                        data.item.risk_level == 5 ||
                        data.item.risk_level == 6
                      "
                      v-bind="getIconWithText(data.item.risk_level)"
                    />
                  </div>
                </template>
                <template v-slot:cell(ethix_risk_rating)="data">
                  <div
                    :class="getColumnClass(data?.field?.tdStyle?.textAlignment)"
                  >
                    <IconWithText
                      v-if="
                        data.item.ethix_risk_rating > 0 &&
                        data.item.ethix_risk_rating < 4
                      "
                      v-bind="getIconWithText(data.item.ethix_risk_rating)"
                    />
                  </div>
                </template>
                <template v-slot:cell(asso_name)="data">
                  <div
                    v-if="data.item?.srch_tpi_assos?.length > 0"
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <div
                      v-for="(assos, index) in data.item.srch_tpi_assos"
                      :key="`tpi_assos-${index}`"
                    >
                      <p>{{ assos.asso_name }}</p>
                    </div>
                  </div>
                  <div
                    v-else
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <p>No Associates Added</p>
                  </div>
                </template>
                <template v-slot:cell(idd_color)="data">
                  <div
                    :class="getColumnClass(data?.field?.tdStyle?.textAlignment)"
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px'
                    }"
                  >
                    <div v-if="data.item.is_idd == '1'">
                      <IconWithText
                        v-if="
                          data.item.idd_color == 1 ||
                          data.item.idd_color == 3 ||
                          data.item.idd_color == 5 ||
                          data.item.idd_color == 6
                        "
                        v-bind="getIconWithText(data.item.idd_color)"
                      />
                    </div>
                  </div>
                </template>
                <template v-slot:cell(edd_color)="data">
                  <div
                    :class="getColumnClass(data?.field?.tdStyle?.textAlignment)"
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <div
                      v-if="
                        data.item.is_edd_or &&
                        data.item.is_edd_or == '1' &&
                        data.item.is_edd &&
                        data.item.is_edd == '0'
                      "
                    >
                      <IconWithText v-bind="riskIcons.IN_PROGRESS" />
                    </div>
                    <div v-else-if="data?.item?.is_edd == '1'">
                      <IconWithText
                        v-if="
                          data.item.edd_color == 1 || data.item.edd_color == 3
                        "
                        v-bind="getIconWithText(data.item.edd_color)"
                      />
                    </div>
                  </div>
                </template>
                <template
                  v-slot:[gomycell(col)]="data"
                  v-for="col in questionnairesColumns"
                >
                  <div
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    :key="col.id"
                  >
                    <ProductStatus
                      v-if="!isLoadingThirdParties"
                      :column-key="col.key"
                      :data="data.item"
                      :column-id="col.index"
                    />
                  </div>
                </template>
                <template v-slot:cell(config_pri)>
                  <div>
                    <span
                      v-b-tooltip.hover.bottom.html="
                        companyDetails.companyApprover === '1'
                          ? ''
                          : $store.state.disabledButtonsToolTipMsg
                      "
                    >
                      <b-avatar
                        class="white-risk-rating"
                        variant="light"
                        size="30"
                        >&nbsp;</b-avatar
                      >
                    </span>
                  </div>
                </template>
                <template v-slot:cell(abcApriRiskScoring)="data">
                  <div
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <div v-if="data.item.negativeMediaKeyTotalByCategory">
                      <div
                        v-if="
                          JSON.parse(data.item.negativeMediaKeyTotalByCategory)
                            .length
                        "
                      >
                        <div
                          v-if="
                            checkScoringFieldExist(
                              data,
                              'Anti Bribery & Corruption'
                            ) != -1
                          "
                        >
                          <div
                            v-if="
                              data.item.meta_json &&
                              JSON.parse(data.item.meta_json).action_taken
                            "
                          >
                            <ApriScoringResultComponent
                              type=""
                              :tableFieldData="data.item"
                              :columnName="'Anti Bribery & Corruption'"
                              :mediaScoring="
                                JSON.parse(
                                  data.item.negativeMediaKeyTotalByCategory
                                )[
                                  checkScoringFieldExist(
                                    data,
                                    'Anti Bribery & Corruption'
                                  )
                                ]
                              "
                            />
                          </div>
                          <div v-else>
                            <span></span>
                          </div>
                        </div>
                      </div>
                      <div v-else></div>
                    </div>
                    <div v-else>
                      <span> </span>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(hrPriRiskTotalScoring)="data">
                  <div
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <div v-if="data.item.negativeMediaKeyTotalByCategory">
                      <div
                        v-if="
                          JSON.parse(data.item.negativeMediaKeyTotalByCategory)
                            .length
                        "
                      >
                        <div
                          v-if="
                            checkScoringFieldExist(data, 'Human Right') != -1
                          "
                        >
                          <div
                            v-if="
                              data.item.meta_json &&
                              JSON.parse(data.item.meta_json).action_taken
                            "
                          >
                            <ApriScoringResultComponent
                              type=""
                              :tableFieldData="data.item"
                              :columnName="'Human Right'"
                              :mediaScoring="
                                JSON.parse(
                                  data.item.negativeMediaKeyTotalByCategory
                                )[checkScoringFieldExist(data, 'Human Right')]
                              "
                            />
                          </div>
                          <div v-else>
                            <span></span>
                          </div>
                        </div>
                      </div>
                      <div v-else></div>
                    </div>
                    <div v-else>
                      <span> </span>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(labPriRiskTotalScoring)="data">
                  <div
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <div v-if="data.item.negativeMediaKeyTotalByCategory">
                      <div
                        v-if="
                          JSON.parse(data.item.negativeMediaKeyTotalByCategory)
                            .length
                        "
                      >
                        <div
                          v-if="checkScoringFieldExist(data, 'Labour') != -1"
                        >
                          <div
                            v-if="
                              data.item.meta_json &&
                              JSON.parse(data.item.meta_json).action_taken
                            "
                          >
                            <ApriScoringResultComponent
                              type=""
                              :tableFieldData="data.item"
                              :columnName="'Labour'"
                              :mediaScoring="
                                JSON.parse(
                                  data.item.negativeMediaKeyTotalByCategory
                                )[checkScoringFieldExist(data, 'Labour')]
                              "
                            />
                          </div>
                          <div v-else>
                            <span></span>
                          </div>
                        </div>
                      </div>
                      <div v-else></div>
                    </div>
                    <div v-else>
                      <span> </span>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(envPriRiskTotalScoring)="data">
                  <div
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <div v-if="data.item.negativeMediaKeyTotalByCategory">
                      <div
                        v-if="
                          JSON.parse(data.item.negativeMediaKeyTotalByCategory)
                            .length
                        "
                      >
                        <div
                          v-if="
                            checkScoringFieldExist(
                              data,
                              'Environmental Practices'
                            ) != -1
                          "
                        >
                          <div
                            v-if="
                              data.item.meta_json &&
                              JSON.parse(data.item.meta_json).action_taken
                            "
                          >
                            <ApriScoringResultComponent
                              type=""
                              :tableFieldData="data.item"
                              :columnName="'Environmental Practices'"
                              :mediaScoring="
                                JSON.parse(
                                  data.item.negativeMediaKeyTotalByCategory
                                )[
                                  checkScoringFieldExist(
                                    data,
                                    'Environmental Practices'
                                  )
                                ]
                              "
                            />
                          </div>
                          <div v-else>
                            <span></span>
                          </div>
                        </div>
                      </div>
                      <div v-else></div>
                    </div>
                    <div v-else>
                      <span> </span>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(abcApriResilienceScoring)="data">
                  <div
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <div v-if="data.item.futureRisksKeyTotalByCategory">
                      <div
                        v-if="
                          JSON.parse(data.item.futureRisksKeyTotalByCategory)
                            .length
                        "
                      >
                        <div
                          v-if="
                            checkFutureRisksScoringFieldExist(
                              data,
                              'Anti Bribery & Corruption'
                            ) != -1
                          "
                        >
                          <div
                            v-if="
                              data.item.meta_json &&
                              JSON.parse(data.item.meta_json).action_taken
                            "
                          >
                            <ApriScoringResultComponent
                              type=""
                              :tableFieldData="data.item"
                              :columnName="'Anti Bribery & Corruption'"
                              :mediaScoring="
                                JSON.parse(
                                  data.item.futureRisksKeyTotalByCategory
                                )[
                                  checkFutureRisksScoringFieldExist(
                                    data,
                                    'Anti Bribery & Corruption'
                                  )
                                ]
                              "
                            />
                          </div>
                          <div v-else>
                            <span></span>
                          </div>
                        </div>
                      </div>
                      <div v-else></div>
                    </div>
                    <div v-else>
                      <span> </span>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(hrPriResilienceTotalScoring)="data">
                  <div
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <div v-if="data.item.futureRisksKeyTotalByCategory">
                      <div
                        v-if="
                          JSON.parse(data.item.futureRisksKeyTotalByCategory)
                            .length
                        "
                      >
                        <div
                          v-if="
                            checkFutureRisksScoringFieldExist(
                              data,
                              'Human Right'
                            ) != -1
                          "
                        >
                          <div
                            v-if="
                              data.item.meta_json &&
                              JSON.parse(data.item.meta_json).action_taken
                            "
                          >
                            <ApriScoringResultComponent
                              type=""
                              :tableFieldData="data.item"
                              :columnName="'Human Right'"
                              :mediaScoring="
                                JSON.parse(
                                  data.item.futureRisksKeyTotalByCategory
                                )[
                                  checkFutureRisksScoringFieldExist(
                                    data,
                                    'Human Right'
                                  )
                                ]
                              "
                            />
                          </div>
                          <div v-else>
                            <span></span>
                          </div>
                        </div>
                      </div>
                      <div v-else></div>
                    </div>
                    <div v-else>
                      <span> </span>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(labPriResilienceTotalScoring)="data">
                  <div
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <div v-if="data.item.futureRisksKeyTotalByCategory">
                      <div
                        v-if="
                          JSON.parse(data.item.futureRisksKeyTotalByCategory)
                            .length
                        "
                      >
                        <div
                          v-if="
                            checkFutureRisksScoringFieldExist(data, 'Labour') !=
                            -1
                          "
                        >
                          <div
                            v-if="
                              data.item.meta_json &&
                              JSON.parse(data.item.meta_json).action_taken
                            "
                          >
                            <ApriScoringResultComponent
                              type=""
                              :tableFieldData="data.item"
                              :columnName="'Labour'"
                              :mediaScoring="
                                JSON.parse(
                                  data.item.futureRisksKeyTotalByCategory
                                )[
                                  checkFutureRisksScoringFieldExist(
                                    data,
                                    'Labour'
                                  )
                                ]
                              "
                            />
                          </div>
                          <div v-else>
                            <span></span>
                          </div>
                        </div>
                      </div>
                      <div v-else></div>
                    </div>
                    <div v-else>
                      <span> </span>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(envPriResilienceTotalScoring)="data">
                  <div
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <div v-if="data.item.futureRisksKeyTotalByCategory">
                      <div
                        v-if="
                          JSON.parse(data.item.futureRisksKeyTotalByCategory)
                            .length
                        "
                      >
                        <div
                          v-if="
                            checkFutureRisksScoringFieldExist(
                              data,
                              'Environmental Practices'
                            ) != -1
                          "
                        >
                          <div
                            v-if="
                              data.item.meta_json &&
                              JSON.parse(data.item.meta_json).action_taken
                            "
                          >
                            <ApriScoringResultComponent
                              type=""
                              :tableFieldData="data.item"
                              :columnName="'Environmental Practices'"
                              :mediaScoring="
                                JSON.parse(
                                  data.item.futureRisksKeyTotalByCategory
                                )[
                                  checkFutureRisksScoringFieldExist(
                                    data,
                                    'Environmental Practices'
                                  )
                                ]
                              "
                            />
                          </div>
                          <div v-else>
                            <span></span>
                          </div>
                        </div>
                      </div>
                      <div v-else></div>
                    </div>
                    <div v-else>
                      <span> </span>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(futureRiskKeywordTotalScoring)="data">
                  <div
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <div
                      v-if="
                        data.item.futureRiskKeywordTotalScoring == null ||
                        data.item.futureRiskKeywordTotalScoring == 'null'
                      "
                    ></div>
                    <div v-else>
                      <a
                        :href="`/pri-search-control-group/${data.item.latest_control_group_search_population_id}`"
                        >{{ data.item.futureRiskKeywordTotalScoring }}</a
                      >
                    </div>
                  </div>
                </template>
                <template v-slot:cell(negativeMediaKeywordTotalScoring)="data">
                  <div
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <div
                      v-if="
                        data.item.negativeMediaKeywordTotalScoring == null ||
                        data.item.negativeMediaKeywordTotalScoring == 'null'
                      "
                    ></div>
                    <div v-else>
                      <a
                        :href="`/pri-search-control-group/${data.item.latest_control_group_search_population_id}`"
                        >{{ data.item.negativeMediaKeywordTotalScoring }}</a
                      >
                    </div>
                  </div>
                </template>
                <template v-slot:cell()="data">
                  <div
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <div
                      v-for="(customKey, customKeyIndex) in visibleFields"
                      :key="customKeyIndex"
                    >
                      <div
                        class="manage-third-party__custom-attributes-wrapper"
                        v-if="data.field.key === customKey.key"
                      >
                        <template
                          v-if="
                            isIncludedInCustomAttributes(
                              customKey.label,
                              data.item.tp_custom_att_searches
                            )
                          "
                        >
                          <template
                            v-for="customAttSearches in data.item
                              .tp_custom_att_searches"
                          >
                            <div
                              v-if="
                                isCustomAttributeTypeOf(
                                  customAttSearches,
                                  attributeType.FILE
                                ) ||
                                isCustomAttributeTypeOf(
                                  customAttSearches,
                                  attributeType.IMAGE
                                )
                              "
                              :key="`${customAttSearches?.tp_type}_option1`"
                            >
                              <p
                                class="text-primary"
                                @click="
                                  viewDocumentCall(customAttSearches.hash_id)
                                "
                                style="cursor: pointer"
                              >
                                <u>
                                  {{
                                    checkSetCustomKeysData(
                                      customKey,
                                      customAttSearches
                                    )
                                  }}
                                </u>
                              </p>
                            </div>
                            <div
                              v-else-if="
                                isCustomAttributeTypeOf(
                                  customAttSearches,
                                  attributeType.RATING
                                ) &&
                                checkCustomAttributeKey(
                                  customKey,
                                  customAttSearches
                                )
                              "
                              :key="`${customAttSearches?.tp_type}_option2`"
                            >
                              <RatingCustomAttribute
                                :value="
                                  makeCustomAttributeDTO(customAttSearches)
                                    ?.value
                                "
                                :scale-type="
                                  getCustomAttributeRatingScaleTypeId({
                                    attributeName:
                                      makeCustomAttributeDTO(customAttSearches)
                                        ?.name,
                                    customAttributeList: customAttributes
                                  })
                                "
                              />
                            </div>
                            <div
                              v-else-if="
                                !!checkSetCustomKeysData(
                                  customKey,
                                  customAttSearches
                                )
                              "
                              :key="`${customAttSearches?.tp_type}_option3`"
                              :class="{
                                'custom-border': onApplyBorder(
                                  customKey,
                                  customAttSearches
                                )
                              }"
                            >
                              {{
                                checkSetCustomKeysData(
                                  customKey,
                                  customAttSearches
                                )
                              }}
                            </div>
                          </template>
                        </template>
                        <template
                          v-else-if="
                            isCustomAttributeTypeOf(
                              { tp_type: customKey.customAttributeType },
                              attributeType.RATING
                            )
                          "
                        >
                          <RatingCustomAttribute
                            :value="
                              makeCustomAttributeDTO(undefined, customKey.id)
                                ?.value
                            "
                          />
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(risk_rating)="data">
                  <div
                    :class="getColumnClass(data?.field?.tdStyle?.textAlignment)"
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <IconWithText
                      v-if="
                        data.item.risk_rating == 0 ||
                        data.item.risk_rating == 1 ||
                        data.item.risk_rating == 2 ||
                        data.item.risk_rating == 3 ||
                        data.item.risk_rating == 4
                      "
                      v-bind="getRiskRatingIconWithText(data.item.risk_rating)"
                    />
                  </div>
                </template>
                <template v-slot:cell(ubo_idd_risk)="data">
                  <div
                    :class="getColumnClass(data?.field?.tdStyle?.textAlignment)"
                    :style="{
                      color: data?.field?.fontColor,
                      fontSize: data?.field?.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <IconWithText
                      v-bind="makeUBOIddRiskOptions(data?.item?.ubo_idd_risk)"
                    />
                  </div>
                </template>
                <template v-slot:cell(is_ubo)="data">
                  <div
                    :class="
                      data?.field?.tdStyle?.textAlignment || 'text-center'
                    "
                    :style="{
                      color: data.field.fontColor,
                      fontSize: data.field.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    {{ setIsScreenedBeneficialOwnerText(data) }}
                  </div>
                </template>
                <template v-slot:cell(ubo_idd_plus_risk)="data">
                  <div
                    :class="getColumnClass(data?.field?.tdStyle?.textAlignment)"
                    :style="{
                      color: data?.field?.fontColor,
                      fontSize: data?.field?.fontSize + 'px',
                      width: getCellWidth(data)
                    }"
                  >
                    <IconWithText
                      v-bind="
                        makeUBOIddRiskOptions(data?.item?.ubo_idd_plus_risk)
                      "
                    />
                  </div>
                </template>
              </b-table>
              <div class="text-center" v-if="totalRows == 0">
                <b> No data available </b>
              </div>
            </div>
            <!-- MMTP Table data end -->
          </div>
          <b-row>
            <b-col cols="2">
              <b-form-select
                v-model="perPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
              />
            </b-col>
            <b-col cols="8" v-if="showTable && totalRows > 0">
              <p class="text-center">
                {{ $t("eb360.manageThirdParties.resultRecords") }}
                <b>{{ fromRows }}</b>
                {{ $t("eb360.manageThirdParties.toRecords") }}
                <b>{{ toRows }}</b>
                {{ $t("eb360.manageThirdParties.ofRecords") }}
                <b>{{ totalRows }}</b>
              </p>
            </b-col>
            <b-col cols="2">
              <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                hide-ellipsis
                limit="3"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                aria-controls="existing-items-table"
              />
            </b-col>
          </b-row>
        </div>
      </b-overlay>

      <!-- Send Ddq Modal start -->
      <b-modal
        scrollable
        no-close-on-backdrop
        v-model="showSendDdqModal"
        size="lg"
        :title="
          multipleThirdParties.length == 0
            ? $t('eb360.manageThirdParties.sendTitleFor') + thirdPartyName
            : $t('eb360.manageThirdParties.sendTitle')
        "
      >
        <div>
          <div v-if="!submiting && !responseSuccess">
            <p>{{ $t("eb360.manageThirdParties.areYouSure") }}</p>
          </div>
          <div v-else-if="submiting && !responseSuccess">
            <p>{{ $t("eb360.manageThirdParties.weAreSending") }}</p>
            <b-spinner
              type="grow"
              :label="$t('loadingSpinnerText')"
            ></b-spinner>
          </div>
          <div v-else>
            <p class="my-2 text-center">
              {{ $t("eb360.manageThirdParties.sentTitle") }}
              <br />
            </p>
          </div>
        </div>
        <template v-slot:modal-footer>
          <div class="float-right">
            <b-button
              size="md"
              v-if="!submiting"
              class="mr-3"
              variant="outline-danger"
              @click="resetModal"
            >
              {{
                !submiting && !responseSuccess
                  ? $t("cancelBtnText")
                  : $t("eb360.tpDetailsAssocTab.dismiss")
              }}
            </b-button>
            <b-button
              size="md"
              v-if="!submiting && !responseSuccess"
              variant="outline-success"
              @click="sendDdq"
              >{{ $t("sendBtn") }}</b-button
            >
          </div>
        </template>
      </b-modal>
      <!-- Send Ddq Modal end -->

      <!-- Select Questionnaires Modal start -->
      <b-modal
        scrollable
        no-close-on-backdrop
        v-model="showSendDdqModalToAll"
        size="md"
        title="Select Questionnaires"
      >
        <div>
          <b-card
            style="border: 0px !important"
            no-body
            header-tag="header"
            class="mb-3"
          >
            <div>
              <b-list-group>
                <b-list-group-item
                  v-for="(action, index) in questionnaires"
                  :key="index"
                  @click="onSelectAvailableActions(action)"
                  v-show="!action.isHidden"
                  size="sm"
                  :style="{
                    backgroundColor: action.isActive ? '#d0f2d2' : 'white',
                    color: 'black',
                    width: '100%',
                    border: '0px'
                  }"
                >
                  <div style="display: flex; align-items: baseline">
                    <div>
                      <BaseIcon
                        v-if="!action.isActive && isMsqActive"
                        :shape="shapes.SQUARE"
                        :border="border.DASHED"
                        :size="25"
                      />
                      <BaseIcon
                        v-else
                        :icon="icons.PLUS"
                        :shape="shapes.SQUARE"
                        :theme="
                          action.isActive
                            ? themes.SUCCESS
                            : themes.SUCCESS_INVERSE
                        "
                        :size="25"
                      />
                    </div>
                    <div style="margin-left: 20px">
                      <p>
                        <b
                          class="d-flex"
                          :style="
                            !action.isActive && isMsqActive
                              ? 'color: lightgray'
                              : ''
                          "
                          >{{ action.btnText }}</b
                        >
                      </p>
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-card>
        </div>
        <template v-slot:modal-footer>
          <div class="float-right">
            <b-button
              size="md"
              class="mr-3"
              variant="outline-danger"
              @click="resetModal"
              >Cancel</b-button
            >
            <b-button
              size="md"
              variant="outline-success"
              @click="openSendDdqConfirmModal"
              :disabled="!isMsqActive"
              >{{ $t("sendBtn") }}</b-button
            >
          </div>
        </template>
      </b-modal>
      <!-- Select Questionnaires Modal end -->

      <!-- Send Questionnaires Modal start -->
      <b-modal
        scrollable
        no-close-on-backdrop
        v-model="showSendDdqConfirmModal"
        size="lg"
        :title="$t('eb360.manageThirdParties.sendTitle')"
      >
        <div>
          <div v-if="!submiting && !responseSuccess">
            <p>
              Are you sure you would like to send the
              <b>{{ questionnaireName }}</b> to all third parties who have not
              previously been sent the questionnaire?
            </p>
            <p>
              Please note this feature does not resend the
              <b>{{ questionnaireName }}</b> to third parties who have
              previously been issued with it.
            </p>
          </div>
          <div v-else-if="submiting && !responseSuccess">
            <p>{{ $t("eb360.manageThirdParties.weAreSending") }}</p>
            <b-spinner
              type="grow"
              :label="$t('loadingSpinnerText')"
            ></b-spinner>
          </div>
          <div v-else>
            <p class="my-2 text-center">
              {{ $t("eb360.manageThirdParties.sentTitle") }}
              <br />
            </p>
          </div>
        </div>
        <template v-slot:modal-footer>
          <div class="float-right">
            <b-button
              size="md"
              v-if="!submiting"
              class="mr-3"
              variant="outline-danger"
              @click="resetModal"
            >
              {{
                !submiting && !responseSuccess
                  ? $t("cancelBtnText")
                  : $t("eb360.tpDetailsAssocTab.dismiss")
              }}
            </b-button>
            <b-button
              size="md"
              v-if="!submiting && !responseSuccess"
              variant="outline-success"
              @click="sendDdqToAll"
              >{{ $t("sendBtn") }}</b-button
            >
          </div>
        </template>
      </b-modal>
      <!-- Send Questionnaires Modal end -->

      <!-- Add TP email Modal start -->
      <b-modal
        scrollable
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        v-model="showAddThirdPartyEmail"
        size="md"
        :title="
          !submiting && !responseSuccess
            ? $t('addBtn') +
              ' ' +
              thirdPartyName +
              ' ' +
              $t('eb360.footerComponent.tpEmail')
            : $t('eb360.manageThirdParties.sendTitleFor') + thirdPartyName
        "
      >
        <div>
          <div v-if="!submiting && !responseSuccess">
            <p>
              {{ $t("eb360.manageThirdParties.addEmail") }}
              <b>{{ thirdPartyName }}</b>
              {{ $t("eb360.manageThirdParties.thirdPartyFirst") }}
            </p>
            <b-form-group>
              <label>
                {{ $t("eb360.manageThirdParties.thirdPartyEmail") }}
                <span class="text-danger">*</span>
              </label>
              <b-form-input
                v-model="v$.form.thirdPartyEmail.$model"
                :state="
                  v$.form.thirdPartyEmail.$dirty
                    ? !v$.form.thirdPartyEmail.$error
                    : null
                "
                type="email"
                :placeholder="
                  $t('eb360.manageThirdParties.thirdPirtyplaceholder')
                "
              ></b-form-input>
            </b-form-group>
          </div>
          <div v-else-if="submiting && !responseSuccess">
            <p>{{ $t("eb360.manageThirdParties.weAreSending") }}</p>
            <b-spinner
              type="grow"
              :label="$t('loadingSpinnerText')"
            ></b-spinner>
          </div>
          <div v-else>
            <p class="my-2 text-center">
              {{ $t("eb360.manageThirdParties.sentTitle") }}
              <br />
            </p>
          </div>
        </div>
        <template v-slot:modal-footer>
          <b-button
            size="md"
            v-if="!submiting"
            variant="outline-danger"
            @click="resetAddThirdPartyModal"
          >
            {{
              !submiting && !responseSuccess
                ? $t("cancelBtnText")
                : $t("eb360.tpDetailsAssocTab.dismiss")
            }}
          </b-button>
          <b-button
            size="md"
            v-if="!submiting && !responseSuccess"
            variant="outline-success"
            @click="addThirdParryEmail()"
            >{{ $t("sendBtn") }}</b-button
          >
        </template>
      </b-modal>
      <!-- Add TP email Modal end -->

      <!-- Archive TP Modal start -->
      <b-modal
        scrollable
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        v-model="showArchiveThirdPartiesModal"
        size="md"
        :title="$t('eb360.manageThirdParties.archieveTitle')"
      >
        <div>
          <div v-if="!submiting && !responseSuccess">
            {{ $t("eb360.manageThirdParties.selectedRecords") }}
          </div>
          <div v-else-if="submiting && !responseSuccess">
            <p>{{ $t("eb360.manageThirdParties.archiveTP") }}</p>
            <b-spinner
              type="grow"
              :label="$t('loadingSpinnerText')"
            ></b-spinner>
          </div>
          <div v-else>
            <p class="my-2 text-center">
              {{ $t("eb360.manageThirdParties.thirdPartyAchieved") }}
              <br />
            </p>
          </div>
        </div>
        <template v-slot:modal-footer>
          <b-button
            size="md"
            v-if="!submiting"
            variant="outline-danger"
            @click="resetArchiveThirdPartyModal"
            >{{
              !submiting && !responseSuccess
                ? $t("eb360.manageThirdParties.no")
                : $t("closeBtnText")
            }}
          </b-button>
          <b-button
            size="md"
            v-if="!submiting && !responseSuccess"
            variant="outline-success"
            @click="archiveThirdPartyCall"
            >{{ $t("eb360.manageThirdParties.yes") }}</b-button
          >
        </template>
      </b-modal>
      <!-- Archive TP Modal end -->

      <!-- Order Idd Modal start -->
      <b-modal
        scrollable
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        v-model="showOrderIddModal"
        size="md"
        :title="'Order Idd For ' + thirdPartyName"
      >
        <div>
          <div v-if="!submiting && !responseSuccess">
            {{ $t("eb360.manageThirdParties.iddOrder") }}
          </div>
          <div v-else-if="submiting && !responseSuccess">
            <p>
              {{ $t("eb360.manageThirdParties.iddOrdering") }}
              {{ thirdPartyName }}......
            </p>
            <b-spinner
              type="grow"
              :label="$t('loadingSpinnerText')"
            ></b-spinner>
          </div>
          <div v-else>
            <p class="my-2 text-center">
              {{ $t("eb360.manageThirdParties.iddOrdered") }}
              <br />
            </p>
          </div>
        </div>
        <template v-slot:modal-footer>
          <b-button
            size="md"
            v-if="!submiting"
            variant="outline-danger"
            @click="resetOrderIddModal"
            >{{
              !submiting && !responseSuccess
                ? $t("eb360.manageThirdParties.no")
                : $t("eb360.manageThirdParties.done")
            }}
          </b-button>
          <b-button
            size="md"
            v-if="!submiting && !responseSuccess"
            variant="outline-success"
            @click="orderIddCall"
            >{{ $t("eb360.manageThirdParties.yes") }}</b-button
          >
        </template>
      </b-modal>
      <!-- Order Idd Modal end -->

      <!-- Act to all modal start -->
      <b-modal
        scrollable
        no-close-on-backdrop
        v-model="showActToAll"
        size="md"
        title="Act On all"
      >
        <div>
          <b-card
            style="border: 0px !important"
            no-body
            header-tag="header"
            class="mb-3"
          >
            <div>
              <b-overlay :show="submitingMSQandReminderToAll" rounded="sm">
                <b-list-group>
                  <b-list-group-item
                    v-for="(action, index) in allQuestionnaires"
                    :key="index"
                    @click="onSelectAllQuestionnaires(action)"
                    v-show="!action.isHidden"
                    size="sm"
                    :disabled="action.isDisabled"
                    :style="{
                      backgroundColor: action.isActive ? '#d0f2d2' : 'white',
                      color: 'black',
                      width: '100%',
                      border: '0px',
                      opacity: action.isDisabled ? '0.5' : ''
                    }"
                  >
                    <div style="display: flex">
                      <div>
                        <BaseIcon
                          :icon="icons.PLUS"
                          :shape="shapes.SQUARE"
                          :theme="
                            action.isActive
                              ? themes.SUCCESS_WHITE
                              : themes.SUCCESS_INVERSE_WHITE
                          "
                          :size="25"
                        />
                      </div>
                      <div style="margin-left: 20px">
                        <p>
                          <b>{{
                            action.alias ? action.alias : action.title
                          }}</b>
                        </p>
                        <p>
                          <i style="font-size: 14px">{{
                            action.subTitle ? action.subTitle : ""
                          }}</i>
                        </p>
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-overlay>
            </div>
          </b-card>
        </div>
        <template v-slot:modal-footer>
          <div class="float-right">
            <b-button
              size="md"
              class="mr-3"
              variant="outline-danger"
              @click="resetModal"
              >Cancel</b-button
            >
            <b-button
              size="md"
              variant="outline-success"
              :disabled="!isAllMsqActive"
              @click="onTriggerBulkActions"
              >{{ $t("sendBtn") }}</b-button
            >
          </div>
        </template>
      </b-modal>
      <!-- Act to all modal end -->

      <!-- Act to all Error modal start -->
      <b-modal
        scrollable
        no-close-on-backdrop
        v-model="showESGErrorModal"
        size="md"
        title="Generate Greenlite reporting on all entities"
      >
        <div>
          <b-card
            style="border: 0px !important"
            no-body
            header-tag="header"
            class="mb-3"
          >
            <div>
              <b-overlay :show="submitingMSQandReminderToAll" rounded="sm">
                <div>{{ errorMessage }}</div>
              </b-overlay>
            </div>
          </b-card>
        </div>
        <template v-slot:modal-footer>
          <div class="float-right">
            <b-button
              size="md"
              class="mr-3"
              variant="outline-danger"
              @click="resetModal"
              >Cancel</b-button
            >
            <b-button
              size="md"
              variant="outline-success"
              :disabled="!isAllMsqActive"
              @click="sendMSQAndReminderToAll"
              >{{ $t("sendBtn") }}</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
    <BulkPerformActionsModal @reload-table="reloadTable(true)" />
  </div>
</template>

<script>
import {
  getCustomAttributeRatingScaleTypeId,
  getIconWithText,
  getRiskIconBasedOnStatus,
  getRiskRatingIconWithText,
  hasPermission,
  HTTP,
  makeAuthorizationHeader,
  makeUUID,
  hasStatus404,
  hasStatus422,
  makeDateToMatchFormat
} from "@/utils";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import moment from "moment";
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import draggable from "vuedraggable";
import InputColorPicker from "vue-native-color-picker";
import "moment/locale/es";
import { EventBus } from "@/event-bus.js";
import staticFieldsJson from "@/utils/staticFields.json";
import TableConfigurationJson from "@/utils/tableConfigurationJson.json";
import BulkOrderDefaultActions from "@/utils/bulkOrderDefaultActions.json";
import {
  actionName,
  attributeType,
  border,
  colors,
  endpoints,
  featurePermissionsKeys,
  getterName,
  icons,
  mutationName,
  pillIcons,
  riskIcons,
  riskStatusMap,
  shapes,
  themes,
  THIRD_PARTY_LIST_FILTERS,
  thirdPartyActionType,
  thirdPartyType,
  typographySize,
  urls,
  visibleFields,
  customErrorMessages,
  infoMessages,
  toastPropSlowHideSuccess,
  toastPropSlowHideFailure,
  eb360DateFormat
} from "@/constants";
import BulkPerformActionsModal from "@/components/eb2/manageThirdParty/bulkPerformActionsModal";
import ApriScoringResultComponent from "@/components/eb2/manageThirdParty/apriScoringResult";
import ProductStatus from "@/organisms/ProductStatus/ProductStatus";
import MsqFilterComponent from "@/components/eb2/manageThirdParty/MsqFilterComponent";
import CustomAttributesFilterComponent from "@/components/eb2/manageThirdParty/CustomAttributesFilterComponent";
import RatingCustomAttribute from "@/organisms/RatingCustomAttribute/RatingCustomAttribute";
import { makeCustomAttributeDTO } from "@/organisms/RatingCustomAttribute/RatingCustomAttribute.dto.js";
import { cloneDeep, isEmpty } from "lodash";
import defaultActionJson from "@/utils/defaultAction.json";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import IconWithText from "@/molecules/IconWithText/IconWithText";
import Tooltip from "@/molecules/Tooltip/Tooltip";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseBadge from "@/atoms/BaseBadge/BaseBadge";
import Checkbox from "@/molecules/Checkbox/Checkbox";
import DatePicker from "@/molecules/DatePicker/DatePicker";

draggable.compatConfig = { MODE: 3 };

export default {
  name: "ManageThirdParties",
  components: {
    BaseBadge,
    BulkPerformActionsModal,
    draggable,
    InputColorPicker,
    ApriScoringResultComponent,
    ProductStatus,
    MsqFilterComponent,
    CustomAttributesFilterComponent,
    RatingCustomAttribute,
    BaseIcon,
    IconWithText,
    Tooltip,
    BaseText,
    Checkbox,
    DatePicker
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      eb360DateFormat,
      themes,
      icons,
      shapes,
      border,
      riskIcons,
      thirdPartyType,
      attributeType,
      urls,
      colors,
      typographySize,
      pillIcons,
      thirdPartyIdsActionsData: [],
      showTableData: false,
      actionsSchema: null,
      selectedMultipleThirdParties: false,
      multipleThirdParties: [],
      showOrderIddModal: false,
      disableButtonsIdd: true,
      thirdPartiesIds: [],
      formsOptions: [],
      selectedForm: null,
      wordNumber: [
        { value: 0, text: this.$t("wordNumber.zero") },
        { value: 1, text: this.$t("wordNumber.one") },
        { value: 2, text: this.$t("wordNumber.two") },
        { value: 3, text: this.$t("wordNumber.three") },
        { value: 4, text: this.$t("wordNumber.four") },
        { value: 5, text: this.$t("wordNumber.five") },
        { value: 6, text: this.$t("wordNumber.six") },
        { value: 7, text: this.$t("wordNumber.seven") },
        { value: 8, text: this.$t("wordNumber.eight") },
        { value: 9, text: this.$t("wordNumber.nine") },
        { value: 10, text: this.$t("wordNumber.ten") }
      ],
      orderEddUrl: null,
      form: {
        thirdPartyEmail: null
      },
      advanceFilterForms: false,
      thirdPartyEmail: null,
      eb2AppUrl: process.env.VUE_APP_BASE_URL,
      filterTable: false,
      resetFilter: false,
      filterAdvance: {
        entityType: [],
        country: [],
        iddResults: [],
        pri: [],
        eddAlert: [],
        iddPlusAlert: [],
        riskRating: [],
        tCertStatus: [],
        tCertPaymentOptions: [],
        questionnaire: [],
        latestCompletedMSQResult: {},
        latestMSQTaskStatus: {},
        isArchived: 0,
        createdBy: [],
        isUBO: [],
        uboIDD: [],
        uboIDDPlus: []
      },
      advanceFilter: {
        entityType: [],
        country: [],
        uploadedDateFrom: null,
        lastMSQSentUploadedDateFrom: {},
        lastMSQSentUploadedDateTo: {},
        uploadedDateTo: null,
        tCertCreatedDateTo: null,
        tCertCreatedDateFrom: null,
        tCertUploadedDateTo: null,
        tCertUploadedDateFrom: null,
        iddResults: [],
        pri: [],
        eddAlert: [],
        iddPlusAlert: [],
        riskRating: [],
        tCertStatus: [],
        tCertPaymentOptions: [],
        isUBO: [],
        uboIDD: [],
        uboIDDPlus: [],
        questionnaire: [],
        latestCompletedMSQResult: {},
        latestMSQTaskStatus: {},
        isArchived: { text: "Not Archived", value: "0" },
        createdBy: []
      },
      optionsFilterEntityType: [
        {
          text: this.$t("eb360.manageThirdParties.filterEntityOrg"),
          value: thirdPartyType.ORGANIZATION
        },
        {
          text: this.$t("eb360.manageThirdParties.filterEntityIndi"),
          value: thirdPartyType.INDIVIDUAL
        }
      ],
      optionsFilterEddAlert: [
        { text: this.$t("colors.green"), value: "1" },
        { text: this.$t("colors.red"), value: "3" },
        { text: this.$t("eb360.manageThirdParties.notOrdered"), value: "0" },
        {
          text: this.$t("eb360.manageThirdParties.notOrderedStatus"),
          value: "4"
        }
      ],
      optionsFilterIddPlusAlert: [
        { text: this.$t("colors.green"), value: "1" },
        { text: this.$t("colors.red"), value: "3" },
        {
          text: this.$t("eb360.manageThirdParties.filterIddGreen"),
          value: "5"
        },
        { text: this.$t("eb360.manageThirdParties.filterIddRed"), value: "6" },
        { text: this.$t("eb360.manageThirdParties.notOrdered"), value: "0" }
      ],
      optionsFilterRiskRating: [
        { text: "Unclassified", value: "0" },
        { text: "Low", value: "1" },
        { text: "Moderate", value: "2" },
        { text: "High", value: "3" },
        { text: "Do not engage ", value: "4" }
      ],
      optionsIsScreenedUBO: [
        { text: "No", value: "0" },
        { text: "Yes", value: "1" }
      ],
      optionsTCertStatus: [
        { text: "In progress", value: 1 },
        { text: "Completed", value: 2 }
      ],
      optionsTCertPaymentOptions: [
        { text: "Third party will pay, renew", value: 1 },
        { text: "Third party will pay, do not renew", value: 2 },
        { text: "We will pay, renew", value: 3 },
        { text: "We will pay, do not renew", value: 4 }
      ],
      optionsUBOIDD: [
        { text: "Not run", value: "not_requested" },
        { text: "In progress", value: "in_progress" },
        { text: "No matched risks found", value: "green" },
        { text: "Risk alert", value: "red_remediated" }
      ],
      optionsFilterIddResults: [
        { text: this.$t("eb360.manageThirdParties.riskBadge"), value: "3" },
        { text: this.$t("eb360.manageThirdParties.noRiskBadge"), value: "1" },
        { text: this.$t("eb360.manageThirdParties.riskRBadge"), value: "6" },
        { text: this.$t("eb360.manageThirdParties.noRiskRBadge"), value: "5" },
        { text: this.$t("eb360.manageThirdParties.notOrdered"), value: "0" }
      ],
      optionsFilterPri: [
        { text: this.$t("eb360.manageThirdParties.lowBadge"), value: "1" },
        { text: this.$t("eb360.manageThirdParties.mediumBadge"), value: "2" },
        { text: this.$t("eb360.manageThirdParties.highBadge"), value: "3" }
      ],
      optionsFilterQuestionnaire: [
        { text: this.$t("eb360.manageThirdParties.notSent"), value: "-1" },
        {
          text: this.$t("eb360.manageThirdParties.notStartedStatus"),
          value: "0"
        },
        {
          text: this.$t("eb360.manageThirdParties.progressStatus"),
          value: "1"
        },
        { text: this.$t("eb360.manageThirdParties.completeBadge"), value: "4" },
        {
          text: this.$t("eb360.manageThirdParties.cancelledStatus"),
          value: "8"
        },
        { text: this.$t("eb360.manageThirdParties.declineStatus"), value: "9" },
        {
          text: this.$t("eb360.manageThirdParties.selfEvidencedStatus"),
          value: "10"
        },
        { text: "In Progress - Pause Reminders", value: "14" }
      ],
      optionsFilterLatestCompletedMSQResult: [
        { text: "High", value: "1" },
        { text: "Low", value: "2" },
        { text: "Moderate", value: "3" }
      ],
      optionsFilterLatestMSQTaskStatus: [
        { text: "Not Started", value: "0" },
        { text: "In Progress", value: "1" },
        { text: "Completed", value: "4" },
        { text: "Cancelled", value: "8" },
        { text: "Declined / Refused", value: "9" },
        { text: "Self Evidenced", value: "10" },
        { text: "Never Sent", value: "-1" },
        { text: "In Progress - Pause Reminders", value: "14" }
      ],
      callFilterApi: true,
      disableButtons: true,
      responseSuccess: false,
      submiting: false,
      showArchiveThirdPartiesModal: false,
      archiveThirdParties: [],
      totalRows: -1,
      thirdPartiesTableData: [],
      isLoadingThirdParties: false,
      filter: null,
      perPage: 50,
      currentPage: 1,
      sortBy: "created_date",
      sortDesc: true,
      colWidth: "100px",
      configureTableAvailableCols: [],
      visibleFields: [],
      modalConfiguredColumns: [],
      pageOptions: [5, 10, 25, 50, 75, 100, 200, 500],
      fromRows: 0,
      toRows: 0,
      showSendDdqModal: false,
      showSendDdqModalToAll: false,
      thirdParties: [],
      thirdPartyName: null,
      platformCustomAttributes: [],
      setRiskCategoriesColumns: [],
      showAddThirdPartyEmail: false,
      userDetail: null,
      showSelectColumnModal: false,
      dynamicTableCol: TableConfigurationJson.DynamicTableColumns,
      showTable: true,
      editBtnDisable: true,
      variants: TableConfigurationJson.variantsArray,
      alignmentArray: TableConfigurationJson.textAlignmentArray,
      filterInputValue: null,
      isLoadingExportThirdParty: false,
      showExportThirdPartyModal: false,
      exportMessage: null,
      isSelectedRow: false,
      isLoading: false,
      filterData: [],
      filterEnable: 0,
      optionsFilterIsArchived: [
        { text: "All", value: null },
        { text: "Archived", value: "1" },
        { text: "Not Archived", value: "0" }
      ],
      setFilterData: [],
      questionnaires: [],
      showSendDdqConfirmModal: false,
      isMsqActive: false,
      questionnaireName: null,
      questionnairesReminder: [],
      isArchivedTP: false,
      showActToAll: false,
      allQuestionnaires: [],
      isAllMsqActive: false,
      submitingMSQandReminderToAll: false,
      questionnairesColumns: [],
      questionnairesFilterColumns: [],
      formQuestionnaires: [],
      isMsqDataAvailable: false,
      isMsqFilterActive: false,
      isFilterActive: false,
      customAttributes: [],
      customAttributesFilterValue: {},
      optionsUsers: [],
      isLoadingUsers: false,
      filterOptions: [
        { text: "Entity Type", value: "entityType" },
        { text: "Country/Territory of Registration", value: "countries" },
        { text: "Uploaded Date (From)", value: "uploadedDateFrom" },
        { text: "Uploaded Date (To)", value: "uploadedDateTo" },
        {
          text: "Tcertification Created Date (To)",
          value: "tCertCreatedDateTo"
        },
        {
          text: "Tcertification Created Date (From)",
          value: "tCertCreatedDateFrom"
        },
        {
          text: "Tcertification Uploaded Date (To)",
          value: "tCertUploadedDateTo"
        },
        {
          text: "Tcertification Uploaded Date (From)",
          value: "tCertUploadedDateFrom"
        },
        { text: "IDD S&E Results", value: "idd" },
        { text: "IDD+ Alert", value: "idd+" },
        { text: "EDD Alert", value: "edd" },
        { text: "Standard PRI ™", value: "pri" },
        { text: "My Risk Rating", value: "riskRating" },
        { text: "Tcertification Status", value: "tCertStatus" },
        {
          text: "Tcertification Payment Options",
          value: "tCertPaymentOptions"
        },
        { text: "Archived/ Not Archived", value: "isArchive" },
        { text: "Created By", value: "createdBy" }
      ],
      filterItems: [
        {
          filterType: null,
          filterTypeValue: null,
          selectedFilterOptions: []
        }
      ],
      nonFilterOptions: [],
      showExportESGModal: false,
      errorMessage: null,
      showESGErrorModal: false,
      primitiveCustomAttributesList: [
        attributeType.TEXT,
        attributeType.EMAIL,
        attributeType.HTML_STRING,
        attributeType.TEXTAREA,
        attributeType.URL,
        attributeType.INTEGER,
        attributeType.DOUBLE,
        attributeType.COLOR
      ],
      multiSelectionCustomAttributesSubList: [
        attributeType.DROPDOWN,
        attributeType.MULTISELECT,
        attributeType.RADIO,
        attributeType.RATING,
        attributeType.MULTISELECT_COUNTRIES
      ],
      getCustomAttributeRatingScaleTypeId,
      getIconWithText,
      getRiskRatingIconWithText,
      riskStatusMap,
      dateFilterTypes: {
        uploadedDateFrom: "uploadedDateFrom",
        uploadedDateTo: "uploadedDateTo",
        tCertCreatedDateTo: "tCertCreatedDateTo",
        tCertCreatedDateFrom: "tCertCreatedDateFrom",
        tCertUploadedDateTo: "tCertUploadedDateTo",
        tCertUploadedDateFrom: "tCertUploadedDateFrom"
      }
    };
  },
  validations: {
    form: {
      thirdPartyEmail: {
        required,
        email
      }
    }
  },
  computed: {
    ...mapGetters({
      isUserSuperAdmin: getterName.USER.IS_USER_SUPER_ADMIN,
      isUserSuperAdminOrAdmin: getterName.USER.IS_USER_SUPER_ADMIN_OR_ADMIN,
      isDNBFeatureEnabled: getterName.COMPANY.IS_DNB_FEATURE_ENABLED,
      hasUBOFeature: getterName.COMPANY.HAS_UBO_FEATURE
    }),
    ...mapState({
      platformKnowledgeCentreLink: (state) =>
        state.platformSettings.platformKnowledgeCentreLink,
      companyId: (state) => state.company.companyId,
      companyName: (state) => state.company.companyName,
      email: (state) => state.user.email,
      userId: (state) => state.user.userId,
      countries: (state) => state.country.countries,
      companyDetails: (state) => state.company.companyDetails,
      isAddThirdPartyModalNewVisible: (state) =>
        state.thirdParty.isAddThirdPartyModalNewVisible,
      isBulkUploadModalVisible: (state) =>
        state.thirdParty.isBulkUploadModalVisible,
      tenantId: (state) => state.company.tenantId,
      isLoadingThirdPartyDetails: (state) =>
        state.thirdParty.isLoadingThirdPartyDetails
    }),
    isLoadingTable() {
      return (
        this.isLoadingThirdPartyDetails ||
        this.isLoadingThirdParties ||
        this.showSelectColumnModal ||
        !this.showTableData
      );
    },
    hasTriggerActOnAllPermission() {
      return hasPermission({ key: featurePermissionsKeys.ACT_ON_ALL });
    },
    hasArchiveThirdPartiesPermission() {
      return hasPermission({
        key: featurePermissionsKeys.ARCHIVE_THIRD_PARTIES
      });
    },
    hasAddThirdPartiesPermission() {
      return hasPermission({ key: featurePermissionsKeys.ADD_THIRD_PARTIES });
    },
    hasExportPermission() {
      return hasPermission({ key: featurePermissionsKeys.EXPORT });
    },
    hasEditThirdPartiesPermission() {
      return hasPermission({
        key: featurePermissionsKeys.EDIT_THIRD_PARTIES
      });
    },
    isCtaDisabled() {
      if (this.isArchivedTP) {
        return true;
      } else if (this.archiveThirdParties.length > 0) {
        return false;
      } else {
        return !!this.disableButtons;
      }
    },
    ctaVarian() {
      return this.archiveThirdParties.length > 0 || !this.disableButtons
        ? "info"
        : "outline-info";
    },
    filterIconStyle() {
      return this.resetFilter
        ? `background-color:  ${colors.hanBlue}; color: ${colors.white}`
        : "";
    },
    multiSelectionCustomAttributesList() {
      return [
        ...this.multiSelectionCustomAttributesSubList,
        attributeType.COUNTRY_LIST,
        attributeType.BOOLEAN,
        attributeType.USER
      ];
    },
    customAttributesList() {
      return [
        ...this.primitiveCustomAttributesList,
        ...this.multiSelectionCustomAttributesList
      ];
    },
    makeUBOFilterParameter() {
      return this.hasUBOFeature ? { is_ubo: this.setIsUBODefaultValue() } : {};
    }
  },
  watch: {
    isAddThirdPartyModalNewVisible(newValue) {
      if (!newValue) this.reloadTable();
    },
    isBulkUploadModalVisible(newValue) {
      if (!newValue) this.reloadTable();
    },
    $route(to, from) {
      if (to !== from) {
        this.onReload();
      }
    },
    companyId(to, from) {
      if (to !== from) {
        this.onReload(true);
      }
    },
    async perPage(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.fetchThirdPartyTableWithAdvanceFilter();
      }
    },
    async currentPage(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.fetchThirdPartyTableWithAdvanceFilter();
      }
    },
    async sortBy(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.fetchThirdPartyTableWithAdvanceFilter();
      }
    },
    async sortDesc(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.fetchThirdPartyTableWithAdvanceFilter();
      }
    }
  },
  async mounted() {
    this.onReload();
  },
  created() {
    EventBus.on("onBulkPerformActionsClose", () => {
      this.multipleThirdParties = [];
      this.selectedMultipleThirdParties = false;
      this.thirdPartyIdsActionsData = [];
    });
    EventBus.on("closeNewViewModal", () => {
      this.onReload(true);
    });
    EventBus.on("setFilterUploadDateFrom", (data) => {
      const { filterIndex, lastMSQSentUploadedDateFrom, index } = data;
      this.filterItems[filterIndex].filterTypeValue =
        lastMSQSentUploadedDateFrom;
      this.advanceFilter.lastMSQSentUploadedDateFrom[index] =
        lastMSQSentUploadedDateFrom;
      this.getUniqueformQuestionnaires(lastMSQSentUploadedDateFrom, index);
      this.checkMsqFilterValue();
    });
    EventBus.on("setFilterUploadDateTo", (data) => {
      const { filterIndex, lastMSQSentUploadedDateTo, index } = data;
      this.filterItems[filterIndex].filterTypeValue = lastMSQSentUploadedDateTo;
      this.advanceFilter.lastMSQSentUploadedDateTo[index] =
        lastMSQSentUploadedDateTo;
      this.getUniqueformQuestionnaires(lastMSQSentUploadedDateTo, index);
      this.checkMsqFilterValue();
    });
    EventBus.on("selectFilterLatestCompletedMSQResult", (data) => {
      const { filterIndex, filterTypeValue, latestCompletedMSQResult, index } =
        data;
      this.filterItems[filterIndex].filterTypeValue = filterTypeValue;
      this.advanceFilter.latestCompletedMSQResult[index] =
        latestCompletedMSQResult;
      this.getUniqueformQuestionnaires(latestCompletedMSQResult, index);
      this.checkMsqFilterValue();
    });
    EventBus.on("selectFilterLatestMSQTaskStatus", (data) => {
      const { filterIndex, filterTypeValue, latestMSQTaskStatus, index } = data;
      this.filterItems[filterIndex].filterTypeValue = filterTypeValue;
      this.advanceFilter.latestMSQTaskStatus[index] = latestMSQTaskStatus;
      this.getUniqueformQuestionnaires(latestMSQTaskStatus, index);
      this.checkMsqFilterValue();
    });
    EventBus.on("saveFilterValue", (data) => {
      const {
        filterIndex,
        filterTypeValue,
        filterValue,
        id,
        type,
        filterType
      } = data;
      this.filterItems[filterIndex].filterTypeValue = filterTypeValue;
      if (
        this.customAttributesFilterValue &&
        this.customAttributesFilterValue[`customatt_${id}`] &&
        type === attributeType.DATE
      ) {
        if (
          this.customAttributesFilterValue[`customatt_${id}`].startDate ||
          this.customAttributesFilterValue[`customatt_${id}`].endDate
        ) {
          if (filterType.text.includes("From")) {
            this.customAttributesFilterValue[`customatt_${id}`].startDate =
              filterValue;
          } else if (filterType.text.includes("To")) {
            this.customAttributesFilterValue[`customatt_${id}`].endDate =
              filterValue;
          }
        }
      } else if (type === attributeType.DATE) {
        let startDate = "";
        let endDate = "";
        if (filterType.text.includes("From")) {
          startDate = filterValue;
        } else if (filterType.text.includes("To")) {
          endDate = filterValue;
        }
        let dateObj = {
          startDate,
          endDate
        };
        this.customAttributesFilterValue[`customatt_${id}`] = dateObj;
      } else {
        this.customAttributesFilterValue[`customatt_${id}`] = filterValue;
      }
      this.callFilterApi = false;
    });
  },
  beforeDestroy() {
    this.resetThirdPartyDetails();
    EventBus.off("onBulkPerformActionsClose");
  },
  methods: {
    makeDateToMatchFormat,
    makeCustomAttributeDTO,
    ...mapMutations({
      setIsThirdPartyAddModalVisible:
        mutationName.THIRD_PARTY.SET_IS_THIRD_PARTY_ADD_MODAL_VISIBLE,
      setIsThirdPartyNewModalVisible:
        mutationName.THIRD_PARTY.SET_IS_THIRD_PARTY_NEW_MODAL_VISIBLE,
      setThirdPartySelectedId:
        mutationName.THIRD_PARTY.SET_THIRD_PARTY_SELECTED_ID,
      resetThirdPartyDetails: mutationName.THIRD_PARTY.RESET_THIRD_PARTY_DETAILS
    }),
    ...mapActions({
      fetchAdditionalVouchers: actionName.COMPANY.FETCH_ADDITIONAL_VOUCHERS,
      fetchCustomAttributesList:
        actionName.CUSTOM_ATTRIBUTES.FETCH_CUSTOM_ATTRIBUTES_LIST,
      sendEmailForThirdPartyFilteredExport:
        actionName.THIRD_PARTY.SEND_EMAIL_FOR_THIRD_PARTY_FILTERED_EXPORT
    }),
    handleActionCheckboxClick(event) {
      event.stopImmediatePropagation();
    },
    isValidDateFilterType(filters) {
      return Object.values(this.dateFilterTypes)?.includes(
        filters?.filterType?.value
      );
    },
    handleDatepickerEvent(event, filters) {
      switch (filters?.filterType?.value) {
        case this.dateFilterTypes.uploadedDateFrom:
          return this.setFilterUploadDateFrom(event);

        case this.dateFilterTypes.uploadedDateTo:
          return this.setFilterUploadDateTo(event);

        case this.dateFilterTypes.tCertCreatedDateTo:
          return this.setTCertCreatedDateTo(event);

        case this.dateFilterTypes.tCertCreatedDateFrom:
          return this.setTCertCreatedDateFrom(event);

        case this.dateFilterTypes.tCertUploadedDateTo:
          return this.setTCertUploadedDateTo(event);

        case this.dateFilterTypes.tCertUploadedDateFrom:
          return this.setTCertUploadedDateFrom(event);
      }
    },
    isIncludedInCustomAttributes(name, customAttributesList) {
      return !!customAttributesList.find(
        ({ tp_customatt }) => tp_customatt === name
      );
    },
    showTrashIcon(filterItemsIndex, filterItems) {
      if (filterItemsIndex === 0) {
        if (filterItems[0]?.filterType || filterItems.filterType) {
          return true;
        } else if (filterItems[0]?.filterTypeValue) {
          return !!filterItems[0].filterTypeValue;
        }
      } else {
        return false;
      }
    },
    isCustomAttributeTypeOf(customAttSearches, currentType) {
      return customAttSearches.tp_type === currentType;
    },
    dynamicSort(property) {
      return function (a, b) {
        return a[property].localeCompare(b[property]);
      };
    },
    viewDocumentCall(hashId) {
      const urlAddress = `${process.env.VUE_APP_BASE_URL}/download_documents.php?docid=${hashId}`;
      window.open(urlAddress);
    },
    async getUsersList() {
      this.isLoadingUsers = true;
      const formData = {
        sort: "name|asc",
        companyId: this.companyId,
        userId: this.userId,
        is_active: 1
      };
      const result = HTTP(
        "post",
        "platform/users/list/all",
        formData,
        makeAuthorizationHeader()
      );
      return result.then((response) => {
        this.optionsUsers = response.data.data;
        this.isLoadingUsers = false;
      });
    },
    checkKeyLatestDdqSentFrom(text) {
      return text.includes("From");
    },
    checkKeyLatestDdqSentTo(text) {
      return text.includes("To");
    },
    checkKeyLatestCompletedMsqResult(text) {
      return text.includes("latest_completed_ddq_status");
    },
    checkKeyLatestDdqStatus(text) {
      return text.includes("latest_ddq_status");
    },
    addFilterRow(filterIndex) {
      if (this.filterItems[filterIndex].filterType == null) {
        this.$bvToast.toast("Please select filter type and value", {
          variant: "warning",
          autoHideDelay: 2000,
          appendToast: false,
          noCloseButton: true
        });
      } else {
        this.filterItems.push({
          filterType: null,
          filterTypeValue: null,
          selectedFilterOptions: []
        });
      }
    },
    removeFilterRow(index, filters) {
      if (filters.filterType) {
        if (filters.filterType.value == "entityType") {
          this.filterAdvance.entityType = [];
        } else if (filters.filterType.value == "countries") {
          this.filterAdvance.country = [];
        } else if (filters.filterType.value == "idd") {
          this.filterAdvance.iddResults = [];
        } else if (filters.filterType.value == "idd+") {
          this.filterAdvance.iddPlusAlert = [];
        } else if (filters.filterType.value == "edd") {
          this.filterAdvance.eddAlert = [];
        } else if (filters.filterType.value == "pri") {
          this.filterAdvance.pri = [];
        } else if (filters.filterType.value == "riskRating") {
          this.filterAdvance.riskRating = [];
        } else if (filters.filterType.value == "tCertStatus") {
          this.filterAdvance.tCertStatus = [];
        } else if (filters.filterType.value == "tCertPaymentOptions") {
          this.filterAdvance.tCertPaymentOptions = [];
        } else if (filters.filterType.value === "is_ubo") {
          this.filterAdvance.isUBO = [];
        } else if (filters.filterType.value === "ubo_idd_risk") {
          this.filterAdvance.uboIDD = [];
        } else if (filters.filterType.value === "ubo_idd_plus_risk") {
          this.filterAdvance.uboIDDPlus = [];
        } else if (filters.filterType.value == "isArchive") {
          this.filterAdvance.isArchived = 0;
        } else if (filters.filterType.value == "createdBy") {
          this.filterAdvance.createdBy = [];
        } else if (filters.filterType.value == "uploadedDateFrom") {
          this.advanceFilter.uploadedDateFrom = null;
        } else if (filters.filterType.value == "uploadedDateTo") {
          this.advanceFilter.uploadedDateTo = null;
        } else if (filters.filterType.value == "tCertCreatedDateTo") {
          this.advanceFilter.tCertCreatedDateTo = null;
        } else if (filters.filterType.value == "tCertCreatedDateFrom") {
          this.advanceFilter.tCertCreatedDateFrom = null;
        } else if (filters.filterType.value == "tCertUploadedDateTo") {
          this.advanceFilter.tCertUploadedDateTo = null;
        } else if (filters.filterType.value == "tCertUploadedDateFrom") {
          this.advanceFilter.tCertUploadedDateFrom = null;
        } else if (
          filters.filterType.text.includes("From") &&
          filters.filterType.value.includes("latest_ddq_sent")
        ) {
          let key = filters.filterType.value.split("_")[3];
          if (key != undefined) {
            this.advanceFilter.lastMSQSentUploadedDateFrom[key] = "";
          }
        } else if (
          filters.filterType.text.includes("To") &&
          filters.filterType.value.includes("latest_ddq_sent")
        ) {
          let key = filters.filterType.value.split("_")[3];
          if (key != undefined) {
            this.advanceFilter.lastMSQSentUploadedDateTo[key] = "";
          }
        } else if (
          filters.filterType.value.includes("latest_completed_ddq_status")
        ) {
          let key = filters.filterType.value.split("_")[4];
          if (key != undefined) {
            this.advanceFilter.latestCompletedMSQResult[key] = [];
          }
        } else if (filters.filterType.value.includes("latest_ddq_status")) {
          let key = filters.filterType.value.split("_")[3];
          if (key != undefined) {
            this.advanceFilter.latestMSQTaskStatus[key] = [];
          }
        } else if (
          this.customAttributesList.includes(filters?.filterType?.value)
        ) {
          delete this.customAttributesFilterValue[
            `customatt_${filters.filterType.id}`
          ];
        } else if (
          filters.filterType.value === attributeType.DATE &&
          filters.filterType.text.includes("From")
        ) {
          let id =
            filters.filterType.id.split("")[1] +
            filters.filterType.id.split("")[2] +
            filters.filterType.id.split("")[3];
          if (
            this.customAttributesFilterValue[`customatt_${id}`] != undefined &&
            this.customAttributesFilterValue[`customatt_${id}`].endDate == ""
          ) {
            delete this.customAttributesFilterValue[`customatt_${id}`];
          } else if (
            this.customAttributesFilterValue[`customatt_${id}`] != undefined
          ) {
            this.customAttributesFilterValue[`customatt_${id}`].startDate = "";
          }
        } else if (
          filters.filterType.value === attributeType.DATE &&
          filters.filterType.text.includes("To")
        ) {
          let id =
            filters.filterType.id.split("")[1] +
            filters.filterType.id.split("")[2] +
            filters.filterType.id.split("")[3];
          if (
            this.customAttributesFilterValue[`customatt_${id}`] != undefined &&
            this.customAttributesFilterValue[`customatt_${id}`].startDate == ""
          ) {
            delete this.customAttributesFilterValue[`customatt_${id}`];
          } else if (
            this.customAttributesFilterValue[`customatt_${id}`] != undefined
          ) {
            this.customAttributesFilterValue[`customatt_${id}`].endDate = "";
          }
        }

        this.nonFilterOptions.map((data, nonFilterIndex) => {
          if (
            data.text == filters.filterType.text &&
            data.id == filters.filterType.id
          ) {
            this.filterOptions.push(filters.filterType);
            this.nonFilterOptions.splice(nonFilterIndex, 1);
          }
        });
        this.filterOptions.sort(this.dynamicSort("text"));
      }
      this.filterItems.splice(index, 1);
    },
    removeFirstFilterValueRow(index, filters) {
      if (this.filterItems.length > 1) {
        this.removeFilterRow(index, filters);
      } else {
        if (filters.filterType) {
          if (filters.filterType.value == "entityType") {
            this.filterAdvance.entityType = [];
          } else if (filters.filterType.value == "countries") {
            this.filterAdvance.country = [];
          } else if (filters.filterType.value == "idd") {
            this.filterAdvance.iddResults = [];
          } else if (filters.filterType.value == "idd+") {
            this.filterAdvance.iddPlusAlert = [];
          } else if (filters.filterType.value == "edd") {
            this.filterAdvance.eddAlert = [];
          } else if (filters.filterType.value == "pri") {
            this.filterAdvance.pri = [];
          } else if (filters.filterType.value == "riskRating") {
            this.filterAdvance.riskRating = [];
          } else if (filters.filterType.value == "tCertStatus") {
            this.filterAdvance.tCertStatus = [];
          } else if (filters.filterType.value == "tCertPaymentOptions") {
            this.filterAdvance.tCertPaymentOptions = [];
          } else if (filters.filterType.value === "is_ubo") {
            this.filterAdvance.isUBO = [];
          } else if (filters.filterType.value === "ubo_idd_risk") {
            this.filterAdvance.uboIDD = [];
          } else if (filters.filterType.value === "ubo_idd_plus_risk") {
            this.filterAdvance.uboIDDPlus = [];
          } else if (filters.filterType.value == "isArchive") {
            this.filterAdvance.isArchived = 0;
          } else if (filters.filterType.value == "createdBy") {
            this.filterAdvance.createdBy = [];
          } else if (filters.filterType.value == "uploadedDateFrom") {
            this.advanceFilter.uploadedDateFrom = null;
          } else if (filters.filterType.value == "uploadedDateTo") {
            this.advanceFilter.uploadedDateTo = null;
          } else if (filters.filterType.value == "tCertCreatedDateTo") {
            this.advanceFilter.tCertCreatedDateTo = null;
          } else if (filters.filterType.value == "tCertCreatedDateFrom") {
            this.advanceFilter.tCertCreatedDateFrom = null;
          } else if (filters.filterType.value == "tCertUploadedDateTo") {
            this.advanceFilter.tCertUploadedDateTo = null;
          } else if (filters.filterType.value == "tCertUploadedDateFrom") {
            this.advanceFilter.tCertUploadedDateFrom = null;
          } else if (
            filters.filterType.text.includes("From") &&
            filters.filterType.value.includes("latest_ddq_sent")
          ) {
            let key = filters.filterType.value.split("_")[3];
            if (key != undefined) {
              this.advanceFilter.lastMSQSentUploadedDateFrom[key] = "";
            }
          } else if (
            filters.filterType.text.includes("To") &&
            filters.filterType.value.includes("latest_ddq_sent")
          ) {
            let key = filters.filterType.value.split("_")[3];
            if (key != undefined) {
              this.advanceFilter.lastMSQSentUploadedDateTo[key] = "";
            }
          } else if (
            filters.filterType.value.includes("latest_completed_ddq_status")
          ) {
            let key = filters.filterType.value.split("_")[4];
            if (key != undefined) {
              this.advanceFilter.latestCompletedMSQResult[key] = [];
            }
          } else if (filters.filterType.value.includes("latest_ddq_status")) {
            let key = filters.filterType.value.split("_")[3];
            if (key != undefined) {
              this.advanceFilter.latestMSQTaskStatus[key] = [];
            }
          } else if (
            this.customAttributesList.includes(filters?.filterType?.value)
          ) {
            delete this.customAttributesFilterValue[
              `customatt_${filters.filterType.id}`
            ];
          } else if (
            filters.filterType.value === attributeType.DATE &&
            filters.filterType.text.includes("From")
          ) {
            let id =
              filters.filterType.id.split("")[1] +
              filters.filterType.id.split("")[2] +
              filters.filterType.id.split("")[3];
            if (
              this.customAttributesFilterValue[`customatt_${id}`] !=
                undefined &&
              this.customAttributesFilterValue[`customatt_${id}`].endDate == ""
            ) {
              delete this.customAttributesFilterValue[`customatt_${id}`];
            } else if (
              this.customAttributesFilterValue[`customatt_${id}`] != undefined
            ) {
              this.customAttributesFilterValue[`customatt_${id}`].startDate =
                "";
            }
          } else if (
            filters.filterType.value === attributeType.DATE &&
            filters.filterType.text.includes("To")
          ) {
            let id =
              filters.filterType.id.split("")[1] +
              filters.filterType.id.split("")[2] +
              filters.filterType.id.split("")[3];
            if (
              this.customAttributesFilterValue[`customatt_${id}`] !=
                undefined &&
              this.customAttributesFilterValue[`customatt_${id}`].startDate ==
                ""
            ) {
              delete this.customAttributesFilterValue[`customatt_${id}`];
            } else if (
              this.customAttributesFilterValue[`customatt_${id}`] != undefined
            ) {
              this.customAttributesFilterValue[`customatt_${id}`].endDate = "";
            }
          }
          this.nonFilterOptions.map((data, nonFilterIndex) => {
            if (
              data.text == filters.filterType.text &&
              data.id == filters.filterType.id
            ) {
              this.filterOptions.push(filters.filterType);
              this.nonFilterOptions.splice(nonFilterIndex, 1);
            }
          });
          this.filterOptions.sort(this.dynamicSort("text"));
        }
        this.filterItems = this.makeFilterItemsObj();
        this.callFilterApi = true;
      }
    },
    getUniqueformQuestionnaires(value, index) {
      if (!value?.length) {
        let formQuestionnairesIndex = this.formQuestionnaires.findIndex(
          (x) => x == index
        );
        let isIndexPresent =
          (this.advanceFilter.latestMSQTaskStatus[index] != undefined &&
            this.advanceFilter.latestMSQTaskStatus[index].length > 0) ||
          (this.advanceFilter.latestCompletedMSQResult[index] != undefined &&
            this.advanceFilter.latestCompletedMSQResult[index].length > 0) ||
          (this.advanceFilter.lastMSQSentUploadedDateFrom[index] != undefined &&
            this.advanceFilter.lastMSQSentUploadedDateFrom[index].length > 0) ||
          (this.advanceFilter.lastMSQSentUploadedDateTo[index] != undefined &&
            this.advanceFilter.lastMSQSentUploadedDateTo[index].length > 0);
        if (formQuestionnairesIndex != -1 && !isIndexPresent) {
          this.formQuestionnaires.splice(formQuestionnairesIndex, 1);
        }
      } else {
        let formQuestionnairesIndex = this.formQuestionnaires.findIndex(
          (x) => x == index
        );
        if (formQuestionnairesIndex == -1) {
          this.formQuestionnaires.push(index);
        }
      }
    },
    gomycell(col) {
      return `cell(${col.key})`;
    },
    isThirdPartyArchived() {
      let archivedTp = this.thirdPartyIdsActionsData.filter(
        (tp) => parseInt(tp.is_archived) == 1
      );
      if (archivedTp.length > 0) {
        this.isArchivedTP = true;
      } else {
        this.isArchivedTP = false;
      }
    },
    async getQuestionnariesData() {
      try {
        const result = await HTTP(
          "get",
          `v1/getactiveformslist/${this.companyId}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.data) {
          this.questionnaires = result.data.questionnaires;
        }
        this.getQuestionnariesReminderData();
      } catch (error) {
        return error;
      }
    },
    async getQuestionnariesReminderData() {
      try {
        const result = await HTTP(
          "get",
          `v1/getactiveformslistforbulk/${this.companyId}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.data) {
          this.questionnairesReminder = result.data.questionnaires;
          this.saveBulkActions();
        }
      } catch (error) {
        return error;
      }
    },
    getFilteredQuestionnaires({ questionnaire_all_actions = [] }) {
      return this.hasTriggerActOnAllPermission
        ? questionnaire_all_actions
        : questionnaire_all_actions.filter(
            ({ actionType }) =>
              actionType.toLowerCase().includes(thirdPartyActionType.ERAF) &&
              !actionType.toLowerCase().includes(thirdPartyActionType.ESG)
          );
    },
    async getAllQuestionnariesData() {
      try {
        const { data = {} } = await HTTP(
          "get",
          `v1/getactiveformslistforallbulk/${this.companyId}`,
          null,
          makeAuthorizationHeader()
        );
        this.allQuestionnaires = this.getFilteredQuestionnaires(data);
      } catch (error) {
        return error;
      }
    },
    async getQuestionnariesColumnsData() {
      try {
        const result = await HTTP(
          "post",
          "thirdparty/dynamic/columns",
          {
            company_id: this.companyId
          },
          makeAuthorizationHeader({ tenantId: this.tenantId })
        );
        if (result.data) {
          this.questionnairesColumns = result.data.dynamic_columns.map(
            (dynamicColumns) => ({
              ...dynamicColumns,
              id: makeUUID()
            })
          );
          this.questionnairesFilterColumns = result.data.filter_dynamic_columns;
          result.data.filter_dynamic_columns.map((filterData) => {
            this.filterOptions.push({
              text: filterData.label,
              value: filterData.key
            });
          });
          this.filterOptions.sort(this.dynamicSort("text"));
        }
      } catch (error) {
        return error;
      }
    },
    async onReload(fetchNewData) {
      this.platformCustomAttributes = [];
      this.showTableData = false;
      this.thirdPartiesTableData = [];
      this.filterData = [];
      this.setFilterData = [];
      this.filterItems = [];
      this.filterAdvance = {
        entityType: [],
        country: [],
        iddResults: [],
        pri: [],
        eddAlert: [],
        iddPlusAlert: [],
        riskRating: [],
        tCertStatus: [],
        tCertPaymentOptions: [],
        questionnaire: [],
        latestCompletedMSQResult: {},
        latestMSQTaskStatus: {},
        isArchived: 0,
        createdBy: [],
        isUBO: [],
        uboIDD: [],
        uboIDDPlus: []
      };
      this.advanceFilter = {
        entityType: [],
        country: [],
        uploadedDateFrom: null,
        lastMSQSentUploadedDateFrom: {},
        lastMSQSentUploadedDateTo: {},
        uploadedDateTo: null,
        tCertCreatedDateTo: null,
        tCertCreatedDateFrom: null,
        tCertUploadedDateTo: null,
        tCertUploadedDateFrom: null,
        iddResults: [],
        pri: [],
        eddAlert: [],
        iddPlusAlert: [],
        riskRating: [],
        tCertStatus: [],
        tCertPaymentOptions: [],
        isUBO: [],
        uboIDD: [],
        uboIDDPlus: [],
        questionnaire: [],
        latestCompletedMSQResult: {},
        latestMSQTaskStatus: {},
        isArchived: { text: "Not Archived", value: "0" },
        createdBy: []
      };
      this.customAttributes = [];
      this.questionnairesFilterColumns = [];
      this.filterOptions = [
        { text: "Entity Type", value: "entityType" },
        { text: "Country/Territory of Registration", value: "countries" },
        { text: "Uploaded Date (From)", value: "uploadedDateFrom" },
        { text: "Uploaded Date (To)", value: "uploadedDateTo" },
        {
          text: "Tcertification Created Date (To)",
          value: "tCertCreatedDateTo"
        },
        {
          text: "Tcertification Created Date (From)",
          value: "tCertCreatedDateFrom"
        },
        {
          text: "Tcertification Uploaded Date (To)",
          value: "tCertUploadedDateTo"
        },
        {
          text: "Tcertification Uploaded Date (From)",
          value: "tCertUploadedDateFrom"
        },
        { text: "IDD S&E Results", value: "idd" },
        { text: "IDD+ Alert", value: "idd+" },
        { text: "EDD Alert", value: "edd" },
        { text: "Standard PRI ™", value: "pri" },
        { text: "My Risk Rating", value: "riskRating" },
        { text: "Tcertification Status", value: "tCertStatus" },
        {
          text: "Tcertification Payment Options",
          value: "tCertPaymentOptions"
        },
        { text: "Archived/ Not Archived", value: "isArchive" },
        { text: "Created By", value: "createdBy" }
      ];
      this.nonFilterOptions = [];
      this.resetFilter = false;
      this.advanceFilterForms = false;
      this.$store.state.DDQquestionnairesTabLazyLoad = true;
      await this.setFilterClear(fetchNewData);
      await this.getUsersList();
      await this.getQuestionnariesData();
      await this.getAllQuestionnariesData();
      await this.getAllForms();
      this.getFieldsFromLocalStorage();
      await this.getQuestionnariesColumnsData();
      await this.getAdditionalVouchers();
      await this.getDueDiligenceCategories();
    },
    hasFilters(configFilters) {
      if (Array.isArray(configFilters)) {
        return !!configFilters?.length;
      }

      for (const filter of THIRD_PARTY_LIST_FILTERS) {
        if (
          !isEmpty(configFilters?.[filter]) ||
          configFilters?.[filter]?.length
        ) {
          return true;
        }
      }

      return false;
    },
    getUnique(array) {
      return array.reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    },
    saveBulkActions() {
      if (localStorage.getItem("bulkActions") == null) {
        let bulkActions = {
          availableActions: cloneDeep(BulkOrderDefaultActions.availableActions),
          actionsNotAvailable: cloneDeep(
            BulkOrderDefaultActions.actionsNotAvailable
          )
        };
        bulkActions.availableActions.push(...this.questionnaires);
        bulkActions.availableActions.push(...this.questionnairesReminder);
        localStorage.setItem("bulkActions", JSON.stringify(bulkActions));
      }
    },
    checkScoringFieldExist(data, field) {
      return JSON.parse(data.item.negativeMediaKeyTotalByCategory).findIndex(
        (x) => x.title == field
      );
    },
    checkFutureRisksScoringFieldExist(data, field) {
      return JSON.parse(data.item.futureRisksKeyTotalByCategory).findIndex(
        (x) => x.title == field
      );
    },
    getFieldsFromLocalStorage() {
      let dynamicTableArray = [];
      dynamicTableArray = JSON.parse(
        localStorage.getItem("DynamicTableColumn")
      );

      if (dynamicTableArray && dynamicTableArray.length) {
        this.dynamicTableCol = dynamicTableArray;
      }
    },
    async submitSearch() {
      if (this.filter) {
        this.filterInputValue = this.filter;
        this.filterTable = true;
        this.currentPage = 1;
        this.thirdPartiesTableData = [];
        await this.fetchThirdPartyTableWithAdvanceFilter();
      } else {
        await this.setFilterClear();
      }
    },
    onEmpltyFilter(value) {
      if (!value) {
        this.setFilterClear();
      }
    },
    async setFilterClear(fetchNewData) {
      this.filter = "";
      this.filterInputValue = null;
      this.filterTable = false;
      if (fetchNewData) {
        await this.fetchThirdPartyTableWithAdvanceFilter();
      }
    },
    getRiskDataScore(tasks) {
      if (tasks.risk_data) {
        let data = JSON.parse(tasks.risk_data);
        let numberIndex = this.wordNumber.findIndex(
          (x) => x.value == data.rating
        );
        return `${this.wordNumber[numberIndex].text} (${data.rating})`;
      } else {
        return "Completed";
      }
    },
    checkRiskScoreColor(tasks) {
      if (tasks.risk_data) {
        let data = JSON.parse(tasks.risk_data);
        if (data.risk) {
          let risk = data.risk.toLowerCase();
          if (risk == "high") {
            return 1;
          } else if (risk == "low") {
            return 2;
          } else if (risk == "moderate") {
            return 3;
          }
        } else {
          return 4;
        }
      } else {
        return 2;
      }
    },
    async getAllForms() {
      try {
        const result = await HTTP(
          "post",
          "chart/getallforms",
          {
            companyId: this.companyId
          },
          makeAuthorizationHeader()
        );
        if (result.data.data) {
          this.formsOptions = result.data.data;
          this.selectedForm = this.formsOptions[0];
        }
      } catch (error) {
        return [error];
      }
    },
    onSelectDDq(val) {
      this.selectedForm = val;
    },
    openBulkOrder() {
      let selectedThirdParties = this.getUnique(this.thirdPartyIdsActionsData);
      EventBus.emit("onBulkPerformActionsOpen", selectedThirdParties);
    },
    async editThirdPartyModal(thirdPartyData) {
      if (this.hasEditThirdPartiesPermission) {
        if (this.isDNBFeatureEnabled) {
          this.$router.push(
            urls.THIRD_PARTY_UPDATE({
              id: thirdPartyData?.id,
              companyId: thirdPartyData?.comp_id
            })
          );
        } else {
          this.setThirdPartySelectedId(thirdPartyData?.id);
          this.setIsThirdPartyNewModalVisible(true);
        }
      }
    },
    showAddModalNew() {
      this.setIsThirdPartyAddModalVisible(true);
    },
    openAllDrawer() {
      EventBus.emit("openAllSideBar", true);
    },
    openOnboardingDrawer() {
      EventBus.emit("openOnboardingSideBar", true);
    },
    async reloadTable(withFilters) {
      this.currentPage = 1;
      this.thirdPartiesTableData = [];
      this.thirdPartyIdsActionsData = [];
      this.multipleThirdParties = [];
      this.archiveThirdParties = [];
      this.showArchiveThirdPartiesModal = false;
      if (withFilters) {
        await this.fetchThirdPartyTableWithAdvanceFilter();
      } else {
        await this.fetchThirdPartyData();
      }
    },
    async getUserDetail() {
      this.isLoadingThirdParties = true;
      const result = HTTP(
        "post",
        "eb/user/detail",
        {
          memberId: this.userId
        },
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          this.userDetail = response.data.user;
          let filteredArray = [];
          if (
            this.userDetail.configuration &&
            this.userDetail.configuration != null
          ) {
            filteredArray =
              this.userDetail.configuration != null
                ? JSON.parse(this.userDetail.configuration)
                : this.visibleFields;
            this.setRiskCategoriesColumns.forEach((fields) => {
              let fieldIndex = filteredArray.findIndex(
                (x) => x.key == fields.key
              );
              if (fieldIndex != -1) {
                filteredArray.splice(fieldIndex, 1);
              }
            });
            if (filteredArray && filteredArray.length) {
              let filteredArrayCompNameEngIndex = filteredArray.findIndex(
                (x) => x.key == "comp_name_eng"
              );
              if (filteredArrayCompNameEngIndex != -1) {
                if (
                  filteredArray[filteredArrayCompNameEngIndex].displayLabel ==
                  undefined
                ) {
                  let visibleFieldsCompNameEngIndex =
                    this.visibleFields.findIndex(
                      (x) => x.key == "comp_name_eng"
                    );
                  if (visibleFieldsCompNameEngIndex != -1) {
                    filteredArray[filteredArrayCompNameEngIndex] =
                      this.visibleFields[visibleFieldsCompNameEngIndex];
                  } else {
                    visibleFieldsCompNameEngIndex =
                      this.configureTableAvailableCols.findIndex(
                        (x) => x.key == "comp_name_eng"
                      );
                    if (visibleFieldsCompNameEngIndex != -1) {
                      filteredArray[filteredArrayCompNameEngIndex] =
                        this.configureTableAvailableCols[
                          visibleFieldsCompNameEngIndex
                        ];
                    }
                  }
                }
              }
              let filteredArrayActionsIndex = filteredArray.findIndex(
                (x) => x.key == "actions"
              );
              if (filteredArrayActionsIndex != -1) {
                filteredArray[filteredArrayActionsIndex].isActive = true;
                filteredArray[filteredArrayActionsIndex].label = "Actions";
              }
              this.configureTableAvailableCols.forEach((fields) => {
                let fieldsIndex = filteredArray.findIndex(
                  (x) => x.key == fields.key
                );
                if (fieldsIndex != -1) {
                  if (filteredArray[fieldsIndex].displayLabel == undefined) {
                    filteredArray[fieldsIndex] = fields;
                  }
                }
              });
              this.resetCompanyColumns(filteredArray);
            }
          } else {
            this.resetConfigureColumnsModal();
            this.setUserConfigurationApiCall();
          }
        })
        .catch((error) => [error])
        .finally(() => {
          this.isLoadingThirdParties = false;
        });
    },
    resetCompanyColumns(filteredArray) {
      let customFields = [];
      filteredArray.forEach((field) => {
        let filterFieldIndex = this.configureTableAvailableCols.findIndex(
          (x) => x.key == field.key
        );
        if (filterFieldIndex != -1) {
          customFields.push(field);
        }
      });
      customFields.forEach((column) => {
        let staticFieldIndex = staticFieldsJson.fields.findIndex(
          (x) => x.key == column.key
        );
        if (staticFieldIndex != -1) {
          let staticColumn = staticFieldsJson.fields[staticFieldIndex];

          Object.keys(staticColumn).map((staticKey) => {
            if (column[staticKey] == null) {
              column[staticKey] = staticColumn[staticKey];
            }
            if (typeof staticColumn[staticKey] == "object") {
              if (staticColumn[staticKey] != null) {
                Object.keys(staticColumn[staticKey]).map((subStaticKey) => {
                  if (column[staticKey][subStaticKey] == null) {
                    column[staticKey][subStaticKey] =
                      staticColumn[staticKey][subStaticKey];
                  }
                });
              }
            }
          });
        }
      });
      this.visibleFields = this.makeVisibleFields(customFields);
      this.resetConfigureColumnsModal();
      this.setUserConfigurationApiCall();
    },
    makeVisibleFields(fields) {
      return fields
        .filter((btn) => btn.isActive)
        .map((customField) => {
          const customAttribute = this.platformCustomAttributes.find(
            ({ tp_customatt }) => tp_customatt === customField.displayLabel
          );
          return {
            ...customField,
            customAttributeId: customAttribute?.id,
            customAttributeType: customAttribute?.tp_type
          };
        });
    },
    async getDueDiligenceCategories() {
      this.isLoadingThirdParties = true;
      this.setRiskCategoriesColumns = [];
      try {
        const result = await HTTP(
          "get",
          "v1/get/due/diligence/categories",
          null,
          makeAuthorizationHeader()
        );
        if (
          result &&
          result.status == 200 &&
          result.data.DueDiligenceCategories.length > 0
        ) {
          let dueDiligenceCategories = result.data.DueDiligenceCategories;
          dueDiligenceCategories.map((fields) => {
            let keyValue = fields.text.toLowerCase();
            this.setRiskCategoriesColumns.push({
              key: keyValue.replace(/\s/g, "_"),
              text: fields.abbreviation ? fields.abbreviation : fields.text,
              value: false
            });
          });
        }
        this.getCustomAttributesFields();
      } catch (error) {
        return error;
      } finally {
        this.isLoadingThirdParties = false;
      }
    },
    makeCustomAttributesFieldsPostObj() {
      return {
        sort: "tp_sequence|asc",
        comp_id: this.companyId,
        is_active: 1
      };
    },
    async getCustomAttributesFields() {
      this.isLoadingThirdParties = true;
      this.customAttributes = [];
      try {
        const { response = [] } = await this.fetchCustomAttributesList(
          this.makeCustomAttributesFieldsPostObj()
        );
        this.platformCustomAttributes = response;
        response.forEach((fields) => {
          let keyValue = fields.tp_customatt.toLowerCase();
          let staticFieldsLength = this.configureTableAvailableCols.length;
          let lastIndex =
            this.configureTableAvailableCols[staticFieldsLength - 1].index || 0;
          let columnIndex = this.configureTableAvailableCols.findIndex(
            (x) => x.label == fields.tp_customatt
          );
          if (columnIndex == -1 && parseInt(fields.tp_displayintable) == 1) {
            this.configureTableAvailableCols.push({
              index: lastIndex + 1,
              isActive: true,
              key: keyValue.replace(/\s/g, "_"),
              label: fields.tp_customatt,
              displayLabel: fields.tp_customatt,
              sortable: false,
              width: "100",
              visible: fields.is_visible === 1,
              stickyColumn: false,
              displayName: "",
              variant: "",
              thClass: "",
              tdClass: `cell-backgroundColor-${keyValue.replace(/\s/g, "_")}`,
              fontSize: "15",
              fontColor: "#000000",
              description: null,
              headerFontSize: "15",
              headerFontColor: "#000000",
              thStyle: {
                backgroundColor: "#ffffff",
                textAlignment: "text-center"
              },
              tdStyle: {
                backgroundColor: "#ffffff",
                textAlignment: "text-center"
              },
              default: false
            });
          }
          if (parseInt(fields.tp_is_filter) == 1) {
            if (fields.tp_type === attributeType.DATE) {
              let dateLabelFrom = `${fields.tp_customatt} (From)`;
              let dateLabelTo = `${fields.tp_customatt} (To)`;

              let dateLabelFieldsFrom = cloneDeep(fields);
              dateLabelFieldsFrom.tp_customatt = dateLabelFrom;

              let dateLabelFieldsTo = cloneDeep(fields);
              dateLabelFieldsTo.tp_customatt = dateLabelTo;

              this.customAttributes.push(dateLabelFieldsFrom);
              this.customAttributes.push(dateLabelFieldsTo);
            } else {
              this.customAttributes.push(fields);
            }
          }
        });
        this.customAttributes = this.customAttributes.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (current) => current.tp_customatt === value.tp_customatt
            )
        );
        this.customAttributes.forEach((attribute) => {
          if (attribute.tp_type === attributeType.DATE) {
            let fromId = `F${attribute.id}`;
            let toId = `T${attribute.id}`;
            if (attribute.tp_customatt.includes("From")) {
              this.filterOptions.push({
                text: attribute.tp_customatt,
                value: attribute.tp_type,
                id: fromId
              });
            } else if (attribute.tp_customatt.includes("To")) {
              this.filterOptions.push({
                text: attribute.tp_customatt,
                value: attribute.tp_type,
                id: toId
              });
            }
          } else {
            this.filterOptions.push({
              text: attribute.tp_customatt,
              value: attribute.tp_type,
              id: attribute.id
            });
          }
        });
        this.filterOptions.sort(this.dynamicSort("text"));
        this.getUserDetail();
      } catch (error) {
        return error;
      } finally {
        this.isLoadingThirdParties = false;
      }
    },
    thirdPartySortData(event) {
      if (event.sortBy != "" || event.sortBy != null) {
        this.showTableData = false;
        this.currentPage = 1;
        this.thirdPartiesTableData = [];
        this.sortBy = event.sortBy;
        this.sortDesc = event.sortDesc;
        setTimeout(() => {
          this.showTableData = true;
        }, 10);
      }
    },
    dataFormatter(items) {
      items.map((rowData) => {
        Object.assign(rowData, { latest_ddq_sent: null });
        Object.assign(rowData, { latest_ddq_status: null });
        Object.assign(rowData, { latest_completed_ddq_status: null });
        if (rowData.onboardings && rowData.onboardings[0]) {
          if (
            rowData.onboardings[0].onboarding_tasks != null &&
            rowData.onboardings[0].onboarding_tasks.length != 0
          ) {
            for (
              let i = 0;
              i < rowData.onboardings[0].onboarding_tasks.length;
              i++
            ) {
              let onboardingsData = rowData.onboardings[0].onboarding_tasks[i];
              if (i == 0) {
                if (onboardingsData.created_at) {
                  rowData.latest_ddq_sent = moment
                    .utc(onboardingsData.created_at)
                    .local()
                    .locale("en")
                    .fromNow();
                }
                if (onboardingsData.task_status) {
                  rowData.latest_ddq_status = onboardingsData;
                }
              }
              if (
                onboardingsData.task_status != null &&
                onboardingsData.task_status == 4
              ) {
                rowData.latest_completed_ddq_status = onboardingsData;
                break;
              }
            }
          }
        }
      });
      return items;
    },
    changeFilterType(type) {
      if (type && type.value) {
        this.nonFilterOptions.map((data, nonFilterIndex) => {
          if (data.text == type.text && data.id == type.id) {
            this.filterOptions.push(type);
            this.nonFilterOptions.splice(nonFilterIndex, 1);
          }
        });
        this.filterOptions.sort(this.dynamicSort("text"));
      }
    },
    removeFilterType(type, index) {
      this.filterOptions.map((filterOption, filterOptionIndex) => {
        if (
          filterOption &&
          filterOption.value &&
          this.filterItems &&
          this.filterItems[index] &&
          this.filterItems[index].filterType &&
          this.filterItems[index].filterType.value
        ) {
          if (
            filterOption.text == this.filterItems[index].filterType.text &&
            filterOption.id == this.filterItems[index].filterType.id
          ) {
            this.nonFilterOptions.push(this.filterOptions[filterOptionIndex]);
            this.filterOptions.splice(filterOptionIndex, 1);
          }
        }
      });
      this.filterOptions.sort(this.dynamicSort("text"));
    },
    setFilterType(type, index) {
      if (type) {
        let entityIndex = this.filterItems.findIndex(
          (val) => val.filterType.value == "entityType"
        );
        if (entityIndex == -1) {
          this.filterAdvance.entityType = [];
        }
        let countryIndex = this.filterItems.findIndex(
          (val) => val.filterType.value == "countries"
        );
        if (countryIndex == -1) {
          this.filterAdvance.country = [];
        }
        let iddIndex = this.filterItems.findIndex(
          (val) => val.filterType.value == "idd"
        );
        if (iddIndex == -1) {
          this.filterAdvance.iddResults = [];
        }
        let iddPlusIndex = this.filterItems.findIndex(
          (val) => val.filterType.value == "idd+"
        );
        if (iddPlusIndex == -1) {
          this.filterAdvance.iddPlusAlert = [];
        }
        let eddIndex = this.filterItems.findIndex(
          (val) => val.filterType.value == "edd"
        );
        if (eddIndex == -1) {
          this.filterAdvance.eddAlert = [];
        }
        let priIndex = this.filterItems.findIndex(
          (val) => val.filterType.value == "pri"
        );
        if (priIndex == -1) {
          this.filterAdvance.pri = [];
        }
        let riskRatingIndex = this.filterItems.findIndex(
          (val) => val.filterType.value == "riskRating"
        );
        if (riskRatingIndex == -1) {
          this.filterAdvance.riskRating = [];
        }

        let tCertStatusIndex = this.filterItems.findIndex(
          (val) => val.filterType.value == "tCertStatus"
        );
        if (tCertStatusIndex == -1) {
          this.filterAdvance.tCertStatus = [];
        }

        let tCertPaymentOptionsIndex = this.filterItems.findIndex(
          (val) => val.filterType.value == "tCertPaymentOptions"
        );
        if (tCertPaymentOptionsIndex == -1) {
          this.filterAdvance.tCertPaymentOptions = [];
        }

        let isUBOIndex = this.filterItems.findIndex(
          (val) => val.filterType.value === "is_ubo"
        );
        if (isUBOIndex === -1) {
          this.filterAdvance.isUBO = [];
        }

        const uboIDDIndex = this.filterItems.findIndex(
          (val) => val.filterType.value === "ubo_idd_risk"
        );
        if (uboIDDIndex === -1) {
          this.filterAdvance.uboIDD = [];
        }

        let uboIDDPlusIndex = this.filterItems.findIndex(
          (val) => val.filterType.value === "ubo_idd_plus_risk"
        );
        if (uboIDDPlusIndex === -1) {
          this.filterAdvance.uboIDDPlus = [];
        }

        let isArchiveIndex = this.filterItems.findIndex(
          (val) => val.filterType.value == "isArchive"
        );
        if (isArchiveIndex == -1) {
          this.filterAdvance.isArchived = "";
        }
        let createdByIndex = this.filterItems.findIndex(
          (val) => val.filterType.value == "createdBy"
        );
        if (createdByIndex == -1) {
          this.filterAdvance.createdBy = "";
        }
        let uploadFromDateIndex = this.filterItems.findIndex(
          (val) => val.filterType.value == "uploadedDateFrom"
        );
        if (uploadFromDateIndex == -1) {
          this.advanceFilter.uploadedDateFrom = null;
        }
        let uploadToDateIndex = this.filterItems.findIndex(
          (val) => val.filterType.value == "uploadedDateTo"
        );
        if (uploadToDateIndex == -1) {
          this.advanceFilter.uploadedDateTo = null;
        }
        const tCertCreatedDateToIndex = this.filterItems.findIndex(
          (val) => val.filterType.value == "tCertCreatedDateTo"
        );
        if (tCertCreatedDateToIndex == -1) {
          this.advanceFilter.tCertCreatedDateTo = null;
        }
        const tCertCreatedDateFromIndex = this.filterItems.findIndex(
          (val) => val.filterType.value == "tCertCreatedDateFrom"
        );
        if (tCertCreatedDateFromIndex == -1) {
          this.advanceFilter.tCertCreatedDateFrom = null;
        }
        const tCertUploadedDateToIndex = this.filterItems.findIndex(
          (val) => val.filterType.value == "tCertUploadedDateTo"
        );
        if (tCertUploadedDateToIndex == -1) {
          this.advanceFilter.tCertUploadedDateTo = null;
        }
        const tCertUploadedDateFromIndex = this.filterItems.findIndex(
          (val) => val.filterType.value == "tCertUploadedDateFrom"
        );
        if (tCertUploadedDateFromIndex == -1) {
          this.advanceFilter.tCertUploadedDateFrom = null;
        }
        let msqFromDateIndex = this.filterItems.findIndex(
          (val) =>
            val.filterType.text.includes("From") &&
            val.filterType.value.includes("latest_ddq_sent")
        );
        if (msqFromDateIndex == -1) {
          for (const [key] of Object.entries(
            this.advanceFilter.lastMSQSentUploadedDateFrom
          )) {
            this.advanceFilter.lastMSQSentUploadedDateFrom[`${key}`] = "";
          }
        } else {
          let msqFromDateValueIndex = this.filterItems.findIndex(
            (val) =>
              val.filterType.text.includes("From") &&
              val.filterType.value.includes("latest_ddq_sent")
          );
          let keyIndex = null;
          if (msqFromDateValueIndex != -1) {
            keyIndex =
              this.filterItems[msqFromDateValueIndex].filterType.value.split(
                "_"
              )[3];
          }
          for (const [key] of Object.entries(
            this.advanceFilter.lastMSQSentUploadedDateFrom
          )) {
            if (`${key}` != keyIndex) {
              this.advanceFilter.lastMSQSentUploadedDateFrom[`${key}`] = "";
            }
          }
        }
        let msqToDateIndex = this.filterItems.findIndex(
          (val) =>
            val.filterType.text.includes("To") &&
            val.filterType.value.includes("latest_ddq_sent")
        );
        if (msqToDateIndex == -1) {
          for (const [key] of Object.entries(
            this.advanceFilter.lastMSQSentUploadedDateTo
          )) {
            this.advanceFilter.lastMSQSentUploadedDateTo[`${key}`] = "";
          }
        } else {
          let msqToDateValueIndex = this.filterItems.findIndex(
            (val) =>
              val.filterType.text.includes("To") &&
              val.filterType.value.includes("latest_ddq_sent")
          );
          let keyIndex = null;
          if (msqToDateValueIndex != -1) {
            keyIndex =
              this.filterItems[msqToDateValueIndex].filterType.value.split(
                "_"
              )[3];
          }
          for (const [key] of Object.entries(
            this.advanceFilter.lastMSQSentUploadedDateTo
          )) {
            if (`${key}` != keyIndex) {
              this.advanceFilter.lastMSQSentUploadedDateTo[`${key}`] = "";
            }
          }
        }
        let msqResultIndex = this.filterItems.findIndex((val) =>
          val.filterType.value.includes("latest_completed_ddq_status")
        );
        if (msqResultIndex == -1) {
          for (const [key] of Object.entries(
            this.advanceFilter.latestCompletedMSQResult
          )) {
            this.advanceFilter.latestCompletedMSQResult[`${key}`] = [];
          }
        } else {
          let msqResultValueIndex = this.filterItems.findIndex((val) =>
            val.filterType.value.includes("latest_completed_ddq_status")
          );
          let keyIndex = null;
          if (msqResultValueIndex != -1) {
            keyIndex =
              this.filterItems[msqResultValueIndex].filterType.value.split(
                "_"
              )[4];
          }
          for (const [key] of Object.entries(
            this.advanceFilter.latestCompletedMSQResult
          )) {
            if (`${key}` != keyIndex) {
              this.advanceFilter.latestCompletedMSQResult[`${key}`] = [];
            }
          }
        }
        let msqStatusIndex = this.filterItems.findIndex((val) =>
          val.filterType.value.includes("latest_ddq_status")
        );

        if (msqStatusIndex == -1) {
          for (const [key] of Object.entries(
            this.advanceFilter.latestMSQTaskStatus
          )) {
            this.advanceFilter.latestMSQTaskStatus[`${key}`] = [];
          }
        }

        let customAttributeIndex;
        let customAttributeId;
        let customAttributeIdIndex;
        for (const property in this.customAttributesFilterValue) {
          customAttributeIndex = this.filterItems.findIndex(
            (val) =>
              parseInt(val.filterType.id) === parseInt(property.split("_")[1])
          );
          customAttributeIdIndex = this.filterOptions.findIndex(
            (val) => parseInt(val.id) === parseInt(property.split("_")[1])
          );
          if (customAttributeIdIndex !== -1) {
            customAttributeId = this.filterOptions[customAttributeIdIndex].id;
          }
        }
        if (customAttributeIndex === -1) {
          delete this.customAttributesFilterValue[
            `customatt_${customAttributeId}`
          ];
        }
        let customAttributeDateIndex;
        let customAttributeDateId;
        let customAttributeDateIdIndex;
        for (const property in this.customAttributesFilterValue) {
          customAttributeDateIndex = this.filterItems.findIndex(
            (val) =>
              val.filterType.value === attributeType.DATE &&
              val.filterType.id.split("")[1] +
                val.filterType.id.split("")[2] ===
                property.split("_")[1]
          );
          customAttributeDateIdIndex = this.filterOptions.findIndex((val) =>
            typeof val.id === "string"
              ? parseInt(val?.id.split("")[1] + val?.id.split("")[2]) ===
                parseInt(property.split("_")[1])
              : ""
          );
          if (customAttributeDateIdIndex !== -1) {
            customAttributeDateId =
              this.filterOptions[customAttributeDateIdIndex].id.split("")[1] +
              this.filterOptions[customAttributeDateIdIndex].id.split("")[2];
          }
        }
        if (customAttributeDateIndex === -1) {
          delete this.customAttributesFilterValue[
            `customatt_${customAttributeDateId}`
          ];
        }

        this.filterItems[index].filterTypeValue = null;
        if (type.value == "entityType") {
          this.filterItems[index].selectedFilterOptions =
            this.optionsFilterEntityType.sort(this.dynamicSort("text"));
        } else if (type.value == "countries") {
          this.filterItems[index].selectedFilterOptions = this.countries.sort(
            this.dynamicSort("country")
          );
        } else if (type.value == "idd") {
          this.filterItems[index].selectedFilterOptions =
            this.optionsFilterIddResults.sort(this.dynamicSort("text"));
        } else if (type.value == "idd+") {
          this.filterItems[index].selectedFilterOptions =
            this.optionsFilterIddPlusAlert.sort(this.dynamicSort("text"));
        } else if (type.value == "edd") {
          this.filterItems[index].selectedFilterOptions =
            this.optionsFilterEddAlert.sort(this.dynamicSort("text"));
        } else if (type.value == "pri") {
          this.filterItems[index].selectedFilterOptions =
            this.optionsFilterPri.sort(this.dynamicSort("text"));
        } else if (type.value === "is_ubo") {
          this.filterItems[index].selectedFilterOptions =
            this.optionsIsScreenedUBO.sort(this.dynamicSort("text"));
        } else if (
          type.value === "ubo_idd_risk" ||
          type.value === "ubo_idd_plus_risk"
        ) {
          this.filterItems[index].selectedFilterOptions =
            this.optionsUBOIDD.sort(this.dynamicSort("text"));
        } else if (type.value == "riskRating") {
          this.filterItems[index].selectedFilterOptions =
            this.optionsFilterRiskRating.sort(this.dynamicSort("text"));
        } else if (type.value == "tCertStatus") {
          this.filterItems[index].selectedFilterOptions =
            this.optionsTCertStatus.sort(this.dynamicSort("text"));
        } else if (type.value == "tCertPaymentOptions") {
          this.filterItems[index].selectedFilterOptions =
            this.optionsTCertPaymentOptions.sort(this.dynamicSort("text"));
        } else if (type.value == "isArchive") {
          this.filterItems[index].selectedFilterOptions =
            this.optionsFilterIsArchived.sort(this.dynamicSort("text"));
        } else if (type.value == "createdBy") {
          this.filterItems[index].selectedFilterOptions = this.optionsUsers;
        } else {
          this.filterItems[index].selectedFilterOptions = [];
        }
      }
    },
    setFilterTypeValue(value, index) {
      if (this.filterItems[index].filterType) {
        if (this.filterItems[index].filterType.value == "entityType") {
          this.filterAdvance.entityType = [];
          value.map((filterEntityType) => {
            this.filterAdvance.entityType.push(filterEntityType.value);
          });
        } else if (this.filterItems[index].filterType.value == "countries") {
          this.filterAdvance.country = [];
          value.map((filterCountryValue) => {
            this.filterAdvance.country.push(filterCountryValue.country);
          });
        } else if (this.filterItems[index].filterType.value == "idd") {
          this.filterAdvance.iddResults = [];
          value.map((filterIddResults) => {
            this.filterAdvance.iddResults.push(filterIddResults.value);
          });
        } else if (this.filterItems[index].filterType.value == "idd+") {
          this.filterAdvance.iddPlusAlert = [];
          value.map((filterIddPlusAlert) => {
            this.filterAdvance.iddPlusAlert.push(filterIddPlusAlert.value);
          });
        } else if (this.filterItems[index].filterType.value == "edd") {
          this.filterAdvance.eddAlert = [];
          value.map((filterEddAlert) => {
            this.filterAdvance.eddAlert.push(filterEddAlert.value);
          });
        } else if (this.filterItems[index].filterType.value == "pri") {
          this.filterAdvance.pri = [];
          value.map((filterPri) => {
            this.filterAdvance.pri.push(filterPri.value);
          });
        } else if (this.filterItems[index].filterType.value == "riskRating") {
          this.filterAdvance.riskRating = [];
          value.map((filterRiskRating) => {
            this.filterAdvance.riskRating.push(filterRiskRating.value);
          });
        } else if (this.filterItems[index].filterType.value == "tCertStatus") {
          this.filterAdvance.tCertStatus = [];
          value.map((filterRiskRating) => {
            this.filterAdvance.tCertStatus.push(filterRiskRating.value);
          });
        } else if (
          this.filterItems[index].filterType.value == "tCertPaymentOptions"
        ) {
          this.filterAdvance.tCertPaymentOptions = [];
          value.map((filterRiskRating) => {
            this.filterAdvance.tCertPaymentOptions.push(filterRiskRating.value);
          });
        } else if (this.filterItems[index].filterType.value === "is_ubo") {
          this.filterAdvance.isUBO = [];
          value.forEach((item) => {
            this.filterAdvance.isUBO.push(item.value);
          });
        } else if (
          this.filterItems[index].filterType.value === "ubo_idd_risk"
        ) {
          this.filterAdvance.uboIDD = [];
          value.forEach((item) => {
            this.filterAdvance.uboIDD.push(item.value);
          });
        } else if (
          this.filterItems[index].filterType.value === "ubo_idd_plus_risk"
        ) {
          this.filterAdvance.uboIDDPlus = [];
          value.forEach((item) => {
            this.filterAdvance.uboIDDPlus.push(item.value);
          });
        } else if (this.filterItems[index].filterType.value == "isArchive") {
          this.filterAdvance.isArchived = value ? value.value : "";
        } else if (this.filterItems[index].filterType.value == "createdBy") {
          this.filterAdvance.createdBy = [];
          value.map((filterCreatedBy) => {
            this.filterAdvance.createdBy.push(filterCreatedBy.member_id);
          });
        }
        this.checkFilterValueNew();
      }
    },
    setFilterUploadDateFrom(value) {
      this.advanceFilter.uploadedDateFrom = value;
      this.checkFilterValue();
    },
    setFilterUploadDateTo(value) {
      this.advanceFilter.uploadedDateTo = value;
      this.checkFilterValue();
    },
    setTCertCreatedDateTo(value) {
      this.advanceFilter.tCertCreatedDateTo = value;
      this.checkFilterValue();
    },
    setTCertCreatedDateFrom(value) {
      this.advanceFilter.tCertCreatedDateFrom = value;
      this.checkFilterValue();
    },
    setTCertUploadedDateTo(value) {
      this.advanceFilter.tCertUploadedDateTo = value;
      this.checkFilterValue();
    },
    setTCertUploadedDateFrom(value) {
      this.advanceFilter.tCertUploadedDateFrom = value;
      this.checkFilterValue();
    },
    checkFilterValue() {
      if (
        this.advanceFilter.country.length != 0 ||
        this.advanceFilter.eddAlert.length != 0 ||
        this.advanceFilter.entityType.length != 0 ||
        this.advanceFilter.iddPlusAlert.length != 0 ||
        this.advanceFilter.iddResults.length != 0 ||
        this.advanceFilter.pri.length != 0 ||
        this.advanceFilter.questionnaire.length != 0 ||
        this.advanceFilter.riskRating.length != 0 ||
        this.advanceFilter.tCertStatus.length ||
        this.advanceFilter.tCertPaymentOptions.length ||
        this.advanceFilter.isUBO.length !== 0 ||
        this.advanceFilter.uboIDD.length !== 0 ||
        this.advanceFilter.uboIDDPlus.length !== 0 ||
        this.advanceFilter.uploadedDateFrom != null ||
        this.advanceFilter.uploadedDateTo != null ||
        this.advanceFilter.tCertCreatedDateTo ||
        this.advanceFilter.tCertCreatedDateFrom ||
        this.advanceFilter.tCertUploadedDateTo ||
        this.advanceFilter.tCertUploadedDateFrom ||
        this.advanceFilter.isArchived != null ||
        this.isMsqFilterActive
      ) {
        this.callFilterApi = false;
        this.isFilterActive = true;
      } else {
        this.callFilterApi = true;
        this.isFilterActive = false;
      }
    },
    checkFilterValueNew() {
      if (
        this.filterAdvance.country.length != 0 ||
        this.filterAdvance.eddAlert.length != 0 ||
        this.filterAdvance.entityType.length != 0 ||
        this.filterAdvance.iddPlusAlert.length != 0 ||
        this.filterAdvance.iddResults.length != 0 ||
        this.filterAdvance.pri.length != 0 ||
        this.filterAdvance.riskRating.length != 0 ||
        this.filterAdvance.tCertStatus.length ||
        this.filterAdvance.tCertPaymentOptions.length ||
        this.filterAdvance.isUBO.length !== 0 ||
        this.filterAdvance.uboIDD.length !== 0 ||
        this.filterAdvance.uboIDDPlus.length !== 0 ||
        this.filterAdvance.uploadedDateFrom != null ||
        this.filterAdvance.uploadedDateTo != null ||
        this.filterAdvance.tCertCreatedDateTo ||
        this.filterAdvance.tCertCreatedDateFrom ||
        this.filterAdvance.tCertUploadedDateTo ||
        this.filterAdvance.tCertUploadedDateFrom ||
        this.filterAdvance.isArchived != "" ||
        this.isMsqFilterActive ||
        this.filterAdvance.createdBy.length != 0
      ) {
        this.callFilterApi = false;
        this.isFilterActive = true;
      } else {
        this.callFilterApi = true;
        this.isFilterActive = false;
      }
    },
    checkMsqFilterValue() {
      if (this.advanceFilter.latestMSQTaskStatus != null) {
        for (const [value] of Object.entries(
          this.advanceFilter.latestMSQTaskStatus
        )) {
          if (value.length == 0 && !this.isFilterActive) {
            this.callFilterApi = true;
            this.isMsqFilterActive = false;
          } else {
            this.callFilterApi = false;
            this.isMsqFilterActive = true;
            return;
          }
        }
      }
      if (this.advanceFilter.latestCompletedMSQResult != null) {
        for (const [value] of Object.entries(
          this.advanceFilter.latestCompletedMSQResult
        )) {
          if (value.length == 0 && !this.isFilterActive) {
            this.callFilterApi = true;
            this.isMsqFilterActive = false;
          } else {
            this.callFilterApi = false;
            this.isMsqFilterActive = true;
            return;
          }
        }
      }
      if (this.advanceFilter.lastMSQSentUploadedDateFrom != null) {
        for (const [value] of Object.entries(
          this.advanceFilter.lastMSQSentUploadedDateFrom
        )) {
          if (value.length == 0 && !this.isFilterActive) {
            this.callFilterApi = true;
            this.isMsqFilterActive = false;
          } else {
            this.callFilterApi = false;
            this.isMsqFilterActive = true;
            return;
          }
        }
      }
      if (this.advanceFilter.lastMSQSentUploadedDateTo != null) {
        for (const [value] of Object.entries(
          this.advanceFilter.lastMSQSentUploadedDateTo
        )) {
          if (value.length == 0 && !this.isFilterActive) {
            this.callFilterApi = true;
            this.isMsqFilterActive = false;
          } else {
            this.callFilterApi = false;
            this.isMsqFilterActive = true;
            return;
          }
        }
      }
    },
    async resetAdvanceFilter() {
      EventBus.emit("resetAdvanceFilter");
      this.resetFilter = false;
      this.callFilterApi = true;
      this.filterAdvance = {
        entityType: [],
        country: [],
        iddResults: [],
        pri: [],
        eddAlert: [],
        iddPlusAlert: [],
        riskRating: [],
        tCertStatus: [],
        tCertPaymentOptions: [],
        isUBO: [],
        uboIDD: [],
        uboIDDPlus: [],
        questionnaire: [],
        latestCompletedMSQResult: {},
        latestMSQTaskStatus: {},
        isArchived: 0,
        createdBy: []
      };
      this.advanceFilter = {
        entityType: [],
        country: [],
        uploadedDateFrom: null,
        lastMSQSentUploadedDateFrom: {},
        lastMSQSentUploadedDateTo: {},
        uploadedDateTo: null,
        tCertCreatedDateTo: null,
        tCertCreatedDateFrom: null,
        tCertUploadedDateTo: null,
        tCertUploadedDateFrom: null,
        iddResults: [],
        pri: [],
        eddAlert: [],
        iddPlusAlert: [],
        riskRating: [],
        tCertStatus: [],
        tCertPaymentOptions: [],
        isUBO: [],
        uboIDD: [],
        uboIDDPlus: [],
        questionnaire: [],
        latestCompletedMSQResult: {},
        latestMSQTaskStatus: {},
        isArchived: { text: "Not Archived", value: "0" },
        createdBy: []
      };
      this.advanceFilterForms = false;
      this.filter = "";
      this.filterTable = false;
      this.currentPage = 1;
      this.thirdPartiesTableData = [];
      this.filterEnable = 0;
      this.formQuestionnaires = [];
      this.filterItems = this.makeFilterItemsObj();
      this.filterOptions.push(...this.nonFilterOptions);
      this.filterOptions.sort(this.dynamicSort("text"));
      this.nonFilterOptions = [];
      this.customAttributesFilterValue = {};
      this.setFilterData = [];
      await this.reloadTable();
    },
    async applyThirdPartyFilter() {
      this.showTableData = false;
      this.resetFilter = true;
      this.currentPage = 1;
      this.thirdPartiesTableData = [];
      this.filterData = [];
      this.filterEnable = 1;
      this.multipleThirdParties = [];
      this.selectedMultipleThirdParties = false;
      this.thirdPartyIdsActionsData = [];
      this.archiveThirdParties = [];
      this.thirdParties = [];
      this.disableButtons = true;
      this.advanceFilterForms = false;
      await this.fetchThirdPartyTableWithAdvanceFilter();
      setTimeout(() => {
        this.showTableData = true;
      }, 200);
    },
    async setFormQuestionnaires() {
      this.formQuestionnaires = [];
      const advanceFilterProperties = [
        "lastMSQSentUploadedDateFrom",
        "lastMSQSentUploadedDateTo",
        "latestCompletedMSQResult",
        "latestMSQTaskStatus"
      ];
      advanceFilterProperties.forEach((property) => {
        const formIds = Object.keys(this.advanceFilter[property]);

        formIds.forEach((id) => {
          if (this.advanceFilter[property][id]?.length) {
            if (!this.formQuestionnaires.includes(parseInt(id))) {
              this.formQuestionnaires.push(parseInt(id));
            }
          }
        });
      });
    },
    async makeThirdPartyTableWithAdvanceFilterPostObj() {
      await this.setFormQuestionnaires();
      if (this.filterData.length) {
        return this.filterData;
      } else {
        return {
          filter: this.filter,
          sort: `${this.sortBy}|${this.sortDesc ? "desc" : "asc"}`,
          per_page: this.perPage,
          page: this.currentPage,
          company_id: this.companyId,
          entity_filter: this.filterAdvance.entityType,
          country_filter: this.filterAdvance.country,
          upload_date_from_filter: this.advanceFilter.uploadedDateFrom,
          upload_date_to_filter: this.advanceFilter.uploadedDateTo,
          tcertificate_created_date_to_filter:
            this.advanceFilter.tCertCreatedDateTo,
          tcertificate_created_date_from_filter:
            this.advanceFilter.tCertCreatedDateFrom,
          tcertificate_upload_date_to_filter:
            this.advanceFilter.tCertUploadedDateTo,
          tcertificate_upload_date_from_filter:
            this.advanceFilter.tCertUploadedDateFrom,
          latest_questionnaire_upload_date_from_filter:
            this.advanceFilter.lastMSQSentUploadedDateFrom,
          latest_questionnaire_upload_date_to_filter:
            this.advanceFilter.lastMSQSentUploadedDateTo,
          idd_filter: this.filterAdvance.iddPlusAlert,
          pri_filter: this.filterAdvance.pri,
          edd_filter: this.filterAdvance.eddAlert,
          se_filter: this.filterAdvance.iddResults,
          risk_rating_filter: this.filterAdvance.riskRating,
          tcertification_status_filter: this.filterAdvance.tCertStatus,
          tcertification_order_type_filter:
            this.filterAdvance.tCertPaymentOptions,
          last_completed_questionnaire_filter:
            this.advanceFilter.latestCompletedMSQResult,
          latest_questionnaire_status_filter:
            this.advanceFilter.latestMSQTaskStatus,
          is_archived: this.filterAdvance.isArchived,
          form_questionnaires: this.formQuestionnaires,
          custom_attributes: this.customAttributesFilterValue,
          add_by_users_filter: this.filterAdvance.createdBy,
          ubo_idd_risk: this.filterAdvance.uboIDD,
          ubo_idd_plus_risk: this.filterAdvance.uboIDDPlus,
          ...this.makeUBOFilterParameter
        };
      }
    },
    async fetchThirdPartyTableWithAdvanceFilter() {
      try {
        this.isLoadingThirdParties = true;
        const formData =
          await this.makeThirdPartyTableWithAdvanceFilterPostObj();
        this.setFilterData = formData;
        const { data = {} } = await HTTP(
          "post",
          endpoints.THIRD_PARTY_FILTERS_THIRD_PARTIES,
          formData,
          makeAuthorizationHeader()
        );
        this.assignThirdPartyData(data);
      } catch (error) {
        return [error];
      } finally {
        this.isLoadingThirdParties = false;
      }
    },
    assignThirdPartyData(data) {
      this.thirdPartiesTableData = this.dataFormatter(data.data);
      this.fromRows = data.from;
      this.toRows = data.to === null ? 0 : data.to;
      this.totalRows = data.total;
    },
    makeThirdPartyDataPostObj() {
      return {
        filter: this.filter,
        sort: `${this.sortBy}|${this.sortDesc ? "desc" : "asc"}`,
        per_page: this.perPage,
        page: this.currentPage,
        company_id: this.companyId,
        is_archived: this.filterAdvance.isArchived,
        ...this.makeUBOFilterParameter
      };
    },
    async fetchThirdPartyData() {
      try {
        this.isLoadingThirdParties = true;
        const { data = {} } = await HTTP(
          "post",
          endpoints.THIRD_PARTIES_WITH_PRI,
          this.makeThirdPartyDataPostObj(),
          makeAuthorizationHeader()
        );

        this.assignThirdPartyData(data);
      } catch (error) {
        return [error];
      } finally {
        this.isLoadingThirdParties = false;
      }
    },
    customFormatter(date) {
      moment.locale("en");
      return moment(date).format("MMMM DD YYYY");
    },
    updateDateFormat(data) {
      moment.locale("en");
      return moment(data.item.created_date).format("DD MMM, YYYY");
    },
    onRowSelected(data) {
      if (data.length > 0) {
        this.isSelectedRow = true;
        this.selectedMultipleThirdParties = false;
        this.thirdPartyIdsActionsData = [];
        this.archiveThirdParties = [];
        this.thirdParties = [];
        this.multipleThirdParties = [];
        this.disableButtons = false;
        let custom_edd = 0;
        let edd_order_enabled = 1;
        let url = null;

        this.editBtnDisable = false;
        this.thirdPartyIdsActionsData.push(data[0]);
        this.archiveThirdParties.push(data[0].id);
        this.isThirdPartyArchived();

        data.map((rowData) => {
          if (rowData.tpi_type === thirdPartyType.ORGANIZATION) {
            if (custom_edd && custom_edd == true) {
              url =
                edd_order_enabled == 1
                  ? `order_edd_form_comp?srch_id=${rowData.srch_id_enc}`
                  : "";
            } else {
              url =
                edd_order_enabled == 1
                  ? `order_edd_comp?srch_id=${rowData.srch_id_enc}`
                  : "";
            }
          } else {
            if (custom_edd && custom_edd == true) {
              url =
                edd_order_enabled == 1
                  ? `order_edd_form_indi?srch_id=${rowData.srch_id_enc}`
                  : "";
            } else {
              url =
                edd_order_enabled == 1
                  ? `order_edd_indi?srch_id=${rowData.srch_id_enc}`
                  : "";
            }
          }
          this.orderEddUrl = url == "" ? null : this.eb2AppUrl + url;
          if (rowData.risk_level == 0) {
            this.thirdPartyName = rowData.comp_name_eng;
            this.thirdPartiesIds.push(rowData.id);
            this.disableButtonsIdd = false;
          }
          rowData.onboardings.map((onboardingsData) => {
            if (onboardingsData.onboarding_tasks.length > 0) {
              this.disableButtons = true;
            }
          });
          this.thirdPartyEmail = null;
          this.thirdPartyName = rowData.comp_name_eng;
          if (rowData.onboardings.length > 0) {
            if (
              rowData.onboardings[0].onboarding_tasks != null &&
              rowData.onboardings[0].onboarding_tasks.length == 0
            ) {
              this.thirdParties.push({
                id: rowData.id,
                name: rowData.comp_name_eng,
                email: null
              });
            } else {
              this.thirdParties = [];
            }
          }
        });
      } else {
        if (this.multipleThirdParties.length == 0) {
          this.isSelectedRow = false;
          this.thirdPartyIdsActionsData = [];
          this.thirdPartyName = null;
          this.thirdParties = [];
          this.disableButtons = true;
          this.editBtnDisable = true;
          this.archiveThirdParties = [];
          this.multipleThirdParties = [];
          this.selectedMultipleThirdParties = false;
        }
      }
    },

    // call configuration API for saving user configuration
    async setUserConfigurationApiCall() {
      const formData = {
        memberId: this.userId,
        configuration: this.visibleFields
      };
      this.submiting = true;
      this.isLoading = true;
      const result = HTTP(
        "post",
        "eb/user/configuration",
        formData,
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          this.submiting = false;
          this.isLoading = false;
          this.responseSuccess = true;
          return response;
        })
        .catch((error) => {
          this.isLoading = false;
          this.submiting = false;
          this.responseSuccess = false;
          return error;
        });
    },
    async selectAllRows(value) {
      if (value) {
        this.autoMultipleThirdPartiesSelectRows(this.thirdPartiesTableData);
      } else {
        if (this.isSelectedRow == false) {
          this.thirdParties = [];
          this.thirdPartyIdsActionsData = [];
          this.archiveThirdParties = [];
          this.selectedMultipleThirdParties = false;
          this.multipleThirdParties = [];
        }
      }
    },
    autoMultipleThirdPartiesSelectRows(allThirdParties) {
      this.$refs.table.clearSelected();
      this.thirdPartyIdsActionsData = [];
      this.archiveThirdParties = [];
      this.thirdParties = [];
      this.isSelectedRow = false;
      allThirdParties.map((items, index) => {
        this.multipleThirdParties[index] = true;
        this.thirdPartyIdsActionsData.push(items);
        this.archiveThirdParties.push(items.id);
        if (items.lg_email != "") {
          if (
            items.onboardings[0].onboarding_tasks != null &&
            items.onboardings[0].onboarding_tasks.length == 0
          ) {
            this.thirdParties.push({
              id: items.id,
              name: items.comp_name_eng,
              email: items.lg_email
            });
          }
        }
      });
      this.isThirdPartyArchived();
    },
    selectedRow(value, data) {
      if (value) {
        this.editBtnDisable = false;
        if (this.isSelectedRow) {
          this.isSelectedRow = false;
          this.thirdParties = [];
          this.thirdPartyIdsActionsData = [];
          this.archiveThirdParties = [];
        }
        this.$refs.table.clearSelected();
        this.archiveThirdParties.push(data.item.id);
        this.thirdPartyIdsActionsData.push(data.item);
        this.multipleThirdParties[data?.index] = true;
        this.isThirdPartyArchived();
        if (
          data.item.lg_email != "" &&
          data.item.onboardings[0].onboarding_tasks != null &&
          data.item.onboardings[0].onboarding_tasks.length == 0
        ) {
          this.thirdParties.push({
            id: data.item.id,
            name: data.item.comp_name_eng,
            email: data.item.lg_email
          });
        }
      } else {
        this.multipleThirdParties[data?.index] = false;
        this.editBtnDisable = true;
        let thirdPartyIndex = this.thirdParties.findIndex(
          (x) => x.id == data.item.id
        );
        let archiveThirdPartyIndex = this.archiveThirdParties.findIndex(
          (x) => x == data.item.id
        );
        let thirdPartyIdsIndex = this.thirdPartyIdsActionsData.findIndex(
          (x) => x.id == data.item.id
        );
        if (thirdPartyIndex != -1) {
          this.thirdParties.splice(thirdPartyIndex, 1);
        }
        if (archiveThirdPartyIndex != -1) {
          this.archiveThirdParties.splice(archiveThirdPartyIndex, 1);
        }
        if (thirdPartyIdsIndex != -1) {
          this.thirdPartyIdsActionsData.splice(thirdPartyIdsIndex, 1);
        }
      }
    },
    showAlert(data) {
      alert(`Plase add email for ${data.item.comp_name_eng}.`);
    },
    async archiveThirdPartyCall() {
      this.archiveThirdPartyApiCall().then(await this.reloadTable);
    },
    async archiveThirdPartyApiCall() {
      const formData = {
        third_parties: this.archiveThirdParties,
        user_id: this.userId,
        company_id: this.companyId
      };
      this.submiting = true;
      this.isLoading = true;
      const result = HTTP(
        "post",
        "thirdparty/archive",
        formData,
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          this.submiting = false;
          this.isLoading = false;
          this.responseSuccess = true;
          return response;
        })
        .catch((error) => {
          this.isLoading = false;
          this.submiting = false;
          this.responseSuccess = false;
          return error;
        });
    },
    onOpenArchiveThirdPartyModal() {
      this.isLoading = false;
      this.submiting = false;
      this.responseSuccess = false;
      this.showArchiveThirdPartiesModal = true;
    },
    resetArchiveThirdPartyModal() {
      this.multipleThirdParties = [];
      this.isLoading = false;
      this.submiting = false;
      this.responseSuccess = false;
      this.showArchiveThirdPartiesModal = false;
      this.archiveThirdParties = [];
    },
    openSentDdqModal(data) {
      this.multipleThirdParties = [];
      this.thirdPartyName = null;
      this.thirdPartyEmail = null;
      this.thirdPartyName = data.item.comp_name_eng;
      this.thirdParties.push({
        id: data.item.id,
        name: data.item.comp_name_eng,
        email: null
      });
      this.thirdPartyEmail = null;
      this.openSendDdq();
    },
    openSendDdq() {
      if (this.thirdPartyEmail == "") {
        this.showAddThirdPartyEmail = true;
      } else {
        this.showSendDdqModal = true;
      }
    },
    openSendDdqToAll() {
      this.showSendDdqModalToAll = true;
    },
    openActToAll() {
      this.showActToAll = true;
    },
    resetModal() {
      this.thirdPartyName = null;
      this.thirdParties = [];
      this.multipleThirdParties = [];
      this.showSendDdqModal = false;
      this.showSendDdqModalToAll = false;
      this.showActToAll = false;
      this.showSendDdqConfirmModal = false;
      this.submiting = false;
      this.responseSuccess = false;
      this.questionnaires.map((items) => {
        (items.isActive = false), (items.requestedDate = "");
      });
      this.allQuestionnaires.map((items) => {
        (items.isActive = false), (items.requestedDate = "");
      });
      this.isMsqActive = false;
      this.isAllMsqActive = false;
      this.submitingMSQandReminderToAll = false;
      this.showESGErrorModal = false;
      this.errorMessage = null;
      this.$refs.table.clearSelected();
    },
    addThirdParryEmail() {
      this.v$.form?.$touch();
      if (!this.v$.form.$errors?.length) {
        this.thirdParties.map((data) => {
          data.email = this.form.thirdPartyEmail;
        });
        this.sendDdq();
      }
    },
    sendDdq() {
      this.sendDdqApiCall().then(this.showSuccesSendDdqToster);
    },
    sendDdqToAll() {
      this.sendDdqToAllApiCall().then(this.showSuccesSendDdqToAllToster);
    },
    async sendDdqToAllApiCall() {
      const formData = {
        msq_action: this.questionnaires.filter((btn) => btn.isActive),
        companyId: this.companyId,
        background: 1,
        memberId: this.userId
      };
      try {
        this.submiting = true;
        this.isLoading = true;
        await HTTP(
          "post",
          "thirdparty/send/ddq/to/all",
          formData,
          makeAuthorizationHeader()
        );
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = true;
      } catch (error) {
        this.isLoading = false;
        this.submiting = false;
      }
    },
    async sendDdqApiCall() {
      const formData = {
        thirdParties: this.thirdParties.length > 0 ? this.thirdParties : [],
        companyId: this.companyId,
        memberId: this.userId
      };
      try {
        this.submiting = true;
        this.isLoading = true;
        await HTTP(
          "post",
          "v1/thirdparty/send/ddq",
          formData,
          makeAuthorizationHeader()
        );
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = true;
      } catch (error) {
        this.isLoading = false;
        this.submiting = false;
      }
    },
    async showSuccesSendDdqToster() {
      this.resetModal();
      this.resetAddThirdPartyModal();
      this.$bvToast.toast("Questionnaire(s) Sent successfully.", {
        title: "Questionnaire(s)",
        variant: "success",
        autoHideDelay: 2000,
        appendToast: false
      });
      await this.reloadTable();
    },
    async showSuccesSendDdqToAllToster() {
      this.resetModal();
      this.resetAddThirdPartyModal();
      this.$bvToast.toast("Questionnaire(s) Sending triggered successfully.", {
        title: "Questionnaire(s)",
        variant: "success",
        autoHideDelay: 2000,
        appendToast: false
      });
      await this.reloadTable();
    },
    setOrderEddUrl(tpi_type, custom_edd, edd_order_enabled, srch_id_enc) {
      let url = null;
      if (tpi_type === thirdPartyType.ORGANIZATION) {
        if (custom_edd && custom_edd == true) {
          url =
            edd_order_enabled == 1
              ? `order_edd_form_comp?srch_id=${srch_id_enc}`
              : "";
        } else {
          url =
            edd_order_enabled == 1
              ? `order_edd_comp?srch_id=${srch_id_enc}`
              : "";
        }
      } else {
        if (custom_edd && custom_edd == true) {
          url =
            edd_order_enabled == 1
              ? `order_edd_form_indi?srch_id=${srch_id_enc}`
              : "";
        } else {
          url =
            edd_order_enabled == 1
              ? `order_edd_indi?srch_id=${srch_id_enc}`
              : "";
        }
      }
      window.location.href = url == "" ? null : this.eb2AppUrl + url;
    },

    checkCustomAttributeKey(customKey, customAttSearches) {
      const customAttSearchesNameValue = customAttSearches.tp_customatt
        .toLowerCase()
        .replace(/\s/g, "_");
      return customAttSearchesNameValue === customKey.key;
    },
    checkSetCustomKeysData(customKey, customAttSearches) {
      let customAttSearchesName = customAttSearches.tp_customatt.toLowerCase();
      let customAttSearchesNameValue = customAttSearchesName.replace(
        /\s/g,
        "_"
      );
      if (customKey.key == customAttSearchesNameValue) {
        if (
          customAttSearches.tp_type === attributeType.USER ||
          customAttSearches.tp_type === attributeType.COUNTRY_LIST ||
          customAttSearches.tp_type === attributeType.MULTISELECT_COUNTRIES
        ) {
          return JSON.parse(customAttSearches.tp_customatt_values).value;
        } else if (customAttSearches.tp_type === attributeType.BOOLEAN) {
          if (parseInt(customAttSearches.tp_customatt_values) == 1) {
            return "Yes";
          } else {
            return "No";
          }
        } else if (
          customAttSearches.tp_type === attributeType.FILE ||
          customAttSearches.tp_type === attributeType.IMAGE
        ) {
          return customAttSearches.title;
        } else {
          return customAttSearches.tp_values_meta
            ? customAttSearches.tp_values_meta
            : customAttSearches.tp_customatt_values;
        }
      } else {
        return "";
      }
    },
    setPriScoring(riskCategories, scoring) {
      let scoringName = scoring.title.toLowerCase();
      let scoringKey = scoringName.replace(/\s/g, "_");
      if (riskCategories.key == scoringKey) {
        if (scoring) {
          return scoring.pri_score_text_abbreviated;
        } else {
          return "NULL";
        }
      }
    },
    getAdditionalVouchers() {
      this.visibleFields = [];
      this.configureTableAvailableCols = [];
      this.isMsqDataAvailable = true;
      this.setTableConfiguration();
    },
    makeFilterItemsObj() {
      return [
        {
          filterType: null,
          filterTypeValue: null,
          selectedFilterOptions: []
        }
      ];
    },
    async setTableConfiguration() {
      this.configureTableAvailableCols = [];
      this.visibleFields = [];
      this.filterItems = this.makeFilterItemsObj();
      let additionalData = this.companyDetails.additionalData;

      if (additionalData?.selectedColumns?.length) {
        additionalData.selectedColumns.forEach((items) => {
          if (items.default) {
            this.visibleFields.push(items);
          }
          this.configureTableAvailableCols.push(items);
        });
        this.questionnairesColumns.forEach((column) => {
          let actionsOccurIndex = this.visibleFields.findIndex(
            (x) => x.label == column.label
          );
          if (actionsOccurIndex != -1) {
            this.visibleFields[actionsOccurIndex] = column;
          } else {
            actionsOccurIndex = this.configureTableAvailableCols.findIndex(
              (x) => x.label == column.label
            );
            this.configureTableAvailableCols[actionsOccurIndex] = column;
          }
        });
      } else {
        this.visibleFields = cloneDeep(visibleFields.fields);
        this.configureTableAvailableCols = cloneDeep(staticFieldsJson.fields);
        this.configureTableAvailableCols.push(...this.questionnairesColumns);
      }

      this.configureTableAvailableCols =
        this.configureTableAvailableCols.reduce((accumulated, column) => {
          if (
            !accumulated.find(
              (accumulatedColumn) => accumulatedColumn.key === column.key
            )
          ) {
            accumulated.push(column);
          }

          return accumulated;
        }, []);

      if (!this.thirdPartiesTableData?.length) {
        await this.fetchThirdPartyTableWithAdvanceFilter();
      }
    },
    setActionSchemaAndCompanyDetail(companyDetail) {
      localStorage.setItem("companyDetail", JSON.stringify(companyDetail));
      let actionsData = companyDetail.additional_data
        ? JSON.parse(companyDetail.additional_data)
        : null;
      let actionsSchema = {};
      if (actionsData && actionsData != null) {
        let allActions = [];
        allActions.push(...defaultActionJson.extraActions);
        allActions.push(...defaultActionJson.actionsOccur);
        allActions.push(...defaultActionJson.actionsNotAvailable);
        if (actionsData.extraActions && actionsData.extraActions.length != 0) {
          actionsData.extraActions.map((items, index) => {
            if (items.title == undefined) {
              let extraObjectIndex = allActions.findIndex(
                (x) => x.gridBtntext == items.gridBtntext
              );
              actionsData.extraActions[index] = allActions[extraObjectIndex];
            }
          });
        }
        if (actionsData.actionsOccur && actionsData.actionsOccur.length != 0) {
          actionsData.actionsOccur.map((items, index) => {
            if (items.title == undefined) {
              let extraObjectIndex = allActions.findIndex(
                (x) => x.gridBtntext == items.gridBtntext
              );
              actionsData.actionsOccur[index] = allActions[extraObjectIndex];
            }
          });
        }
        if (
          actionsData.actionsNotAvailable &&
          actionsData.actionsNotAvailable.length != 0
        ) {
          actionsData.actionsNotAvailable.map((items, index) => {
            if (items.title == undefined) {
              let extraObjectIndex = allActions.findIndex(
                (x) => x.gridBtntext == items.gridBtntext
              );
              actionsData.actionsNotAvailable[index] =
                allActions[extraObjectIndex];
            }
          });
        }
        if (!actionsData.extraActions) {
          actionsData.extraActions = defaultActionJson.extraActions;
        }
        if (!actionsData.actionsNotAvailable) {
          actionsData.actionsNotAvailable =
            defaultActionJson.actionsNotAvailable;
        }
        if (!actionsData.actionsOccur) {
          actionsData.actionsOccur = defaultActionJson.actionsOccur;
        }
        actionsSchema = actionsData;
        localStorage.setItem("actionsSchema", JSON.stringify(actionsSchema));
        let actionsSidebarSchema = localStorage.getItem("actionsSchema");
        let parsedSidebarSchema = JSON.parse(actionsSidebarSchema);
        this.$store.state.newSidebarData = parsedSidebarSchema;
      } else {
        actionsSchema = {};
        actionsSchema.extraActions = defaultActionJson.extraActions;
        actionsSchema.actionsNotAvailable =
          defaultActionJson.actionsNotAvailable;
        actionsSchema.actionsOccur = defaultActionJson.actionsOccur;
        localStorage.setItem("actionsSchema", JSON.stringify(actionsSchema));
        let actionsSidebarSchema = localStorage.getItem("actionsSchema");
        let parsedSidebarSchema = JSON.parse(actionsSidebarSchema);
        this.$store.state.newSidebarData = parsedSidebarSchema;
      }
    },
    toggleAddThirdPartyModal() {
      this.form.thirdPartyEmail = null;
      this.showAddThirdPartyEmail = false;
      this.thirdPartyName = null;
      this.thirdParties = [];
      this.submiting = false;
      this.responseSuccess = false;
      this.$refs.table.clearSelected();
    },
    openIddPlusPage(link) {
      window.location.href = link;
    },
    openEddPage() {
      window.location.href = this.orderEddUrl;
    },
    setOrderIdd(data) {
      this.thirdPartyName = data.item.comp_name_eng;
      this.thirdPartiesIds.push(data.item.id);
      this.showOrderIddModal = true;
    },
    async showSuccesOrderIddToster() {
      this.resetOrderIddModal();
      this.$bvToast.toast(
        `IDD for ${this.thirdPartyName} successfully ordered`,
        {
          title: "Order IDD",
          variant: "success",
          autoHideDelay: 2000,
          appendToast: false
        }
      );
      await this.reloadTable();
    },
    orderIddCall() {
      this.orderIddApiCall().then(this.showSuccesOrderIddToster);
    },
    async orderIddApiCall() {
      const formData = {
        thirdPartyIds: this.thirdPartiesIds,
        companyId: this.companyId,
        memberId: this.userId
      };
      try {
        this.submiting = true;
        this.isLoading = true;
        await HTTP(
          "post",
          "v1/thirdparty/generate/idd",
          formData,
          makeAuthorizationHeader()
        );
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = true;
      } catch (error) {
        this.isLoading = false;
        this.submiting = false;
      }
    },
    resetOrderIddModal() {
      this.thirdPartiesIds = [];
      this.showOrderIddModal = false;
      this.submiting = false;
      this.isLoading = false;
      this.responseSuccess = false;
      this.disableButtonsIdd = true;
    },
    async openConfigureColumnsModal() {
      localStorage.removeItem("defaultVisibleFields");
      localStorage.removeItem("defaultStaticFields");
      this.modalConfiguredColumns = [];
      localStorage.setItem(
        "defaultStaticFields",
        JSON.stringify(this.configureTableAvailableCols)
      );
      localStorage.setItem(
        "defaultVisibleFields",
        JSON.stringify(this.visibleFields)
      );
      this.modalConfiguredColumns = this.visibleFields;
      this.showSelectColumnModal = true;
    },
    closeConfigureColumnsModal() {
      let defaultVisibleFields = localStorage.getItem("defaultVisibleFields");
      let defaultStaticFields = localStorage.getItem("defaultStaticFields");
      this.configureTableAvailableCols = JSON.parse(defaultStaticFields);
      this.visibleFields = JSON.parse(defaultVisibleFields);
      this.resetConfigureColumnsModal();
    },
    resetConfigureColumnsModal() {
      this.visibleFields.forEach((items) => {
        let staticFieldsIndex = this.configureTableAvailableCols.findIndex(
          (x) => x.key == items.key || x.label == items.label
        );
        if (staticFieldsIndex != -1) {
          this.configureTableAvailableCols.splice(staticFieldsIndex, 1);
        }
      });
      this.modalConfiguredColumns = [];
      this.showSelectColumnModal = false;
      this.showTableData = true;
    },
    async saveConfiguredColumns() {
      if (this.modalConfiguredColumns.length == 0) {
        this.showTable = false;
        this.visibleFields = this.modalConfiguredColumns;
        localStorage.setItem(
          "DynamicTableColumn",
          JSON.stringify(this.dynamicTableCol)
        );
      } else {
        this.showTable = true;
        this.visibleFields = [];
        this.visibleFields = this.modalConfiguredColumns;
        localStorage.setItem(
          "DynamicTableColumn",
          JSON.stringify(this.dynamicTableCol)
        );
      }
      this.resetConfigureColumnsModal();
      this.setUserConfigurationApiCall();
    },
    checkStickyField(value, fields) {
      let columnsIndex = this.modalConfiguredColumns.findIndex(
        (x) => x == fields
      );
      if (columnsIndex != -1) {
        this.modalConfiguredColumns[columnsIndex].stickyColumn = !!value;
        this.modalConfiguredColumns[columnsIndex].variant = value
          ? "primary"
          : "";
        this.modalConfiguredColumns[columnsIndex].tdStyle.backgroundColor =
          value ? "#b8daff" : "";
      }
    },
    setFieldsDisplayLabel(value, fields) {
      let columnsIndex = this.modalConfiguredColumns.findIndex(
        (x) => x == fields
      );
      if (columnsIndex != -1) {
        this.modalConfiguredColumns[columnsIndex].displayLabel = value;
      }
    },
    setFieldsWidth(value, fields) {
      let columnsIndex = this.modalConfiguredColumns.findIndex(
        (x) => x == fields
      );
      if (columnsIndex != -1) {
        this.modalConfiguredColumns[columnsIndex].width = value;
      }
    },
    setFieldsFontSize(value, fields) {
      let columnsIndex = this.modalConfiguredColumns.findIndex(
        (x) => x == fields
      );
      if (columnsIndex != -1) {
        this.modalConfiguredColumns[columnsIndex].fontSize = value;
      }
    },
    setFieldsHeaderFontSize(value, fields) {
      let columnsIndex = this.modalConfiguredColumns.findIndex(
        (x) => x == fields
      );
      if (columnsIndex != -1) {
        this.modalConfiguredColumns[columnsIndex].headerFontSize = value;
      }
    },
    addColumn(event, data) {
      this.dynamicTableCol.map((field) => {
        if (field.key == data.key) {
          field.visible = !!event;
        }
      });
      localStorage.setItem(
        "DynamicTableColumn",
        JSON.stringify(this.dynamicTableCol)
      );
    },
    changeColor(val, fields, type) {
      let columnsIndex = this.modalConfiguredColumns.findIndex(
        (x) => x == fields
      );
      if (columnsIndex != -1) {
        if (type == "thStyle") {
          this.modalConfiguredColumns[columnsIndex].thStyle.backgroundColor =
            val;
        } else {
          this.modalConfiguredColumns[columnsIndex][type] = val;
        }
      }
    },
    changeBacgroundColor(val, fields) {
      let columnsIndex = this.modalConfiguredColumns.findIndex(
        (x) => x == fields
      );
      if (columnsIndex != -1) {
        this.modalConfiguredColumns[columnsIndex].tdStyle.backgroundColor = val;
      }
    },
    increment(value, fields, key) {
      if (key == "fixed_column_background_color") {
        let variant = this.variants.findIndex((x) => x.value == value);
        if (variant == this.variants.length - 1) {
          variant = -1;
        }
        if (variant < this.variants.length - 1 || variant > 0) {
          let columnsIndex = this.modalConfiguredColumns.findIndex(
            (x) => x == fields
          );
          if (columnsIndex != -1) {
            this.modalConfiguredColumns[columnsIndex].variant =
              this.variants[variant + 1].value;
            this.modalConfiguredColumns[columnsIndex].variantColor =
              this.variants[variant + 1].color;
          }
        }
      } else if (key == "text_align") {
        let index = this.alignmentArray.findIndex((x) => x.value == value);
        if (index == this.alignmentArray.length - 1) {
          index = -1;
        }
        if (index < this.alignmentArray.length - 1 || index > 0) {
          let columnsIndex = this.modalConfiguredColumns.findIndex(
            (x) => x == fields
          );
          if (columnsIndex != -1) {
            this.modalConfiguredColumns[columnsIndex].tdStyle.textAlignment =
              this.alignmentArray[index + 1].value;
            this.modalConfiguredColumns[columnsIndex].tdStyle.alignment =
              this.alignmentArray[index + 1].name;
          }
        }
      } else if (key == "header_text_align") {
        let index = this.alignmentArray.findIndex((x) => x.value == value);
        if (index == this.alignmentArray.length - 1) {
          index = -1;
        }
        if (index < this.alignmentArray.length - 1 || index > 0) {
          let columnsIndex = this.modalConfiguredColumns.findIndex(
            (x) => x == fields
          );
          if (columnsIndex != -1) {
            this.modalConfiguredColumns[columnsIndex].thStyle.textAlignment =
              this.alignmentArray[index + 1].value;
            this.modalConfiguredColumns[columnsIndex].thStyle.alignment =
              this.alignmentArray[index + 1].name;
          }
        }
      }
    },
    decrement(value, fields, key) {
      if (key == "fixed_column_background_color") {
        let variant = this.variants.findIndex((x) => x.value == value);
        if (variant == 0) {
          variant = this.variants.length;
        }
        if (variant < this.variants.length - 1 || variant > 0) {
          let columnsIndex = this.modalConfiguredColumns.findIndex(
            (x) => x == fields
          );
          if (columnsIndex != -1) {
            this.modalConfiguredColumns[columnsIndex].variant =
              this.variants[variant - 1].value;
            this.modalConfiguredColumns[columnsIndex].variantColor =
              this.variants[variant - 1].color;
          }
        }
      } else if (key == "text_align") {
        let index = this.alignmentArray.findIndex((x) => x.value == value);
        if (index == 0) {
          index = this.alignmentArray.length;
        }
        if (index < this.alignmentArray.length - 1 || index > 0) {
          let columnsIndex = this.modalConfiguredColumns.findIndex(
            (x) => x == fields
          );
          if (columnsIndex != -1) {
            this.modalConfiguredColumns[columnsIndex].tdStyle.textAlignment =
              this.alignmentArray[index - 1].value;
            this.modalConfiguredColumns[columnsIndex].tdStyle.alignment =
              this.alignmentArray[index - 1].name;
          }
        }
      } else if (key == "header_text_align") {
        let index = this.alignmentArray.findIndex((x) => x.value == value);
        if (index == 0) {
          index = this.alignmentArray.length;
        }
        if (index < this.alignmentArray.length - 1 || index > 0) {
          let columnsIndex = this.modalConfiguredColumns.findIndex(
            (x) => x == fields
          );
          if (columnsIndex != -1) {
            this.modalConfiguredColumns[columnsIndex].thStyle.textAlignment =
              this.alignmentArray[index - 1].value;
            this.modalConfiguredColumns[columnsIndex].thStyle.alignment =
              this.alignmentArray[index - 1].name;
          }
        }
      }
    },
    incrementAll(value) {
      let variant = this.variants.findIndex((x) => x.value == value);
      if (variant == this.variants.length - 1) {
        variant = -1;
      }
      if (variant < this.variants.length - 1 || variant > 0) {
        this.modalConfiguredColumns.map((items) => {
          if (items.stickyColumn) {
            this.dynamicTableCol[13].value = this.variants[variant + 1].value;
            this.dynamicTableCol[13].color = this.variants[variant + 1].color;
            items.variant = this.variants[variant + 1].value;
            items.variantColor = this.variants[variant + 1].color;
          }
        });
      }
    },
    decrementAll(value) {
      let variant = this.variants.findIndex((x) => x.value == value);
      if (variant == 0) {
        variant = this.variants.length;
      }
      if (variant < this.variants.length - 1 || variant > 0) {
        this.modalConfiguredColumns.map((items) => {
          if (items.stickyColumn) {
            this.dynamicTableCol[13].value = this.variants[variant - 1].value;
            this.dynamicTableCol[13].color = this.variants[variant - 1].color;
            items.variant = this.variants[variant - 1].value;
            items.variantColor = this.variants[variant - 1].color;
          }
        });
      }
    },
    incrementAlignmentAll(value, key) {
      let index = this.alignmentArray.findIndex((x) => x.value == value);
      if (index == this.alignmentArray.length - 1) {
        index = -1;
      }
      if (index < this.alignmentArray.length - 1 || index > 0) {
        if (key == "text_align") {
          this.modalConfiguredColumns.map((items) => {
            this.dynamicTableCol[11].value =
              this.alignmentArray[index + 1].value;
            this.dynamicTableCol[11].name = this.alignmentArray[index + 1].name;
            items.tdStyle.textAlignment = this.alignmentArray[index + 1].value;
            items.tdStyle.alignment = this.alignmentArray[index + 1].name;
          });
        } else if (key == "header_text_align") {
          this.modalConfiguredColumns.map((items) => {
            this.dynamicTableCol[12].value =
              this.alignmentArray[index + 1].value;
            this.dynamicTableCol[12].name = this.alignmentArray[index + 1].name;
            items.thStyle.textAlignment = this.alignmentArray[index + 1].value;
            items.thStyle.alignment = this.alignmentArray[index + 1].name;
          });
        }
      }
    },
    decrementAlignmentAll(value, key) {
      let index = this.alignmentArray.findIndex((x) => x.value == value);
      if (index == 0) {
        index = this.alignmentArray.length;
      }
      if (index < this.alignmentArray.length - 1 || index > 0) {
        if (key == "text_align") {
          this.modalConfiguredColumns.map((items) => {
            this.dynamicTableCol[11].value =
              this.alignmentArray[index - 1].value;
            this.dynamicTableCol[11].name = this.alignmentArray[index - 1].name;
            items.tdStyle.textAlignment = this.alignmentArray[index - 1].value;
            items.tdStyle.alignment = this.alignmentArray[index - 1].name;
          });
        } else if (key == "header_text_align") {
          this.modalConfiguredColumns.map((items) => {
            this.dynamicTableCol[12].value =
              this.alignmentArray[index - 1].value;
            this.dynamicTableCol[12].name = this.alignmentArray[index - 1].name;
            items.thStyle.textAlignment = this.alignmentArray[index - 1].value;
            items.thStyle.alignment = this.alignmentArray[index - 1].name;
          });
        }
      }
    },
    setAllFields(value, key) {
      this.modalConfiguredColumns.map((items) => {
        if (key == "column_width") {
          this.dynamicTableCol[3].value = value;
          items.width = value;
        } else if (key == "font_size") {
          this.dynamicTableCol[4].value = value;
          items.fontSize = value;
        } else if (key == "header_font_size") {
          this.dynamicTableCol[8].value = value;
          items.headerFontSize = value;
        }
      });
    },
    changeAllFieldsColor(value, key) {
      this.modalConfiguredColumns.map((items) => {
        if (key == "font_color") {
          this.dynamicTableCol[5].value = value;
          items.fontColor = value;
        } else if (key == "background_color") {
          this.dynamicTableCol[6].value = value;
          items.tdStyle.backgroundColor = value;
        } else if (key == "header_font_color") {
          this.dynamicTableCol[9].value = value;
          items.headerFontColor = value;
        } else if (key == "header_background_color") {
          this.dynamicTableCol[10].value = value;
          items.thStyle.backgroundColor = value;
        }
      });
    },
    dragColumnBack(field) {
      this.configureTableAvailableCols.push(field);
      let modalConfiguredColumnsIndex = this.modalConfiguredColumns.findIndex(
        (x) => x.key == field.key
      );
      if (modalConfiguredColumnsIndex > -1) {
        this.modalConfiguredColumns.splice(modalConfiguredColumnsIndex, 1);
      }
    },
    async exportFilteredThirdParty() {
      try {
        const { data } = await this.sendEmailForThirdPartyFilteredExport(
          this.archiveThirdParties
        );
        this.$bvToast.toast(
          infoMessages.manageThirdParties.numberOfEmails({
            numberOfEmails: data.numberOfEmails
          }),
          toastPropSlowHideSuccess
        );
      } catch ({ response }) {
        let errorMessaage =
          hasStatus404(response) || hasStatus422(response)
            ? customErrorMessages.manageThirdParties.invalidQueryFilter
            : customErrorMessages.manageThirdParties.unexpectedError;
        this.$bvToast.toast(errorMessaage, toastPropSlowHideFailure);
      }
    },
    async exportThirdParty() {
      this.isLoadingExportThirdParty = true;
      try {
        const formData = {
          companyId: this.companyId,
          memberId: this.userId,
          company_name: this.companyName,
          email: this.email,
          sort: "shield_vendor_info.id|desc",
          isArchived: 1,
          filterEnable: this.filterEnable
        };
        if (this.filterEnable == 1) {
          formData.upload_date_from_filter =
            this.advanceFilter.uploadedDateFrom;
          formData.upload_date_to_filter = this.advanceFilter.uploadedDateTo;
          formData.tcertificate_created_date_to_filter =
            this.advanceFilter.tCertCreatedDateTo;
          formData.tcertificate_created_date_from_filter =
            this.advanceFilter.tCertCreatedDateFrom;
          formData.tcertificate_upload_date_to_filter =
            this.advanceFilter.tCertUploadedDateTo;
          formData.tcertificate_upload_date_from_filter =
            this.advanceFilter.tCertUploadedDateFrom;
          formData.latest_questionnaire_upload_date_from_filter =
            this.advanceFilter.lastMSQSentUploadedDateFrom;
          formData.latest_questionnaire_upload_date_to_filter =
            this.advanceFilter.lastMSQSentUploadedDateTo;
          formData.idd_filter = this.filterAdvance.iddPlusAlert;
          formData.pri_filter = this.filterAdvance.pri;
          formData.edd_filter = this.filterAdvance.eddAlert;
          formData.se_filter = this.filterAdvance.iddResults;
          formData.risk_rating_filter = this.filterAdvance.riskRating;
          formData.tcertification_status_filter =
            this.filterAdvance.tCertStatus;
          formData.tcertification_order_type_filter =
            this.filterAdvance.tCertPaymentOptions;
          formData.is_ubo = this.setIsUBODefaultValue();
          formData.ubo_idd_risk = this.filterAdvance.uboIDD;
          formData.ubo_idd_plus_risk = this.filterAdvance.uboIDDPlus;
          formData.last_completed_questionnaire_filter =
            this.advanceFilter.latestCompletedMSQResult;
          formData.latest_questionnaire_status_filter =
            this.advanceFilter.latestMSQTaskStatus;
          formData.isArchived = this.filterAdvance.isArchived;
          formData.add_by_users_filter = this.filterAdvance.createdBy;
        }
        const result = await HTTP(
          "post",
          "../thirdparty/thirdpartydatainexcel",
          formData,
          makeAuthorizationHeader()
        );
        if (result.status == 200) {
          this.exportMessage = result.data.message;
          this.showExportThirdPartyModal = true;
          this.isLoadingExportThirdParty = false;
        }
      } catch (error) {
        this.isLoadingExportThirdParty = false;
        return [error];
      }
    },
    setIsUBODefaultValue() {
      return this.filterAdvance?.isUBO?.length ? this.filterAdvance?.isUBO : [];
    },
    onSelectAvailableActions(action) {
      this.questionnaires.find((btn) => {
        if (btn.id == action.id) {
          if (btn.isActive) {
            btn.isActive = false;
          } else {
            btn.isActive = true;
            btn.requestedDate = new Date();
            this.isMsqActive = true;
            this.questionnaireName = btn.title;
          }
        } else {
          btn.isActive = false;
        }
      });
      let fields = this.questionnaires.filter((btn) => btn.isActive);
      if (fields.length == 0) {
        this.isMsqActive = false;
      }
    },
    openSendDdqConfirmModal() {
      this.showSendDdqConfirmModal = true;
      this.submiting = false;
      this.responseSuccess = false;
    },
    onSelectAllQuestionnaires(action) {
      this.allQuestionnaires.find((btn) => {
        if (btn.id == action.id && btn.title == action.title) {
          btn.isActive = !btn.isActive;
          btn.requestedDate = new Date();
        }
      });
      let fields = this.allQuestionnaires.filter((btn) => btn.isActive);
      if (fields.length == 0) {
        this.isAllMsqActive = false;
      } else {
        this.isAllMsqActive = true;
      }
    },
    isEsgActionTrigger() {
      let selectedActions = this.allQuestionnaires.filter(
        (btn) => btn.isActive
      );
      let isEsgFound = selectedActions.some(
        (action) => action.actionType === thirdPartyActionType.ESG
      );
      return isEsgFound;
    },
    async checkEsgBulkActions() {
      try {
        this.submitingMSQandReminderToAll = true;
        const result = await HTTP(
          "post",
          "thirdparty/esg/check/bulk/actions/all",
          {
            companyId: this.companyId
          },
          makeAuthorizationHeader()
        );
        if (
          result.data.status == 1 &&
          result.data.data &&
          result.data.data.no_url_count &&
          result.data.data.no_url_count > 0
        ) {
          this.showActToAll = false;
          this.showESGErrorModal = true;
          this.errorMessage = result.data.message;
        }
        this.submitingMSQandReminderToAll = false;
      } catch (error) {
        this.submitingMSQandReminderToAll = false;
      }
    },
    async sendMSQAndReminderToAll() {
      try {
        const formData = {
          companyId: this.companyId,
          memberId: this.userId,
          bulkActions: this.allQuestionnaires.filter((btn) => btn.isActive),
          background: 1
        };
        this.submitingMSQandReminderToAll = true;
        const result = await HTTP(
          "post",
          "thirdparty/perform/bulk/actions/all",
          formData,
          makeAuthorizationHeader()
        );
        if (result.data.status == 1) {
          this.$bvToast.toast(result.data.message, {
            title: "Done",
            variant: "success",
            autoHideDelay: 2000,
            appendToast: false
          });
          this.resetModal();
          await this.reloadTable();
        }
      } catch (error) {
        this.submitingMSQandReminderToAll = false;
      }
    },
    onTriggerBulkActions() {
      if (this.isEsgActionTrigger()) {
        this.checkEsgBulkActions();
      } else {
        this.sendMSQAndReminderToAll();
      }
    },
    onApplyBorder(customKey, customAttSearches) {
      return (
        this.checkSetCustomKeysData(customKey, customAttSearches) &&
        (customAttSearches.tp_type === attributeType.MULTISELECT ||
          customAttSearches.tp_type === attributeType.MULTISELECT_COUNTRIES)
      );
    },
    getColumnClass(className) {
      if (className === "text-center") {
        return "manage-third-party__column";
      } else {
        return className;
      }
    },
    makeUBOIddRiskOptions(status) {
      return getRiskIconBasedOnStatus(riskStatusMap[status]);
    },
    makeCompanyProfileUrlLink({
      thirdpartyid = "",
      comp_id = "",
      parent_third_party_id = "",
      member_id = ""
    } = {}) {
      return member_id && parent_third_party_id
        ? urls.ULTIMATE_BENEFICIAL_OWNERS_PROFILE(
            parent_third_party_id,
            member_id
          )
        : urls.THIRD_PARTY_PROFILE_COMPANY(thirdpartyid, comp_id);
    },
    setIsScreenedBeneficialOwnerText(data) {
      return data?.item?.is_ubo === "1" ? "Yes" : "No";
    },
    getCellWidth(data) {
      return `${data?.width}px`;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/custom.scss";

.viewScroll {
  max-height: calc(100vh - 170px);
  overflow: auto;
}
.filter-drop-down-menu {
  width: 80rem;
  transform: translate3d(21rem, 0, 0px);
}
.form-control-small .vs__dropdown-toggle {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-small-date-picker {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.third-party-custom-date-picker {
  width: 100%;
  border: none;
}
.third-party-custom-date-picker:focus {
  border: none;
}
.white-risk-rating {
  border: 1px solid $black;
}
.nav-tabs .nav-link {
  font-weight: bold;
  color: $black;
  border-bottom: 3px solid $white !important;
}
.nav-tabs li.nav-item {
  margin-right: 25px;
}
.vl {
  border-right: 0.5px solid $platinum;
  height: 500px;
}
.col-container {
  overflow-y: scroll;
  height: calc(100vh - 90px);
}
.kanban-column {
  min-height: 300px;
}
.dropdownFieldsContainer {
  display: flex;
  justify-content: space-between;
}
.noBorder {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  text-align: center;
}

.modal-dialog-configure-table {
  margin: 91px 5px 5px 5px !important;
  transform: none;
  max-width: 100% !important;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 20px !important;
}
.modal-content-configure-table {
  height: 98% !important;
  max-width: 100% !important;
}
.modal-open-configure-table {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  padding-left: 0px !important;

  z-index: 9999;
}
.main-wrapper-modal {
  z-index: 9999;
}
.modal-footer-configure-table {
  padding: 2px !important;
}
.modal-header-configure-table {
  padding: 7px 10px !important;
}
.table.b-table > tbody > .table-active,
.table.b-table > tbody > .table-active > td,
.table.b-table > tbody > .table-active > th {
  background-color: $robin-egg-blue;
}
.low-risk-score-style {
  color: $white;
  background-color: $robin-egg-blue;
}
.low-risk-score-style:hover,
.low-risk-score-style:focus {
  color: $white;
  background-color: $robin-egg-blue;
}
.modrate-risk-score-style {
  color: $white;
  background-color: $dark-orange;
}
.modrate-risk-score-style:hover,
.modrate-risk-score-style:focus {
  color: $white;
  background-color: $dark-orange;
}
.high-risk-score-style {
  color: $white;
  background-color: $alizarin-crimson;
}
.high-risk-score-style:hover,
.high-risk-score-style:focus {
  color: $white;
  background-color: $alizarin-crimson;
}
.manage-thirdparty-table {
  &__column-spacing {
    display: flex;
    gap: 2px;
  }

  th {
    position: sticky !important;
    overflow-wrap: break-word;
  }
}
.manage-third-party__custom-attributes-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;

  & .custom-border {
    border-bottom: 1px solid $bright-gray;
    padding: 5px 0;

    &:last-child {
      border: none;
    }
  }
}

.manage-third-party {
  &__column {
    display: flex;
    justify-content: center;
  }

  &__third-party-details--name {
    display: flex;
    gap: $spacing6;
    align-items: center;
  }

  &__entity-verified-icon {
    min-width: max-content;
  }
}
</style>

const esgChartsEnum = {
  ESG_COMPLETED: "completedEsg",
  ESG_QUEUED: "EsgQueued",
  ESG_IN_REMEDIATION: "awaitingRemediation",
  ESG_RISK_RESILIENCE: "riskResilience"
};

const chartsEnum = {
  DDQ_RISK_SCORE: "DDQ_Risk_Score",
  TP_COUNT: "tpCount",
  TP_OVER_TIME: "tpOverTime",
  OGM_RESULTS: "ogmResults",
  TP_OVER_COUNTRIES_MAP: "tpOverCountriesMap",
  PRI_RESULTS: "priResults",
  IDD_RESULTS: "iddResults",
  IDD_PLUS_PRE_REMEDIATION_RESULTS: "iddPlusPreRemediationResults",
  EDD_RESULTS: "eddResults",
  STANDARD_QUESTION: "standard_question",
  TP_OVER_COUNTRIES: "tpOverCountries",
  IDD_PLUS_POST_REMEDIATION_RESULTS: "iddPlusPostRemediationResults",
  NOT_STARTED: "Not_Started",
  IN_PROGRESS_PAUSE_REMINDERS: "In_Progress_-_Pause_Reminders",
  IN_PROGRESS: "In_Progress",
  CANCELLED: "Cancelled",
  COMPLETED: "Completed",
  SELF_EVIDENCE: "Self_Evidenced",
  DECLINED_REFUSED: "Declined_/_Refused",
  ESG_OVERALL_RATING: "overAllEsgRating",
  GOVERNANCE_PILLAR_OVERALL_RATING: "governanceRating",
  SOCIAL_PILLAR_OVERALL_RATING: "socialRating",
  OVERALL_RISK_SEVERITY: "riskSeverity",
  ENVIRONMENT_PILLAR_OVERALL_RATING: "environmentRating",
  RESILIENCE_OVERALL_STRENGTH: "resilienceStrength",
  CUSTOM_ATTRIBUTE: "custom_attribute",
  ...esgChartsEnum
};

const chartTypesKeys = {
  PIE: "PIE",
  PYRAMID: "PYRAMID",
  FUNNEL: "FUNNEL",
  MAP_BUBBLE: "MAP_BUBBLE",
  BUBBLE: "BUBBLE",
  SCATTER: "SCATTER",
  DATETIME: "DATETIME",
  BAR: "BAR",
  POINT_VALUE: "POINT_VALUE",
  GAUGE: "GAUGE",
  AVERAGE_RATING: "AVERAGE_RATING"
};

const chartsEnumId = {
  [chartsEnum.ESG_OVERALL_RATING]: "",
  [chartsEnum.SOCIAL_PILLAR_OVERALL_RATING]: "Social",
  [chartsEnum.GOVERNANCE_PILLAR_OVERALL_RATING]: "Governance",
  [chartsEnum.OVERALL_RISK_SEVERITY]: "Risk",
  [chartsEnum.ENVIRONMENT_PILLAR_OVERALL_RATING]: "Environment",
  [chartsEnum.RESILIENCE_OVERALL_STRENGTH]: "Resilience"
};

const chartsStatusType = {
  [chartsEnum.ESG_COMPLETED]: "Complete",
  [chartsEnum.ESG_QUEUED]: "Queued",
  [chartsEnum.ESG_IN_REMEDIATION]: "Awaiting ESG Assessments"
};

const chartsStandardQuestionDataType = {
  COUNTRIES: "countries"
};

const chartsStandardQuestionChartType = {
  MAP: "map",
  BAR: "bar"
};

const chartTypes = {
  [chartTypesKeys.PIE]: "pie",
  [chartTypesKeys.PYRAMID]: "pyramid",
  [chartTypesKeys.FUNNEL]: "funnel",
  [chartTypesKeys.MAP_BUBBLE]: "mapbubble",
  [chartTypesKeys.BUBBLE]: "bubble",
  [chartTypesKeys.SCATTER]: "scatter",
  [chartTypesKeys.DATETIME]: "datetime",
  [chartTypesKeys.BAR]: "bar",
  [chartTypesKeys.POINT_VALUE]: "single_point_value",
  [chartTypesKeys.GAUGE]: "solidgauge"
};

const pieChartName = {
  RED: "Red",
  AMBER: "Amber",
  GREEN: "Green",
  NO_PRI: "No PRI"
};

const pieChartNameMap = {
  [pieChartName.RED]: "Red PRI",
  [pieChartName.AMBER]: "Amber PRI",
  [pieChartName.GREEN]: "Green PRI",
  [pieChartName.NO_PRI]: "No PRI"
};

const ddqRiskScoreName = {
  HIGH: "High",
  MODERATE: "Moderate",
  LOW: "Low"
};

const sanctionsAlertsName = {
  NO_SE_ALERT: "No S&E Alert",
  SE_ALERT: "S&E Alert",
  NO_IDD: "No IDD"
};

const iddPlusPreRemediationName = {
  FALSE_POSITIVE: "False Positive",
  NO_IDD_ALERTS: "No IDD+ Alerts",
  ALERTS: "Alerts"
};

const iddPlusPostRemediationName = {
  NO_IDD_ORDERED: "No IDD Ordered",
  REMEDIATION_GREEN: "Remediated Green",
  REMEDIATION_IN_PROGRESS: "Remediation In Process",
  REMEDIATION_RED: "Remediated Red",
  REMEDIATION_NOT_ORDERED: "Remediation Not Ordered"
};

const eddResultName = {
  EDD_GREEN: "EDD Green",
  EDD_NOT_ORDERED: "EDD Not Ordered",
  EDD_RED: "EDD Red"
};

const chartTypesText = {
  [chartTypesKeys.BAR]: "Bar chart",
  [chartTypesKeys.PIE]: "Pie chart",
  [chartTypesKeys.AVERAGE_RATING]: "Average Rating"
};

export {
  esgChartsEnum,
  chartsEnum,
  chartsEnumId,
  chartsStatusType,
  chartTypes,
  pieChartName,
  pieChartNameMap,
  ddqRiskScoreName,
  sanctionsAlertsName,
  iddPlusPreRemediationName,
  iddPlusPostRemediationName,
  eddResultName,
  chartsStandardQuestionDataType,
  chartsStandardQuestionChartType,
  chartTypesText
};

const localStorageItem = {
  ACCESS_TOKEN: "accessToken",
  USER_ID: "userId",
  USER_GROUP_ID: "userGroupId",
  USER_FULL_NAME: "userFullname",
  REGISTRATION_STATUS: "registrationStatus",
  USER_FIRST_NAME: "userFirstname",
  USER_LAST_NAME: "userLastname",
  COMPANY_ID: "companyId",
  COMPANY_NAME: "companyName",
  LANDING_PAGE: "landingPage",
  FORM_DEFAULT_LANGUAGE: "formDefaultLanguage",
  FORM_TRANSLATIONS: "formTranslations",
  ACTIONS_SCHEMA: "actionsSchema",
  SINGLE_ANALYTICS: "single_analytics",
  COMPARE_ANALYTICS: "compare_analytics",
  DASHBOARD_VIEW: "dashboard_view",
  OPENED_COMPANY_PROFILE: "openedCompanyProfile",
  AUTO_FILL_MSQ_V2: "autoFillMsqV2",
  PASSWORD_CREATED: "passwordCreated",
  UPDATED_PASSWORD: "update_password",
  BULK_ACTIONS: "bulkActions",
  ROLES: "roles",
  PERMISSIONS: "permissions",
  FORM_STATUS: "formStatus",
  TASK_ID: "taskId",
  ACTIVE_FORM_ID: "activeFormId",
  ACTIVE_FORM_SCHEMA: "activeFormSchema",
  TASK_FORM_DATA: "taskFormData",
  ACTIVE_TASK_STATUS: "activeTaskStatus",
  TEMP_FORM: "tempForm",
  USER_CONSENT: "userConsent",
  PLATFORM_VERSION: "platformVersion",
  TENANT_ID: "tenantId"
};

export { localStorageItem };

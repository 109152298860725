import router from "@/routes";
import posthog from "posthog-js";
import {
  actionName,
  languages,
  languagesInitial,
  mutationName,
  routeNames,
  getterName
} from "@/constants";
import { authService } from "@/services";

export const actions = {
  async logOut(
    { commit, dispatch, rootGetters, rootState },
    hardLogoutSSOUsers = false
  ) {
    const isUserLoggedInViaSSO =
      !rootGetters?.[getterName.USER.IS_LOGGED_IN_VIA_THE_PLATFORM];

    posthog.reset();

    // Expire/Remove cookie for authentication of download/print pages (php) - PD-13218 & PD-13219
    document.cookie = `__Host_token=${rootState?.auth?.token};Path=/;Secure;SameSite=Strict; ; expires= Thu, 21 Aug 2014 20:00:00 UTC`;

    commit(mutationName.LOADER.SET_IS_APP_LOADING, true, { root: true });
    localStorage.clear();
    commit(mutationName.USER.RESET_USER, null, { root: true });
    commit(mutationName.PLATFORM_SETTINGS.RESET_PLATFORM_SETTINGS, null, {
      root: true
    });
    commit(mutationName.USER_SETTINGS.RESET_USER_SETTINGS, null, {
      root: true
    });
    commit(mutationName.AUTH.RESET_AUTH, null, {
      root: true
    });
    commit(mutationName.THIRD_PARTY.RESET_THIRD_PARTY, null, {
      root: true
    });
    commit(mutationName.ACTIONS.RESET_ACTIONS, null, {
      root: true
    });
    commit(mutationName.ROLES.RESET_ROLES, null, {
      root: true
    });
    commit(mutationName.COMPANY.RESET_COMPANY, null, {
      root: true
    });
    commit(mutationName.COUNTRY.RESET_COUNTRIES, null, {
      root: true
    });
    commit(mutationName.POSTHOG.RESET_POSTHOG_STORE, null, {
      root: true
    });
    commit(mutationName.PERMISSIONS.RESET_PERMISSIONS, null, {
      root: true
    });
    commit(mutationName.TASKS.RESET_TASKS, null, {
      root: true
    });

    await dispatch(
      actionName.PLATFORM_SETTINGS.FETCH_PUBLIC_PLATFORM_SETTINGS,
      null,
      { root: true }
    );

    if (router?.currentRoute?.value?.name !== routeNames.LOGIN) {
      await router.push({ name: routeNames.LOGIN });
    }

    commit(mutationName.LOADER.SET_IS_APP_LOADING, false, {
      root: true
    });

    if (isUserLoggedInViaSSO && hardLogoutSSOUsers) {
      window.location = `${window?.location?.origin}/logout.php`;
    }
  },
  async logIn({ commit, dispatch }, payload) {
    const response = await authService.authenticate(payload);
    const formTranslations =
      Array.isArray(response.data.formTranslations) &&
      response.data.formTranslations?.length
        ? response.data.formTranslations
        : [languages[languagesInitial.ENGLISH]];
    const formDefaultLanguage = languages[response.data.contact.language]
      ? languages[response.data.contact.language]
      : languages[languagesInitial.ENGLISH];

    commit(mutationName.FORM.SET_FORM_TRANSLATIONS, formTranslations, {
      root: true
    });
    commit(mutationName.AUTH.SET_TOKEN, response.data.authToken, {
      root: true
    });

    // Add cookie for authentication of download/print pages (php) - PD-13218 & PD-13219
    document.cookie = `__Host_token=${response.data.authToken};Path=/;Secure;SameSite=Strict;`;

    commit(mutationName.USER.SET_FORM_DEFAULT_LANGUAGE, formDefaultLanguage, {
      root: true
    });
    commit(
      mutationName.FORM.SET_FORM_TRANSLATIONS,
      response.data.formTranslations,
      {
        root: true
      }
    );
    commit(
      mutationName.COMPANY.SET_COMPANY_VIEWS,
      response.data.platformViews,
      {
        root: true
      }
    );
    commit(
      mutationName.USER.SET_PLATFORM_VERSION,
      response.data.platformVersion,
      {
        root: true
      }
    );
    commit(mutationName.USER.SET_USER_FIRST_NAME, response.data.userFirstName, {
      root: true
    });
    commit(mutationName.USER.SET_USER_LAST_NAME, response.data.userLastName, {
      root: true
    });
    commit(
      mutationName.USER.SET_REGISTRATION_STATUS,
      response.data.userRegistrationStatus,
      {
        root: true
      }
    );
    commit(
      mutationName.USER.SET_USER_TOUR_STATUS,
      response.data.userTourStatus,
      {
        root: true
      }
    );
    commit(mutationName.USER.SET_USER_FULL_NAME, response.data.userFullName, {
      root: true
    });
    commit(mutationName.USER.SET_EMAIL, response.data.email, {
      root: true
    });
    commit(mutationName.USER.SET_MEMBER_GROUP_ID, response.data.memberGroupId, {
      root: true
    });
    commit(mutationName.USER.SET_LANDING_PAGE, response.data.landingPage, {
      root: true
    });
    commit(
      mutationName.USER.SET_PASSWORD_CREATED,
      response.data.passwordCreated,
      {
        root: true
      }
    );
    commit(mutationName.COMPANY.SET_COMPANY_ID, response.data.companyId, {
      root: true
    });
    commit(mutationName.USER.SET_ROLES, response.data.roles, {
      root: true
    });
    commit(
      mutationName.PERMISSIONS.SET_PERMISSIONS,
      response.data.permissions,
      {
        root: true
      }
    );
    commit(mutationName.USER.SET_LOGIN_TYPE, response.data.loginType, {
      root: true
    });
    commit(mutationName.USER.SET_USER_ID, response.data.memberId, {
      root: true
    });
    commit(mutationName.COMPANY.SET_COMPANY_NAME, response.data.companyName, {
      root: true
    });
    commit(mutationName.COMPANY.SET_TENANT_ID, response.data.tenantId, {
      root: true
    });
    commit(mutationName.USER.SET_THIRD_PARTY_ID, response.data.thirdPartyId, {
      root: true
    });

    dispatch(actionName.AUTH.FETCH_EB360_APP_DATA, null, { root: true });

    return response;
  },
  async fetchEB360AppData({ dispatch, commit, rootGetters }) {
    commit(mutationName.LOADER.SET_IS_APP_LOADING, true, { root: true });
    if (!rootGetters?.[getterName.USER.IS_USER_THIRD_PARTY]) {
      await dispatch(
        actionName.PLATFORM_SETTINGS.FETCH_PLATFORM_USER_LIST,
        null,
        {
          root: true
        }
      );
      await dispatch(actionName.COMPANY.FETCH_COMPANY_USERS, null, {
        root: true
      });
    }
    await dispatch(actionName.PLATFORM_SETTINGS.FETCH_PLATFORM_SETTINGS, null, {
      root: true
    });
    await dispatch(actionName.COMPANY.FETCH_COMPANY_FEATURES, null, {
      root: true
    });
    commit(mutationName.LOADER.SET_IS_APP_LOADING, false, {
      root: true
    });

    await dispatch(actionName.LANGUAGES.FETCH_LANGUAGES, null, {
      root: true
    });

    commit(mutationName.POSTHOG.SET_DEFAULT_PROPERTIES, null, {
      root: true
    });
  }
};

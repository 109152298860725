<template>
  <ErrorPageTemplate v-bind="errorPageOptions" />
</template>

<script>
import ErrorPageTemplate from "@/templates/ErrorPageTemplate/ErrorPageTemplate";
import { validationKeys, customErrorMessages } from "@/constants";

export default {
  name: "InvalidDocumentFormatTemplate",
  components: {
    ErrorPageTemplate
  },
  data() {
    return {
      errorPageOptions: {
        iconOptions: {
          icon: "file-upload"
        },
        headingOptions: {
          text: "Invalid document format"
        },
        contentOptions: {
          text: [
            customErrorMessages.uploadingFiles[
              validationKeys.UNSUPPORTED_FILE_FORMAT
            ],
            "If the issue persists please contact support."
          ]
        }
      }
    };
  }
};
</script>
